import React from 'react';

import { alpha, useTheme } from '@mui/material/styles';

import PlaySmallIcon from '~/assets/icons/PlaySmallIcon';
import ConditionalBlockLink from '~/components/ConditionalBlockLink/ConditionalBlockLink';
import LazyLoadImage from '~/components/LazyloadImage/LazyLoadImage';
import { VideoInterface } from '~/components/Video/types';
import Modal from '~/modals/Modal';
import { Modals } from '~/modals/types';
import styled from '~/styled';
import { BREAKPOINTS_VALUES } from '~/theme';

const SRCSET = [
  { width: 230, height: 240, focus: 'auto' },
  { width: 270, height: 350, focus: 'auto' },
  { width: 280, height: 350, focus: 'auto' },
  { width: 300, height: 350, focus: 'auto' },
];

const PortraitVideo: React.FC<VideoInterface> = ({
  id,
  title,
  userName,
  previewUrl,
  background,
  handleClick,
  durationNumb,
  verseId,
  link,
  bumperUrl,
  shouldOpenInModal = false,
}) => {
  const theme = useTheme();
  return (
    <Modal
      verseId={verseId}
      duration={durationNumb}
      title={title}
      expertName={userName}
      bumperUrl={bumperUrl}
    >
      {({ openModal }) => (
        <VideoWrap
          onClick={() => {
            if (shouldOpenInModal) {
              openModal(Modals.VideoModal);
              return;
            }

            if (handleClick) {
              handleClick(id);
            }
          }}
          background={background}
          withLink={link && !shouldOpenInModal}
          to={link}
        >
          <StyledLazyLoadImage
            className={'videoImg'}
            path={previewUrl}
            alt={title}
            draggable={false}
            srcset={SRCSET}
            sizes={`(max-width:${BREAKPOINTS_VALUES['sm']}px) 230px, (max-width: ${BREAKPOINTS_VALUES['md']}) 270px, (max-width:${BREAKPOINTS_VALUES['lg']}px) 280px, 300px`}
          />
          <BtnWrap>
            <Btn color={theme.palette.common.white} />
          </BtnWrap>
          <Title title={title}>{title}</Title>
        </VideoWrap>
      )}
    </Modal>
  );
};

export default PortraitVideo;

const VideoWrap = styled(ConditionalBlockLink, {
  shouldForwardProp: (prop) => prop !== 'background',
})`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  height: 225px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  background: ${({ background, theme }) =>
    background === 'Dark'
      ? theme.palette.common.blockBackground.light
      : theme.palette.common.blockBackground.main};

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 90%;
    background: ${({ background, theme }) =>
      background === 'Dark'
        ? `linear-gradient(0deg, ${theme.palette.common.blockBackground.main} 0%, ${alpha(
            theme.palette.common.blockBackground.main,
            0.9,
          )} 28.65%, ${alpha(theme.palette.common.blockBackground.light, 0)} 100%);`
        : `linear-gradient(0deg, ${theme.palette.common.blockBackground.main} 0%, ${alpha(
            theme.palette.common.blockBackground.main,
            0.9,
          )} 28.65%, ${alpha(theme.palette.common.blockBackground.main, 0)} 100%);`};
    -webkit-background-origin: padding-box;
    -o-background-origin: padding-box;
    -moz-background-origin: padding-box;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: 315px;

    &:hover {
      & .videoImg {
        height: 110%;
      }
    }
  }
`;
const StyledLazyLoadImage = styled(LazyLoadImage)`
  position: absolute;
  right: 50%;
  bottom: 50%;
  height: 100%;
  transform: translate(50%, 50%);
  transition: height 0.3s ease;
`;
const BtnWrap = styled('div')`
  cursor: pointer;
  position: absolute;
  transform: translate(-40%, -60%);
  background: ${({ theme }) => alpha(theme.palette.common.blockBackground.main, 0.5)};
  top: 50%;
  left: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 70px;
    height: 70px;
  }
`;
const Btn = styled(PlaySmallIcon)`
  width: 24px;
  height: 28px;
  margin-left: 2px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 35px;
    height: 40px;
    margin-left: 4px;
  }
`;
const Title = styled('span')`
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  margin: 0 17px 17px 17px;
  z-index: 4;
  overflow: hidden;
  white-space: inherit;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 75%;
  max-height: 72px;
  color: ${({ theme }) => theme.palette.common.white};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 20px;
    line-height: 24px;
    margin: 0 23px 23px 23px;
  }
`;
