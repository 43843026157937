import React, { FC, useContext } from 'react';

import { Box, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';

import CircleCheckIcon from '~/assets/icons/CircleCheckIcon';
import WelcomeHandIcon from '~/assets/icons/WelcomeHandIcon';
import ProgressLine from '~/components/UI/ProgressLine/ProgressLine';
import { H4 } from '~/components/UI/Texts';
import { UserInfoCtx } from '~/context/UserInfoProvider';
import useIsSsku from '~/hooks/tenant/useIsSsku';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import styled from '~/styled';
import { MIDDLE_MARGIN_PX, SMALL_MARGIN_PX, SUBMIDDLE_MARGIN_PX } from '~/theme';

export const TOTAL_STEPS = 4;

interface StepHeaderProps {
  currentStep: number;
  onboardingComplete: boolean;
  labelledBy: string;
}

export const StepHeader: FC<StepHeaderProps> = ({
  currentStep,
  onboardingComplete,
  labelledBy,
}) => {
  const { t } = useTenantTranslation();
  const theme = useTheme();
  const { userInfo } = useContext(UserInfoCtx);
  const isSsku = useIsSsku();
  const userName = userInfo?.first_name;

  const isFirstStep = !currentStep;
  const isLastStep = currentStep === TOTAL_STEPS;

  const title = isFirstStep
    ? t('modal.onboarding.welcome', { name: userName })
    : isLastStep
    ? onboardingComplete
      ? t('modal.onboarding.goalsUpdated')
      : t('modal.onboarding.wellDone')
    : onboardingComplete
    ? t('modal.onboarding.editYourGoals')
    : t('modal.onboarding.setYourGoals');
  const progressWidth = (currentStep / (TOTAL_STEPS - 1)) * 100;

  const showProgress = !!currentStep && !isLastStep;

  return (
    <StyledContainer currentStep={showProgress}>
      <Title
        isSsku={isSsku}
        id={labelledBy}
        currentStep={currentStep}
        data-testid="onboardingHeader"
      >
        {isLastStep && <StyledCircleCheckIcon color={theme.palette.common.accentFaded} />}
        {isSsku && isFirstStep && <StyledWelcomeHandIcon color={theme.palette.common.primary} />}
        {title}
      </Title>
      {showProgress && (
        <>
          <Steps data-testid="onboardingStepProgress">
            {t('modal.onboarding.step', { currentStep, totalSteps: TOTAL_STEPS - 1 })}
          </Steps>
          <StyledProgressLine width={progressWidth} />
        </>
      )}
    </StyledContainer>
  );
};

const StyledProgressLine = styled(ProgressLine)`
  background: ${({ theme }) => alpha(theme.palette.common.accentMain, 0.33)};
  height: 5px;
  opacity: 1;
`;
const Steps = styled(H4)`
  color: ${({ theme }) => theme.palette.common.icon.gray};
`;
const StyledContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'currentStep',
})`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  position: relative;
  margin-bottom: ${SUBMIDDLE_MARGIN_PX};
  padding-bottom: ${({ currentStep }) => (currentStep ? SUBMIDDLE_MARGIN_PX : 0)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-bottom: ${MIDDLE_MARGIN_PX};
  }
`;
const Title = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'currentStep' && prop !== 'isSsku',
})`
  display: flex;
  align-items: center;
  font-weight: 400;
  margin-top: ${({ currentStep }) => currentStep === TOTAL_STEPS && 100}px;
  font-family: ${({ theme }) => theme.typography.specialFontFamily};
  font-size: 32px;
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: ${({ isSsku }) => (isSsku ? 54 : 40)}px;
  }
`;
const createStyledIcon = (tag) => styled(tag)`
  width: 40px;
  height: 40px;
  margin-right: ${SMALL_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 56px;
    height: 56px;
  },
`;

const StyledCircleCheckIcon = createStyledIcon(CircleCheckIcon);
const StyledWelcomeHandIcon = createStyledIcon(WelcomeHandIcon);
