import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { Popper } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import styled from '~/styled';
import { MIDDLE_RADIUS_PX, SUBMIDDLE_MARGIN, TINY_MARGIN, useIsWidthUp } from '~/theme';

export interface IAlert {
  message: string | React.JSX.Element;
  duration?: number;
  severity?: Severity;
  ref?: null | HTMLElement;
}

interface IAlertCtx {
  alert: null | IAlert;
  showAlert: (alert: IAlert) => void;
  hideAlert: () => void;
}

export enum Severity {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

const AlertCtx = createContext<IAlertCtx>({
  alert: null,
  showAlert: () => {},
  hideAlert: () => {},
});

export function useAlert() {
  return useContext(AlertCtx);
}

export const WithAlert = ({ children }) => {
  const { alert, hideAlert } = useAlert();
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (alert?.ref && !isHovered) {
      const timer = setTimeout(hideAlert, alert.duration);
      return () => clearTimeout(timer);
    }
  }, [alert, hideAlert, isHovered]);

  const isMobile = !useIsWidthUp('sm');

  if (alert?.ref) {
    return (
      <>
        {children}
        <StyledPopper
          open={Boolean(alert?.ref)}
          anchorEl={alert?.ref}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          placement="bottom"
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, TINY_MARGIN], // offset from the anchor
              },
            },
            {
              name: 'preventOverflow',
              options: {
                padding: isMobile ? 0 : SUBMIDDLE_MARGIN, // offset from the edge of the screen
              },
            },
          ]}
        >
          {alert?.message}
        </StyledPopper>
      </>
    );
  }

  return (
    <>
      {children}
      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        open={Boolean(alert?.message)}
        autoHideDuration={alert?.duration}
        onClose={hideAlert}
      >
        <StyledAlert onClose={hideAlert} severity={alert?.severity} variant="filled">
          {alert?.message}
        </StyledAlert>
      </Snackbar>
    </>
  );
};

const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState<null | IAlert>(null);

  const showAlert = useCallback((alert) => {
    const defaultAlertOptions = {
      message: '',
      duration: 3000,
      severity: Severity.Success,
      ref: null,
    };
    setAlert({ ...defaultAlertOptions, ...alert });
  }, []);
  const hideAlert = useCallback((event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlert(null);
  }, []);

  return <AlertCtx.Provider value={{ showAlert, hideAlert, alert }}>{children}</AlertCtx.Provider>;
};

export default AlertProvider;

const StyledAlert = styled(MuiAlert)`
  & .MuiIconButton-root {
    padding: 5px;
  }
`;
const StyledPopper = styled(Popper)`
  padding: ${TINY_MARGIN * 2}px;
  background: ${({ theme }) => theme.palette.common.primary};
  display: flex;
  border-radius: ${MIDDLE_RADIUS_PX};
  z-index: 10;
`;
