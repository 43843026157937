import React, { FC } from 'react';

import IKImage from '~/components/IKImage/IKImage';
import { BodyIntroText, H3 } from '~/components/UI/Texts';
import styled from '~/styled';
import { TINY_MARGIN, TINY_MARGIN_PX } from '~/theme';

interface InfoItemProps {
  iconUrl?: string | null;
  title?: string | null;
  description?: string | null;
  className?: string;
}

export const InfoItem: FC<InfoItemProps> = ({ iconUrl, title, description, className = '' }) => {
  return (
    <div className={className}>
      <TitleWrapper>
        <Icon path={iconUrl} />
        <Title variant="h3">{title}</Title>
      </TitleWrapper>
      <Description>{description}</Description>
    </div>
  );
};

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: ${TINY_MARGIN_PX};
`;
const Icon = styled(IKImage)`
  width: 32px;
  height: 32px;
  margin-right: ${TINY_MARGIN_PX};
`;
const Title = styled(H3)`
  && {
    margin-bottom: 0;
  }
`;
const Description = styled(BodyIntroText)`
  color: ${({ theme }) => theme.palette.common.gray};

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-left: ${TINY_MARGIN + 32}px;
  }
`;
