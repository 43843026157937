import React, { useContext, useState } from 'react';

import { Container, styled, TextField } from '@mui/material';
import { captureException } from '@sentry/react';

import PrivacyBlock from '~/blocks/PrivacyBlock';
import Checkbox from '~/components/Checkbox/Checkbox';
import CustomBlockWrapper from '~/components/CustomBlockWrapper';
import IKImage from '~/components/IKImage/IKImage';
import CustomButton from '~/components/UI/CustomButton/CustomButton';
import { H3 } from '~/components/UI/Texts';
import { useAlert } from '~/context/AlertProvider';
import { TenantCtx } from '~/context/TenantProvider';
import { UserInfoCtx } from '~/context/UserInfoProvider';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import { getUtmParameters } from '~/hooks/useTrackUtmParameters';
import useValidation from '~/hooks/useValidation';
import { MIDDLE_MARGIN_PX, SMALL_MARGIN_PX, SUBMIDDLE_MARGIN_PX } from '~/theme';
import UserCom from '~/utils/UserCom';
import { GtmEvents, sendEventToGTM } from '~/utils/gtmUtils';
import { createSchema, createValidation } from '~/utils/validator';

const imgTransform = [{ width: 400 }];
const initialValues = {
  email: '',
  newsletterPositiveConsent: true,
};
const validation = (t) =>
  createSchema({
    email: createValidation().required(t('error.fieldRequired')).email(t('error.emailIncorrect')),
  });

const RequestAFullCourseListBlock = ({ data }) => {
  const {
    background,
    image_url,
    seo_image_name,
    title,
    button_text,
    user_com_formId,
    form_title_automation,
  } = data;
  const hasImage = Boolean(image_url);

  const [loading, setLoading] = useState(false);

  const { userInfo } = useContext(UserInfoCtx);
  const { tenant } = useContext(TenantCtx);
  const { showAlert } = useAlert();
  const { t } = useTenantTranslation();
  const { values, errors, handleChange, setFieldManually, handleSubmit, resetToDefault } =
    useValidation(initialValues, validation, onSubmit);

  async function onSubmit(values) {
    try {
      setLoading(true);

      const data = {
        email: values.email,
        ...(!userInfo && { newsletter_positive_consent: values.newsletterPositiveConsent }),
        request_form_title_automation: form_title_automation || 'Request a full course list',
        form_tenant: tenant?.name || '',
        ...getUtmParameters(),
      };

      await UserCom.updateClient(data);
      await UserCom.sendEvent('form_conversion', {
        ...data,
        form_id: user_com_formId,
      });
      sendEventToGTM(GtmEvents.submitForm, { email: data.email });
      showAlert({ message: t('alert.getInTouch') });
      resetToDefault();
      setLoading(false);
    } catch (e) {
      captureException(e);
    }
  }

  return (
    <CustomBlockWrapper className="requestAFullCourseListBlock" background={background}>
      <StyledContainer>
        {image_url && (
          <ImgWrapper>
            <StyledImage
              alt={seo_image_name || ''}
              path={image_url}
              transforms={imgTransform}
              sizes="100vw"
            />
          </ImgWrapper>
        )}
        <ContentWrapper hasImage={hasImage}>
          <StyledForm
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <StyledTitle hasImage={hasImage} variant="h3">
              {title}
            </StyledTitle>
            <StyledTextField
              variant="outlined"
              name="email"
              type="email"
              value={values.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              label={`${t('input.placeholder.workEmail')}*`}
            />
            <StyledCustomButton type="submit" disabled={loading}>
              {button_text}
            </StyledCustomButton>
            {!userInfo && (
              <StyledCheckbox
                name="newsletterPositiveConsent"
                onChange={() =>
                  setFieldManually('newsletterPositiveConsent', !values?.newsletterPositiveConsent)
                }
                checked={values?.newsletterPositiveConsent}
              >
                {t('block.letsTalk.newsletterAgreement')}
              </StyledCheckbox>
            )}
          </StyledForm>
          <PrivacyBlock />
        </ContentWrapper>
      </StyledContainer>
    </CustomBlockWrapper>
  );
};

export default RequestAFullCourseListBlock;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
  }
`;

const ImgWrapper = styled('div')`
  display: none;
  position: relative;
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
    width: 33%;
    padding-right: ${SMALL_MARGIN_PX};
  }
`;

const StyledImage = styled(IKImage)`
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ContentWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasImage',
})<{ hasImage: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: ${({ hasImage }) => !hasImage && '1000px'};
  margin: ${({ hasImage }) => !hasImage && '0 auto'};

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: ${({ hasImage }) => hasImage && '67%'};
  }
`;
const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
  position: relative;
  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
`;
const StyledTitle = styled(H3, {
  shouldForwardProp: (prop) => prop !== 'hasImage',
})<{ hasImage: boolean }>`
  margin-bottom: ${SUBMIDDLE_MARGIN_PX};
  width: 100%;
  order: 1;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-bottom: ${SUBMIDDLE_MARGIN_PX};
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: ${({ hasImage }) => (hasImage ? '100%' : 'auto')};
    margin-right: ${({ hasImage }) => (hasImage ? 0 : MIDDLE_MARGIN_PX)};
  }
`;
const StyledTextField = styled(TextField)`
  margin-bottom: ${SUBMIDDLE_MARGIN_PX};
  flex: 1;
  min-width: 33%;
  order: 2;
`;
const StyledCheckbox = styled(Checkbox)`
  margin-bottom: ${SUBMIDDLE_MARGIN_PX};
  width: 100%;
  order: 3;
  ${({ theme }) => theme.breakpoints.up('md')} {
    order: 4;
  }
`;
const StyledCustomButton = styled(CustomButton)`
  margin-bottom: ${SUBMIDDLE_MARGIN_PX};
  order: 4;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-left: auto;
    margin-right: auto;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-left: ${MIDDLE_MARGIN_PX};
    margin-right: 0;
    order: 3;
  }
`;
