import { MegaMenuBlockTypes, MegaMenuLeftBlockItemsTypes } from '~/blocks/Header/types';
import { INSIGHTS_SERP_ROUTE } from '~/routes';
import { MegaMenuMiddleBlock, MegaMenuRightBlock } from '~/types';
import { checkIsIframed } from '~/utils/checkIsIframed';

export const LOGGED_IN_ITEMS: ExpandedNavigationItem[] = [
  {
    title: 'home',
    url: '/home',
    className: 'home-link',
    type: 'link',
  },
  {
    title: 'explore',
    url: '/discover',
    type: 'MegaMenu',
  },
  {
    title: 'analytics',
    url: '/analytics',
    managerUrl: '/analytics/dashboard',
    avoidManagerUrlWithoutSub: true,
    type: 'link',
  },
];

export const PROFILE_LINKS = [
  {
    title: 'header.myAccount',
    url: '/my-account/',
  },
  {
    title: 'header.myMembership',
    url: '/my-account/membership-area/',
  },
  {
    title: 'header.myWatchlist',
    url: '/analytics#watchlist',
  },
];
export const PROFILE_LINKS_SSKU = [
  {
    title: 'header.myAccount',
    url: '/my-account/edit-account',
  },
  {
    title: 'header.myWatchlist',
    url: '/analytics#watchlist',
  },
];

export type SubNavigationItem = {
  title: string;
  description: string;
  url: string;
};

export type ExpandedNavigationItem = {
  title: string;
  url: string;
  type: string;
  className?: string;
  managerUrl?: string;
  avoidManagerUrlWithoutSub?: boolean;
};

export const LEFT_MEGA_MENU = {
  item_type: MegaMenuBlockTypes.LeftBlock,
  title: 'learnerProfiles',
  external_link: '/discover/search',
  text_external_link: 'exploreAll',
  type_left_block: MegaMenuLeftBlockItemsTypes.ListItem,
};
export const LEFT_MEGA_MENU_SSKU = {
  item_type: MegaMenuBlockTypes.LeftBlock,
  external_link: null,
  text_external_link: null,
  type_left_block: MegaMenuLeftBlockItemsTypes.BlockItem,
};

const INSIGHTS_MENU_ITEM_LINK = `/discover${INSIGHTS_SERP_ROUTE}`;

export const MIDDLE_MEGA_MENU = {
  item_type: MegaMenuBlockTypes.MiddleBlock,
  title: 'exploreOurLearningPathways',
  items: [
    {
      title: 'shortCourses',
      sub_title: 'shortCoursesDescription',
      link: '/discover/pathways',
      image_url: '/headers-images/MegaMenuIcons/pathwaysIcon.svg',
    },
    {
      title: 'certificates',
      sub_title: 'certificatesDescription',
      link: '/courses',
      image_url: '/headers-images/MegaMenuIcons/coursesIcon.svg',
    },
    {
      title: 'videosModules',
      sub_title: 'videoModulesDescription',
      link: '/discover/videos',
      image_url: '/headers-images/MegaMenuIcons/videosIcon.svg',
    },
    {
      title: 'glossary',
      sub_title: 'glossaryDescription',
      link: '/discover/glossary',
      image_url: '/headers-images/MegaMenuIcons/glossaryIcon.svg',
    },
    {
      title: 'insights',
      sub_title: 'insightsDescription',
      link: INSIGHTS_MENU_ITEM_LINK,
      image_url: '/headers-images/MegaMenuIcons/insightsIcon.svg',
    },
  ],
};

export const MIDDLE_MEGA_MENU_SSKU = {
  item_type: MegaMenuBlockTypes.MiddleBlock,
  title: 'exploreOurLearningPathways',
  items: [
    {
      title: 'certificates',
      sub_title: 'certificatesDescription',
      link: '/discover/courses',
      image_url: '/headers-images/MegaMenuIcons/trackIcon.svg',
    },
    {
      title: 'shortCourses',
      sub_title: 'shortCoursesDescription',
      link: '/discover/pathways',
      image_url: '/headers-images/MegaMenuIcons/sprintIcon_updated.svg',
    },
    {
      title: 'videosModules',
      sub_title: 'videoModulesDescription',
      link: '/discover/videos',
      image_url: '/headers-images/MegaMenuIcons/stepIcon.svg',
    },
  ],
};

export const RIGHT_MEGA_MENU = {
  item_type: MegaMenuBlockTypes.RightBlock,
  title: 'trendingCourses',
};

export const formatMegaMenuItem = ({
  megaMenu,
  t,
  hasNews,
  baseCategoriesList,
  twoLatestVideos,
  isSsku,
}) => {
  const leftBlock = isSsku
    ? {
        ...LEFT_MEGA_MENU_SSKU,
        title: t(`header.skills`),
        items: [
          {
            title: t('header.digital'),
            sub_title: t('header.stayAhead'),
            link: '/discover/digital',
          },
          {
            title: t('header.leadership'),
            sub_title: t('header.excelWithInsights'),
            link: '/discover/leadership',
          },
          {
            title: t('header.netZero'),
            sub_title: t('header.masterSustainability'),
            link: '/discover/net-zero',
          },
        ],
      }
    : {
        ...LEFT_MEGA_MENU,
        title: t(`header.${LEFT_MEGA_MENU.title}`),
        text_external_link: t(`header.${LEFT_MEGA_MENU.text_external_link}`),
        items: baseCategoriesList?.map((item) => ({
          title: item?.title,
          link: `/discover/${item?.slug}`,
        })),
      };

  const middleMegaMenuData = isSsku ? MIDDLE_MEGA_MENU_SSKU : MIDDLE_MEGA_MENU;

  const middleBLock: MegaMenuMiddleBlock = {
    ...middleMegaMenuData,
    title: t(`header.${middleMegaMenuData.title}`),
    clickable_items: middleMegaMenuData.items
      .filter(
        (item) =>
          (item?.link === INSIGHTS_MENU_ITEM_LINK && hasNews) ||
          item.link !== INSIGHTS_MENU_ITEM_LINK,
      )
      .map((item) => ({
        ...item,
        title: t(`header.${item.title}`),
        sub_title: t(`header.${item.sub_title}`),
        list_items: [],
      })),
  };

  const rightBlock: MegaMenuRightBlock = {
    ...RIGHT_MEGA_MENU,
    title: t(`header.${RIGHT_MEGA_MENU.title}`),
    items: twoLatestVideos?.length
      ? twoLatestVideos.map((item) => ({
          title: item?.title,
          description: item?.description,
          image_url: item?.preview_url,
          link: `/videos/${item?.video_url}`,
          play_icon: true,
        }))
      : [],
  };

  return {
    ...megaMenu,
    title: t(`header.${megaMenu.title}`),
    blocks: [leftBlock, middleBLock, rightBlock],
  };
};

export const getHeaderActions = (navigationItems) => {
  return {
    buttonData: navigationItems?.find((item) => item.type === 'Button'),
    filledButtonData: navigationItems?.find((item) => item.type === 'FilledButton'),
    userData: navigationItems?.find((item) => item.type === 'User'),
    loginButton: navigationItems?.find((item) => item.type === 'LoginButton'),
  };
};

export const getButtonProps = (url, location, loginWithPopup) => {
  if (url === '/login') {
    if (checkIsIframed()) {
      return {
        to: '/login',
        onClick: (e) => {
          e.preventDefault();
          loginWithPopup();
        },
      };
    } else {
      return {
        to: 'login',
        state: { prevPath: location.pathname, prevSearch: location.search },
      };
    }
  } else {
    return {
      to: url,
    };
  }
};
