import React from 'react';

import { default as MaterialButton } from '@mui/material/Button';
import { alpha } from '@mui/material/styles';

import SafeLink from '~/components/SafeLink';
import styled from '~/styled';

const Button = ({ text = '', link = '#', isPrimary = true, className = '', ...rest }) => {
  return (
    <StyledButton
      variant="contained"
      className={className}
      data-testid={rest['data-testid']}
      isPrimary={isPrimary}
    >
      <SafeLink to={link}>{text}</SafeLink>
    </StyledButton>
  );
};

export default Button;

const StyledButton = styled(MaterialButton, {
  shouldForwardProp: (prop) => prop !== 'isPrimary' && prop !== 'additionalStyles',
})`
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  border-radius: 999em;
  box-shadow: none;
  border: 3px solid ${({ theme }) => theme.palette.common.primary};
  background: ${({ isPrimary, theme }) =>
    isPrimary ? theme.palette.common.transparent : alpha(theme.palette.common.white, 0.15)};
  padding: 0;
  transition: 0.3s ease all;

  & a {
    text-decoration: none;
    padding: 16px 32px;
    color: ${({ theme }) => theme.palette.common.white};
  }

  &:hover {
    box-shadow: 0px 3px 6px ${({ theme }) => alpha(theme.palette.common.blackPure, 0.4)};
    background: ${({ isPrimary, theme }) =>
      isPrimary ? theme.palette.primary.main : alpha(theme.palette.common.white, 0.15)};
    margin-bottom: 1px;
    margin-top: -1px;
    color: ${({ theme }) => theme.palette.common.blueDark};
  }

  & a:hover {
    color: ${({ theme }) => theme.palette.common.blueDark};
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 16px;
    padding: 0;
  }
`;
