import React, { useContext } from 'react';

import { Box, Typography } from '@mui/material';
import { alpha, css, styled } from '@mui/material/styles';

import AnimatedSkeleton from '~/components/AnimatedSkeleton';
import ConditionalBlockLink from '~/components/ConditionalBlockLink/ConditionalBlockLink';
import LazyLoadImage from '~/components/LazyloadImage/LazyLoadImage';
import FollowButton from '~/components/buttons/FollowButton';
import { DEFAULT_DISCOVER_URL } from '~/const';
import { UserInfoCtx } from '~/context/UserInfoProvider';
import useFollowExperts from '~/hooks/useFollowExperts';
import useIsIE from '~/hooks/useIsIE';
import { CssUtilityType } from '~/styled';
import { BREAKPOINTS_VALUES } from '~/theme';

export type ExpertCardType = 'light' | 'dark';

const defaultSrcset = [
  { width: 215, height: 215, focus: 'auto' },
  { width: 365, height: 365, focus: 'auto' },
  { width: 220, height: 310, focus: 'auto' },
];
const defaultSizes = `(max-width:${BREAKPOINTS_VALUES['sm']}px) 215px, (max-width: ${BREAKPOINTS_VALUES['md']}) 365px, 500px`;
const defaultTransformation = [
  {
    width: 220,
    height: 310,
    focus: 'auto',
  },
];

interface ExpertCardProps {
  id?: number;
  title?: string;
  jobTitle?: string;
  imageUrl?: string;
  slug?: string;
  wrapperCss?: CssUtilityType;
  srcset?: any;
  sizes?: string;
  transformation?: any;
  withLink?: boolean;
  type?: ExpertCardType;
  loading?: boolean;
}

const ExpertCard: React.FC<ExpertCardProps> = ({
  id,
  title,
  jobTitle,
  imageUrl,
  slug,
  wrapperCss,
  srcset = defaultSrcset,
  sizes = defaultSizes,
  transformation = defaultTransformation,
  withLink = false,
  type = 'light',
  loading = false,
}) => {
  //check on IE browser to replace srcset with custom image transformation
  const isIE = useIsIE();
  const {
    followed,
    toggleFollowing,
    loading: loadingToggleFollowing,
  } = useFollowExperts([id?.toString()]);
  const { userInfo } = useContext(UserInfoCtx);

  if (loading) {
    return (
      <Wrapper type={type} optionalStyles={wrapperCss}>
        <StyledContainer>
          <LoadingCard />
        </StyledContainer>
      </Wrapper>
    );
  }

  return (
    <Wrapper type={type} optionalStyles={wrapperCss} data-testid="expertCard">
      <StyledContainer>
        <StyledConditionalLink to={`${DEFAULT_DISCOVER_URL}/experts/${slug}`} withLink={withLink}>
          <ImgWrapper type={type}>
            {imageUrl && (
              <StyledLazyLoadImage
                path={imageUrl}
                alt={title}
                srcset={srcset}
                transformation={isIE ? transformation : null}
                sizes={sizes}
                data-object-fit="cover"
                wrapperCss={generatedImgLazyWrapper}
              />
            )}
          </ImgWrapper>
        </StyledConditionalLink>
        {!!userInfo && (
          <StyledFollowButton
            loading={loadingToggleFollowing}
            followed={followed}
            onlyIcon
            onClick={(e) => {
              e.stopPropagation();
              toggleFollowing();
            }}
          />
        )}
        <TextWrapper>
          <StyledConditionalLink
            data-testid="expertCardLink"
            withLink={withLink}
            to={`${DEFAULT_DISCOVER_URL}/experts/${slug}`}
          >
            <Title variant="h3">{title}</Title>
          </StyledConditionalLink>
          <JobTitle>{jobTitle}</JobTitle>
        </TextWrapper>
      </StyledContainer>
    </Wrapper>
  );
};

export default ExpertCard;

const LoadingCard = styled(AnimatedSkeleton)`
  width: 100%;
  height: 100%;
`;

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'type' && prop !== 'optionalStyles',
})<{ type: ExpertCardType; optionalStyles?: CssUtilityType }>`
  background: ${({ type, theme }) =>
    type === 'dark'
      ? theme.palette.common.blockBackground.main
      : theme.palette.common.card.smallCardBg};
  border-radius: 10px;
  height: 300px;
  overflow: hidden;
  position: relative;

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 348px;
  }

  ${({ optionalStyles }) => optionalStyles}
`;
const StyledContainer = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
`;
const StyledConditionalLink = styled(ConditionalBlockLink)`
  color: ${({ theme }) => theme.palette.common.white};
`;
const ImgWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'type',
})<{ type: ExpertCardType }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 198px;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 100%;
    height: 133px;
    background: ${({ type, theme }) =>
      type === 'dark'
        ? 'linear-gradient(0deg, ' +
          theme.palette.common.blockBackground.main +
          ' 0%, ' +
          alpha(theme.palette.common.blockBackground.main, 0.9) +
          ' 17.24%, ' +
          alpha(theme.palette.common.blockBackground.main, 0) +
          ' 100%)'
        : `${theme.palette.common.card.smallCardGradient} 0% 0% no-repeat`};
    -webkit-background-origin: padding-box;
    -o-background-origin: padding-box;
    -moz-background-origin: padding;
    z-index: 0;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 274px;
  }
`;
const StyledLazyLoadImage = styled(LazyLoadImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const generatedImgLazyWrapper = css`
  width: 100%;
  height: 100%;
  position: relative;
`;
const TextWrapper = styled(Box)`
  padding: 0 24px 21px 24px;
  z-index: 2;
`;
const Title = styled(Typography)`
  & {
    font-family: ${({ theme }) => theme.typography.specialFontFamily};
    color: ${({ theme }) => theme.palette.common.white};
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    display: -webkit-box;
    margin-bottom: 9px;

    ${({ theme }) => theme.breakpoints.up('md')} {
      font-size: 20px;
      line-height: 25px;
    }
  }
`;
const JobTitle = styled(Typography)`
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.palette.common.text.secondaryToPrimary};

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 14px;
    line-height: 18px;
  }
`;
const StyledFollowButton = styled(FollowButton)`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin: 12px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.common.card.background.light};
  transition: 0.3s;

  &:hover {
    background: ${({ theme }) => alpha(theme.palette.common.card.background.light, 0.66)};
  }
`;
