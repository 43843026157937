import React, { forwardRef } from 'react';

import { Box } from '@mui/material';

import HeroBackground from '~/components/Hero/HeroBackground';
import { HERO_IMG_SRC_SET } from '~/const';
import useIsSsku from '~/hooks/tenant/useIsSsku';
import styled, { CssUtilityType } from '~/styled';
import { LARGE_MARGIN_PX } from '~/theme';
import { Maybe } from '~/types';

export interface HeroLayoutProps {
  children?: React.ReactNode;
  imageUrl?: Maybe<string>;
  foregroundUrl?: Maybe<string>;
  foregroundAlt?: Maybe<string>;
  imageSrcset?: any[];
  image?: any;
  wrapperCss?: CssUtilityType;
  imageWrapperCss?: CssUtilityType;
  contentWrapperCss?: CssUtilityType;
  contentWrapperClass?: string;
  fadedOverlay?: boolean;
  fadedFromLeftToRight?: boolean;
  fadedFromBottomToTop?: boolean;
  alt?: Maybe<string>;
  foregroundCss?: CssUtilityType;
}

const HeroLayout = forwardRef<HTMLDivElement, HeroLayoutProps>(
  (
    {
      children,
      imageUrl,
      imageSrcset = HERO_IMG_SRC_SET,
      image,
      wrapperCss,
      imageWrapperCss,
      contentWrapperCss,
      contentWrapperClass = '',
      fadedOverlay = true,
      fadedFromLeftToRight = true,
      fadedFromBottomToTop = true,
      alt,
      foregroundUrl,
      foregroundAlt,
      foregroundCss,
    },
    ref,
  ) => {
    const isSsku = useIsSsku();

    return (
      <Wrapper ref={ref} optionalStyles={wrapperCss} isSsku={isSsku}>
        <HeroBackground
          backgroundUrl={imageUrl}
          srcset={imageSrcset}
          backgroundAbsoluteUrl={image}
          wrapperCss={imageWrapperCss}
          fadedOverlay={fadedOverlay}
          fadedFromLeftToRight={fadedFromLeftToRight}
          fadedFromBottomToTop={fadedFromBottomToTop}
          backgroundAlt={alt}
          foregroundAlt={foregroundAlt}
          foregroundUrl={foregroundUrl}
          foregroundCss={foregroundCss}
        />
        <ContentWrapper
          withForeground={!!foregroundUrl}
          className={contentWrapperClass}
          optionalStyles={contentWrapperCss}
        >
          {children}
        </ContentWrapper>
      </Wrapper>
    );
  },
);

export default HeroLayout;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'optionalStyles' && prop !== 'isSsku',
})`
  position: relative;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-bottom: ${({ isSsku, theme }) => isSsku && theme.palette.mode === 'light' && '110px'};
  }

  ${({ optionalStyles }) => optionalStyles}
`;
const ContentWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'optionalStyles' && prop !== 'withForeground',
})`
  padding-top: 100px;
  padding-bottom: ${LARGE_MARGIN_PX};
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 5;
  position: relative;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-top: ${({ withForeground }) => (withForeground ? 140 : 200)}px;
    padding-bottom: ${LARGE_MARGIN_PX};
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding-top: 200px;
  }

  && {
    ${({ optionalStyles }) => optionalStyles}
  }
`;
