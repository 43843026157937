import React, { useState, FC, useContext } from 'react';

import ChevronTopIcon from '~/assets/icons/ChevronTopIcon';
import LeftMegaMenu from '~/blocks/Header/components/LeftMegaMenu';
import LinkWithArrow from '~/blocks/Header/components/LinkWithArrow';
import LogoutMegaMenuFooter from '~/blocks/Header/components/LogoutMegaMenuFooter';
import MiddleMegaMenu from '~/blocks/Header/components/MiddleMegaMenu';
import RightMegaMenu from '~/blocks/Header/components/RightMegaMenu';
import { MegaMenuBlockTypes } from '~/blocks/Header/types';
import { H4 } from '~/components/UI/Texts';
import { UserInfoCtx } from '~/context/UserInfoProvider';
import styled from '~/styled';
import { MIDDLE_MARGIN_PX, MIDDLE_RADIUS_PX, SMALL_MARGIN_PX, useIsWidthUp } from '~/theme';
import {
  MegaMenuLeftBlock,
  MegaMenuMiddleBlock,
  MegaMenuRightBlock,
  NavigationMegaMenuBlock,
} from '~/types';

interface MegaMenuLinkProps {
  blocks?: NavigationMegaMenuBlock[] | null;
}

const MegaMenuLink: FC<MegaMenuLinkProps> = ({ blocks }) => {
  const [openedSubmenu, setOpenedSubmenu] = useState(1);
  const { userInfo } = useContext(UserInfoCtx);
  const isMobile = !useIsWidthUp('md');

  const leftBlock = blocks?.find(
    (item) => item?.item_type === MegaMenuBlockTypes.LeftBlock,
  ) as MegaMenuLeftBlock;
  const middleBlock = blocks?.find(
    (item) => item?.item_type === MegaMenuBlockTypes.MiddleBlock,
  ) as MegaMenuMiddleBlock;
  const rightBlock = blocks?.find(
    (item) => item?.item_type === MegaMenuBlockTypes.RightBlock,
  ) as MegaMenuRightBlock;

  const toggleDropdown = (index) => {
    setOpenedSubmenu((prevState) => (prevState === index ? 0 : index));
  };

  const isRow = leftBlock.blocks_in_row;
  const shouldDisplayTitle = isMobile ? !!middleBlock : true;

  return (
    <Wrapper>
      <ColumnsWrapper>
        <LeftWrapper alignedLeft={isRow}>
          {leftBlock && (
            <LeftColumn isRow={isRow} data-testid="megamenuLeftColumn">
              {shouldDisplayTitle && (
                <ColumnTitle onClick={() => toggleDropdown(2)}>
                  <Title>{leftBlock?.title}</Title>
                  <ArrowIcon expanded={openedSubmenu === 2} />
                </ColumnTitle>
              )}
              <Content expanded={openedSubmenu === middleBlock ? 2 : 1}>
                <LeftMegaMenu
                  isRow={leftBlock?.blocks_in_row}
                  subtitle={shouldDisplayTitle ? leftBlock?.sub_title : undefined}
                  externalLink={leftBlock?.external_link}
                  textExternalLink={leftBlock?.text_external_link}
                  itemsType={leftBlock?.type_left_block}
                  items={leftBlock?.items}
                />
              </Content>
            </LeftColumn>
          )}
          {middleBlock && (
            <MiddleColumn data-testid="megamenuMiddleColumn">
              <ColumnTitle onClick={() => toggleDropdown(1)}>
                <Title>{middleBlock?.title}</Title>
                <ArrowIcon expanded={openedSubmenu === 1} />
              </ColumnTitle>
              <Content expanded={openedSubmenu === 1}>
                <MiddleMegaMenu
                  linkItems={middleBlock?.clickable_items}
                  checkItems={middleBlock?.informational_items}
                />
              </Content>
            </MiddleColumn>
          )}
        </LeftWrapper>
        {rightBlock && (
          <RightColumn data-testid="megamenuRightColumn">
            <RightContent>
              <ColumnTitle>
                <Title>{rightBlock?.title}</Title>
                {Boolean(rightBlock?.text_external_link && rightBlock?.external_link) && (
                  <StyledLinkWithArrow
                    title={rightBlock.text_external_link}
                    to={rightBlock.external_link}
                  />
                )}
              </ColumnTitle>
              <RightMegaMenu
                items={rightBlock?.items}
                link={rightBlock?.external_link}
                linkText={rightBlock?.text_external_link}
              />
            </RightContent>
          </RightColumn>
        )}
      </ColumnsWrapper>
      {!userInfo && <StyledLogoutMegaMenuFooter />}
    </Wrapper>
  );
};

export default MegaMenuLink;

const Wrapper = styled('div')`
  width: 100%;
  background: ${({ theme }) => theme.palette.common.header.overlay};
  ${({ theme }) => theme.breakpoints.up('xl')} {
    background: ${({ theme }) => theme.palette.common.blockBackground.light};
  }
  transition: background 0.5s ease-in-out;
`;

const ColumnsWrapper = styled('div')`
  padding: ${MIDDLE_MARGIN_PX} 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.up('xl')} {
    flex-direction: row;
    padding: ${MIDDLE_MARGIN_PX} 0;
  }
`;

const LeftWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'alignedLeft',
})`
  display: flex;
  flex-direction: column-reverse;
  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
  }
  ${({ theme }) => theme.breakpoints.up('xl')} {
    padding: 0 9%;
    width: 100%;
    justify-content: ${({ alignedLeft }) => (alignedLeft ? 'flex-start' : 'flex-end')};
  }
  @media (min-width: 1440px) {
    padding-right: 0;
    padding-left: 5%;
    width: 68%;
    justify-content: ${({ alignedLeft }) => (alignedLeft ? 'flex-start' : 'flex-end')};
  }
  @media (min-width: 1660px) {
    padding-left: 7%;
    width: 65%;
  }
`;

const LeftColumn = styled('div')`
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: ${({ isRow }) => (isRow ? 100 : 35)}%;
    margin-right: ${MIDDLE_MARGIN_PX};
  }
  ${({ theme }) => theme.breakpoints.up('xl')} {
    width: ${({ isRow }) => (isRow ? '100%' : '232px')};
    min-width: 232px;
  }
  @media (min-width: 1440px) {
    min-width: unset;
    max-width: ${({ isRow }) => (isRow ? '100%' : '281px')};
    width: ${({ isRow }) => (isRow ? 100 : 12)}%;
    flex: 58;
  }
`;

const MiddleColumn = styled('div')`
  ${({ theme }) => theme.breakpoints.up('xl')} {
    flex: 1;
  }

  @media (min-width: 1440px) {
    max-width: 797px;
    width: 41%;
    flex: 199;
  }
`;

const RightColumn = styled('div')`
  display: none;
  border-bottom-left-radius: ${MIDDLE_RADIUS_PX};
  border-top-left-radius: ${MIDDLE_RADIUS_PX};
  @media (min-width: 1440px) {
    display: block;
    background: ${({ theme }) => theme.palette.common.blockBackground.main};
    margin-left: ${MIDDLE_MARGIN_PX};
    padding-right: 3%;
    width: 32%;
  }
  @media (min-width: 1660px) {
    padding-right: 5%;
    width: 35%;
  }
`;

const RightContent = styled('div')`
  ${({ theme }) => theme.breakpoints.up('xl')} {
    padding: ${MIDDLE_MARGIN_PX};
    padding-right: 0;
  }
`;

const ColumnTitle = styled('div')`
  margin-bottom: ${SMALL_MARGIN_PX};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled(H4)`
  font-weight: bold;

  ${({ theme }) => theme.breakpoints.up('xl')} {
    text-transform: ${({ theme }) => (theme.isSsku ? 'capitalize' : 'uppercase')};
  }
`;

const Content = styled('div', {
  shouldForwardProp: (prop) => prop !== 'expanded',
})`
  display: ${({ expanded }) => (expanded ? 'block' : 'none')};
  margin-bottom: ${({ expanded }) => (expanded ? MIDDLE_MARGIN_PX : 0)};

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: block;
    margin-bottom: 0;
  }
`;

const ArrowIcon = styled(ChevronTopIcon, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})`
  display: block;
  width: 12px;
  height: 12px;
  transform: ${({ expanded }) => (expanded ? 'rotate(0)' : 'rotate(180deg)')};
  transition: transform 0.3s;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }
`;

const StyledLinkWithArrow = styled(LinkWithArrow)`
  display: none;

  ${({ theme }) => theme.breakpoints.between('md', 'xl')} {
    display: flex;
  }
`;

const StyledLogoutMegaMenuFooter = styled(LogoutMegaMenuFooter)`
  display: none;

  ${({ theme }) => theme.breakpoints.up('xl')} {
    display: flex;
  }
`;
