import React, { FC } from 'react';

import useTenantTranslation from '~/hooks/useTenantTranslation';
import NotificationRadioItem from '~/pages/AssignedLearningPage/components/NotificationsRadioGroup/NotificationRadioItem';
import { ASL_MANAGER_PAGE_EVENTS } from '~/pages/AssignedLearningPage/const';
import styled from '~/styled';
import { SUBMIDDLE_MARGIN_PX } from '~/theme';
import { NotificationSettings } from '~/types';
import sendAnalyticEvent from '~/utils/sendAnalyticEvent';

const NOTIFICATIONS_GTM_EVENTS_MAP: Record<
  Extract<NotificationSettings, 'auto' | 'week' | 'month'>,
  string
> = {
  auto: ASL_MANAGER_PAGE_EVENTS.aslNotificationsAuto,
  week: ASL_MANAGER_PAGE_EVENTS.aslNotificationsWeek,
  month: ASL_MANAGER_PAGE_EVENTS.aslNotificationsMonth,
};

interface NotificationsRadioGroupProps {
  value: NotificationSettings;
  handleChange: (val: NotificationSettings) => void;
  className?: string;
}

const NotificationsRadioGroup: FC<NotificationsRadioGroupProps> = ({
  value,
  handleChange,
  className = '',
}) => {
  const { t } = useTenantTranslation();

  function handleNotificationsDurationChange(value: NotificationSettings) {
    return () => {
      sendAnalyticEvent(NOTIFICATIONS_GTM_EVENTS_MAP[value]);
      handleChange(value);
    };
  }

  return (
    <Wrapper className={className}>
      <StyledNotificationRadioItem
        name={NotificationSettings.Auto}
        checked={NotificationSettings.Auto === value}
        label={t('page.assignedLearnings.auto')}
        tooltip={t('page.assignedLearnings.automaticDescription')}
        data-testid="notificationAutomatic"
        onChange={handleNotificationsDurationChange(NotificationSettings.Auto)}
      />
      <StyledNotificationRadioItem
        name={NotificationSettings.Week}
        checked={NotificationSettings.Week === value}
        label={t('page.assignedLearnings.week')}
        tooltip={t('page.assignedLearnings.weeklyDescription')}
        data-testid="notificationWeekly"
        onChange={handleNotificationsDurationChange(NotificationSettings.Week)}
      />
      <StyledNotificationRadioItem
        name={NotificationSettings.Month}
        checked={NotificationSettings.Month === value}
        label={t('page.assignedLearnings.month')}
        tooltip={t('page.assignedLearnings.monthlyDescription')}
        data-testid="notificationMonthly"
        onChange={handleNotificationsDurationChange(NotificationSettings.Month)}
      />
    </Wrapper>
  );
};

export default NotificationsRadioGroup;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
  }
`;
const StyledNotificationRadioItem = styled(NotificationRadioItem)`
  margin-right: ${SUBMIDDLE_MARGIN_PX};

  &:last-child {
    margin-right: 0;
  }
`;
