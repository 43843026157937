import { useCallback, useState } from 'react';

import useEventListener from '~/hooks/useEventListener';
import useOverflowHidden from '~/hooks/useOverflowHidden';
import { Modals } from '~/modals/types';

const useMultipleModals = (preventCloseOnOutsideClick: boolean) => {
  const [stack, setStack] = useState<Modals[]>([]);
  const [modalProps, setModalProps] = useState({});
  /**
   * In most cases, you should pass props to <Modal> explicitly.
   *
   * However, in specific cases, you can pass props as the second argument to the openModal method, like this: openModal("modalName", { additionalProp: propValue }).
   */
  const openModal = useCallback((name: Modals, params = {}) => {
    setModalProps((paramsMap) => ({ ...paramsMap, [name]: params }));

    setStack((queue) => {
      const alreadyOpened = queue.includes(name);
      return !alreadyOpened ? [...queue, name] : queue;
    });
  }, []);

  const closeModal = useCallback(() => {
    setStack((stack) => {
      const modalName = stack.at(-1);
      if (!modalName) return stack;
      // cleanup modal params before closing
      setModalProps((paramsMap) => ({ ...paramsMap, [modalName]: undefined }));
      return stack.filter((name) => name !== modalName);
    });
  }, []);

  const closeAllModals = useCallback(() => {
    // Clear the queue and modal props
    setStack([]);
    setModalProps({});
  }, []);

  useEventListener('keyup', (e) => {
    if (!preventCloseOnOutsideClick && (e.key === 'Escape' || e.keyCode === 27)) closeModal();
  });

  // The "overflow: hidden" property must be set manually because the container for the MUI Dialog was redefined
  useOverflowHidden(stack.length > 0);

  return {
    openModal,
    modalProps,
    stack,
    closeModal,
    closeAllModals,
  };
};

export default useMultipleModals;
