import React, { FC, Fragment, ReactNode, useCallback } from 'react';

import { alpha } from '@mui/material/styles';

import InfoIcon from '~/assets/icons/InfoIcon';
import { LightTooltip } from '~/components/UI/LightTooltip/LightTooltip';
import styled, { CssUtilityType } from '~/styled';

interface IHintIconTooltipped {
  title?: ReactNode;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  className?: string;
  size?: number;
  tooltipCss?: CssUtilityType;
}

const HintIconTooltipped: FC<IHintIconTooltipped> = ({
  title,
  placement = 'top',
  className,
  size = 14,
  tooltipCss,
}) => {
  const Wrapper = useCallback(
    ({ children }) =>
      title ? (
        <StyledTooltip optionalStyles={tooltipCss} title={title} placement={placement}>
          {children}
        </StyledTooltip>
      ) : (
        <Fragment>{children}</Fragment>
      ),
    [tooltipCss, title, placement],
  );

  return (
    <Wrapper>
      <IconWrapper className={className}>
        <StyledInfoIcon size={size} />
      </IconWrapper>
    </Wrapper>
  );
};

export default HintIconTooltipped;

const StyledTooltip = styled(LightTooltip, {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})`
  &:before {
    display: none;
  }

  ${({ optionalStyles }) => optionalStyles}
`;
const StyledInfoIcon = styled(InfoIcon, {
  shouldForwardProp: (prop) => prop !== 'size',
})`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size};
  color: ${({ theme }) => alpha(theme.palette.common.white, 0.33)};
`;
const IconWrapper = styled('span')`
  display: flex;
`;
