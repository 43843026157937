import React, { FC } from 'react';

import { Container } from '@mui/material';
import { alpha } from '@mui/material/styles';

import PlaySmallIcon from '~/assets/icons/PlaySmallIcon';
import IKImage from '~/components/IKImage/IKImage';
import IconButton from '~/components/UI/IconButton/IconButton';
import { BodyText, H2 } from '~/components/UI/Texts';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import Modal from '~/modals/Modal';
import { Modals } from '~/modals/types';
import styled from '~/styled';
import { MIDDLE_MARGIN_PX, LARGE_MARGIN_PX, SMALL_MARGIN_PX, SUBMIDDLE_MARGIN_PX } from '~/theme';
import { Background, IntroImageBlock } from '~/types';

interface ImageAndTextBlockProps {
  data?: IntroImageBlock;
}

const ImageAndTextBlock: FC<ImageAndTextBlockProps> = ({ data }) => {
  const { t } = useTenantTranslation();
  const isDark = data?.background === Background.Dark;

  return (
    <Wrapper className={isDark ? 'dark-block' : 'light-block'} isDark={isDark}>
      <StyledContainer>
        <ImageWrapper>
          <Image path={data?.preview_image_url} />
          {data?.button_url && (
            <Modal url={data?.button_url} bumperUrl={null}>
              {({ openModal }) => (
                <PlayBtn
                  aria-label={t('button.playVideo')}
                  onClick={() => openModal(Modals.VideoModal)}
                >
                  <PlayBtnIcon />
                </PlayBtn>
              )}
            </Modal>
          )}
        </ImageWrapper>
        <TextWrapper>
          <Title variant="h2">{data?.heading}</Title>
          <Description>{data?.description}</Description>
          {data?.button_url && (
            <Modal url={data?.button_url} bumperUrl={null}>
              {({ openModal }) => (
                <IconButton
                  icon={<PlayIcon color={'currentColor'} />}
                  label={data?.button_text || ''}
                  onClick={() => openModal(Modals.VideoModal)}
                  size="large"
                />
              )}
            </Modal>
          )}
        </TextWrapper>
      </StyledContainer>
    </Wrapper>
  );
};

export default ImageAndTextBlock;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDark',
})`
  background: ${({ theme }) => theme.palette.secondary.dark};
  padding: ${MIDDLE_MARGIN_PX} 0 ${MIDDLE_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${LARGE_MARGIN_PX} 0 ${LARGE_MARGIN_PX};
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    background: ${({ isDark, theme }) =>
      isDark ? theme.palette.secondary.dark : theme.palette.secondary.light};
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: 0;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-left: 16px;
    padding-right: 16px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0 ${SUBMIDDLE_MARGIN_PX};
    flex-direction: row;
    align-items: center;
  }
`;

const ImageWrapper = styled('div')`
  position: relative;
  width: 100%;
  margin-bottom: ${SUBMIDDLE_MARGIN_PX};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 50%;
    margin-bottom: 0;
  }
`;

const PlayBtn = styled('button')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background: ${({ theme }) => alpha(theme.palette.common.blueDark, 0.6)};
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 62px;
    height: 62px;
  }
`;

const PlayBtnIcon = styled(PlaySmallIcon)`
  width: 20px;
  height: 20px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 32px;
    height: 32px;
  }
`;

const Image = styled(IKImage)`
  width: 100%;
  max-width: 100%;
  border-radius: 5px;
`;

const TextWrapper = styled('div')`
  width: 100%;
  padding: 0 16px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 0;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 50%;
    padding-left: 10%;
  }
`;

const Title = styled(H2)`
  margin-bottom: ${SMALL_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-bottom: ${SMALL_MARGIN_PX};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: ${SUBMIDDLE_MARGIN_PX};
  }
`;

const Description = styled(BodyText)`
  &:not(:last-child) {
    margin-bottom: ${SUBMIDDLE_MARGIN_PX};
  }
`;

const PlayIcon = styled(PlaySmallIcon)`
  width: 24px;
  height: 24px;
`;
