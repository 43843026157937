import React, { FC, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import parse from 'date-fns/parse';

import DatePicker from '~/components/DatePicker/DatePicker';
import CustomButton from '~/components/UI/CustomButton/CustomButton';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import { ModalWrapper, StyledCloseIcon } from '~/modals/modalStyles';
import { ModalProps } from '~/modals/types';
import styled, { css } from '~/styled';
import { SMALL_MARGIN_PX } from '~/theme';

export interface IExtendDueDateModal {
  newDate: string;
  setNewDate: Function;
  dueDate: string;
  onSubmit: () => void;
}

const ExtendDueDateModal: FC<IExtendDueDateModal & ModalProps> = ({
  onSubmit,
  dueDate,
  newDate,
  setNewDate,
  closeModal,
  labelledBy,
}) => {
  const { t } = useTenantTranslation();

  const date = new Date();
  const dueDateFormat = new Date(dueDate);
  const minDate = dueDateFormat.getTime() > date.getTime() ? dueDateFormat : date;

  // TODO: cover with test
  const [dateVal, setDateVal] = useState<Date>(
    newDate ? parse(newDate, 'yyyy-MM-dd', new Date()) : minDate,
  );

  const onChangeDate = (date: Date | null): void => {
    if (!date) return;

    const selectedDate = format(date, 'yyyy-MM-dd');
    setDateVal(date);
    setNewDate(selectedDate);
  };

  const onCloseModal = () => {
    closeModal?.();
    setNewDate('');
  };

  return (
    <ModalWrapper data-testid="extendDueDateModal">
      <StyledCloseIcon onClick={onCloseModal} />
      <Typography id={labelledBy} variant="h3">
        {t('page.assignedLearnings.extendDueDateTitle')}
      </Typography>
      <Description>{t('page.assignedLearnings.extendDueDateDescription')}</Description>
      <Box>
        <DatePicker
          data-testid="extendDueDatePicker"
          label={t('page.assignedLearnings.date')}
          minDate={minDate}
          textFieldCss={textFieldCss}
          value={dateVal}
          onChange={onChangeDate}
          placement="bottom"
        />
      </Box>
      <Box>
        <StyledCustomButton
          data-testid="cancelExtending"
          className="cancel-btn"
          onClick={onCloseModal}
          color="secondary"
        >
          {t('button.cancel')}
        </StyledCustomButton>
        <CustomButton
          data-testid="submitExtending"
          onClick={async () => {
            await onSubmit();
            closeModal?.();
          }}
          btnClass={'confirm-btn'}
          disabled={format(dueDateFormat, 'yyyy-MM-dd') === format(dateVal, 'yyyy-MM-dd')}
        >
          {t('button.extend')}
        </CustomButton>
      </Box>
    </ModalWrapper>
  );
};

export default ExtendDueDateModal;

const textFieldCss = ({ theme }) => css`
  margin-top: -20px;
  margin-bottom: 30px;
  width: 100%;

  ${theme.breakpoints.up('md')} {
    min-width: 412px;
  }
`;
const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.gray};
  margin: 24px 0 50px;
`;
const StyledCustomButton = styled(CustomButton)`
  margin-right: ${SMALL_MARGIN_PX};
`;
