import React, { FC, useState } from 'react';

import { captureException } from '@sentry/react';

import IKImage, { InterfaceIKImage } from '~/components/IKImage/IKImage';
import Loader from '~/components/Loader/Loader';
import Ratio from '~/components/Ratio';
import styled, { css, CssUtilityType } from '~/styled';

interface ImageWithLoaderProps extends InterfaceIKImage {
  ratio: number;
  className?: string;
  imgWrapperCss?: CssUtilityType;
  imageCss?: CssUtilityType;
}

const ImageWithLoader: FC<ImageWithLoaderProps> = ({
  ratio,
  className = '',
  imgWrapperCss,
  imageCss,
  path,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = () => {
    setIsLoading(false);
  };

  const handleError = (e) => {
    setIsLoading(false);
    captureException(e);
  };

  return (
    <Ratio
      ratio={ratio}
      className={className}
      contentWrapperCss={imgWrapperCss ? [imgWrapper, imgWrapperCss] : imgWrapper}
    >
      {isLoading && <Loader global={false} data-testid={`${rest['data-testid']}Loader`} />}
      <StyledIKImage
        path={path}
        onLoad={handleLoad}
        onError={handleError}
        optionalStyles={imageCss}
        {...rest}
      />
    </Ratio>
  );
};

export default ImageWithLoader;

const StyledIKImage = styled(IKImage, {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})`
  ${({ optionalStyles }) => optionalStyles}
`;
const imgWrapper = ({ theme }) => css`
  background: ${theme.palette.common.card.lighterAccent};
`;
