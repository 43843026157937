import React from 'react';

import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper/types/swiper-options';

import NewsCard from '~/components/NewsBlock/NewsCard';
import Button from '~/components/UI/Button/Button';
import { DEFAULT_DISCOVER_URL } from '~/const';
import { GET_ALL_NEWS } from '~/graphql/news';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import { DiscoverRoutes } from '~/routes';
import styled from '~/styled';
import { BREAKPOINTS_VALUES, MIDDLE_MARGIN_PX } from '~/theme';
import { Query, QueryListPostsArgs, SortOrder } from '~/types';
import { checkIsSSR } from '~/utils/checkIsSSR';
import { formatDateToNewsFormat } from '~/utils/dateUtils/formatDateToNewsFormat';

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) => <WrappedComponent {...props} width="xs" />;

const NEWS_SORT_PARAM = [
  {
    column: 'published_at',
    order: SortOrder.Desc,
  },
];

const NewsBlock = ({ data: newsBlockData }) => {
  const background = newsBlockData?.background;
  const newsLimit = newsBlockData?.news_limit;
  const buttonText = newsBlockData?.button_text;
  const buttonUrl = newsBlockData?.button_url;
  const { t, i18n } = useTenantTranslation();
  const isSSR = checkIsSSR();

  const { data, loading: loadingData } = useQuery<Pick<Query, 'listPosts'>, QueryListPostsArgs>(
    GET_ALL_NEWS,
    {
      skip: isSSR,
      variables: {
        page: 1,
        first: newsLimit || 3,
        sort: NEWS_SORT_PARAM,
      },
    },
  );
  const loading = loadingData || isSSR;

  const news = [...(data?.listPosts?.data || [])];
  const heading = newsBlockData?.heading || t('common.noHeading');

  const swiperSettings: SwiperOptions = {
    slidesPerView: news?.length < newsLimit ? news?.length : newsLimit,
    observer: true,
    spaceBetween: 16,
    pagination: {
      clickable: true,
    },
    breakpoints: {
      [BREAKPOINTS_VALUES.xl]: {
        slidesPerView: 3,
      },
      [BREAKPOINTS_VALUES.lg]: {
        slidesPerView: 3,
      },
      [BREAKPOINTS_VALUES.md]: {
        slidesPerView: 3,
      },
      [BREAKPOINTS_VALUES.sm]: {
        slidesPerView: 2.1,
      },
      [BREAKPOINTS_VALUES.xs]: {
        slidesPerView: 1.1,
        slidesOffsetAfter: 16,
      },
    },
  };

  return (
    <Wrapper
      className={cx(background === 'Dark' ? 'dark-block' : 'light-block', 'news-block')}
      background={background}
    >
      <StyledContainer background={background}>
        <Heading variant="h2" component="h2" align="center">
          {heading}
        </Heading>

        <SliderWrapper className="news-slider">
          <Swiper {...swiperSettings}>
            {loading
              ? new Array(3).fill(null).map((item, index) => (
                  <SwiperSlide key={index}>
                    <NewsCard loading />
                  </SwiperSlide>
                ))
              : news &&
                news.map((item) => {
                  const { id, slug, user, image_url, title, post_introduction, published_at } =
                    item;

                  const name = user?.name;
                  const avatar = user?.avatar;
                  const formattedDate = formatDateToNewsFormat({
                    dateString: published_at,
                    locale: i18n.language,
                  });

                  return (
                    <SwiperSlide key={id}>
                      <NewsCard
                        link={`${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Insights}/${slug || id}`}
                        avatarUrl={avatar}
                        imageUrl={image_url}
                        background={background}
                        title={title}
                        description={post_introduction}
                        infoSubtitle={`${formattedDate} • ${name}`}
                        avatarAlt={name}
                      />
                    </SwiperSlide>
                  );
                })}
          </Swiper>
        </SliderWrapper>

        <BtnWrap>{buttonText && <Button text={buttonText} link={buttonUrl} />}</BtnWrap>
      </StyledContainer>
    </Wrapper>
  );
};

// withWidth added here to recalculate swiper width. swiper has incorrect width after ssr
export default withWidth()(NewsBlock);

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'background',
})`
  padding-top: 36px;
  padding-bottom: 36px;
  background: ${({ theme }) => theme.palette.secondary.dark};

  &:first-child {
    margin-top: 76px;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-top: 72px;
    padding-bottom: 72px;
    background: ${({ background, theme }) =>
      background === 'Dark' ? theme.palette.secondary.dark : theme.palette.secondary.light};

    &:first-child {
      margin-top: 120px;
    }
  }
`;
const StyledContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'background',
})`
  padding-right: 0;

  & .swiper::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -1px;
    width: 50px;
    height: 314px;
    background: ${({ theme }) => theme.palette.common.transparent}
      linear-gradient(
        -90deg,
        ${({ theme }) => theme.palette.secondary.dark} 0%,
        ${({ theme }) => theme.palette.common.transparent} 100%
      )
      0% 0% no-repeat padding-box;
    z-index: 3;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-right: 16px;

    & .swiper::after {
      opacity: 0;
      height: 369px;
      background: ${({ theme }) => theme.palette.common.transparent}
        linear-gradient(
          -90deg,
          ${({ background, theme }) =>
              background === 'Dark' ? theme.palette.secondary.dark : theme.palette.secondary.light}
            0%,
          ${({ theme }) => theme.palette.common.transparent} 100%
        )
        0% 0% no-repeat padding-box;
    }
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-right: 24px;
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    padding-right: 16px;
  }
`;
const Heading = styled(Typography)`
  && {
    margin-bottom: 22px;

    ${({ theme }) => theme.breakpoints.up('sm')} {
      margin-bottom: ${MIDDLE_MARGIN_PX};
    }
  }
`;
const SliderWrapper = styled(Box)`
  margin-bottom: 40px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-top: 0;
    margin-bottom: ${MIDDLE_MARGIN_PX};
  }

  & .swiper {
    overflow: visible;
  }
`;
const BtnWrap = styled('div')`
  display: flex;
  justify-content: center;
`;
