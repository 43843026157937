import { useEffect, useState } from 'react';

const useOverflowHidden = (isHidden: boolean) => {
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (isHidden) {
      setTouched(true);
      document.body.style.overflow = 'hidden';
    }
  }, [isHidden]);

  useEffect(() => {
    if (!touched) {
      return;
    }

    if (!isHidden) {
      document.body.removeAttribute('style');
      setTouched(false);
    }
  }, [isHidden, touched]);
};

export default useOverflowHidden;
