import React, { FC, useContext } from 'react';

import { Collapse, Fade, TextField, Typography } from '@mui/material';
import { alpha, css, styled } from '@mui/material/styles';

import CloseIcon from '~/assets/icons/CloseIcon';
import SparklingStar from '~/blocks/Reviews/SparklingStar';
import useReview from '~/blocks/Reviews/useReview';
import LazyLoadImage from '~/components/LazyloadImage/LazyLoadImage';
import CustomButton from '~/components/UI/CustomButton/CustomButton';
import { H3 } from '~/components/UI/Texts';
import { LanguageCtx } from '~/context/LanguageProvider';
import useIsSsku from '~/hooks/tenant/useIsSsku';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import useTick from '~/hooks/useTick';
import useValidation from '~/hooks/useValidation';
import { PATHWAY_PAGE_EVENTS } from '~/pages/PathwayPage/const';
import { MIDDLE_MARGIN_PX, MIDDLE_RADIUS_PX, SMALL_MARGIN_PX, SUBMIDDLE_MARGIN_PX } from '~/theme';
import { Maybe, Pathway } from '~/types';
import UserCom, { UserComEvents } from '~/utils/UserCom';
import sendAnalyticEvent from '~/utils/sendAnalyticEvent';
import { sleep } from '~/utils/sleep';

const stars = new Array(5).fill(null);

interface ReviewFormProps {
  review: ReturnType<typeof useReview>;
  pathwayId: Pathway['id'];
  imageUrl?: string | null;
  className?: string;
  onClose?: () => void;
  onSubmitMessage?: () => void;
  subtitle?: Maybe<string>;
}

const DURATION_OF_COLLAPSE_ANIM = 600;
export const REVIEW_FORM_ID = 'review-form';
export const REVIEWS_SECTION_ID = 'review-section';

const ReviewForm: FC<ReviewFormProps> = ({
  review,
  subtitle,
  imageUrl,
  className,
  onClose,
  onSubmitMessage,
  pathwayId,
}) => {
  const { t } = useTenantTranslation();
  const { values, errors, handleSubmit, setFieldManually, handleChange } = useValidation(
    { message: '', stars: 0 },
    undefined,
    onSubmit,
  );
  const { tickTo, tickValue } = useTick(50, values.stars);
  const { language } = useContext(LanguageCtx);
  const isSsku = useIsSsku();

  const sendSubmitEvent = () =>
    UserCom.sendEvent(UserComEvents.pathwayReviewSubmitted, {
      lang: language,
      pathwayId,
    });

  async function onSubmit(values) {
    await review.submitReview(values);
    onSubmitMessage?.();
    sendAnalyticEvent(PATHWAY_PAGE_EVENTS.createReviewStarEvent(values.stars));
    // wait till animation of closing form finishes
    await sleep(DURATION_OF_COLLAPSE_ANIM + 100);
    review.refetchStatus();
    if (isSsku) {
      sendSubmitEvent();
    }
  }

  return (
    <Collapse id={REVIEW_FORM_ID} in={!review.messageSubmitted} timeout={DURATION_OF_COLLAPSE_ANIM}>
      <Fade in={!review.messageSubmitted} timeout={DURATION_OF_COLLAPSE_ANIM / 2}>
        <ContentWrapper className={className}>
          <Content>
            {onClose && <StyledCloseIcon onClick={onClose} />}
            <Title data-testid="reviewFormTitle">{t('page.pathway.letUsKnow')}</Title>
            <PathwayName data-testid="reviewFormSubtitle">{subtitle}</PathwayName>
            <Rating>
              <Stars onMouseLeave={() => tickTo(values.stars)}>
                {stars.map((s, index) => {
                  const starValue = index + 1;
                  const hovered = tickValue > 0;
                  const filled = hovered ? starValue <= tickValue : starValue <= values.stars;
                  const sameStar = starValue === values.stars;
                  const ableToSelectStar = !review.submitLoading && !sameStar;
                  const handleSelectStar = () => {
                    setFieldManually('stars', starValue);
                    if (!isSsku) {
                      review.submitReview({ stars: starValue });
                      sendSubmitEvent();
                      sendAnalyticEvent(PATHWAY_PAGE_EVENTS.createReviewStarEvent(starValue));
                    }
                  };

                  return (
                    <SparklingStar
                      key={index}
                      sparkling
                      loading={review.submitLoading}
                      data-testid={filled ? 'reviewStarFilledIcon' : 'reviewStarOutlinedIcon'}
                      filled={filled}
                      onClick={ableToSelectStar ? handleSelectStar : undefined}
                      onMouseOver={() => tickTo(starValue)}
                    />
                  );
                })}
              </Stars>
              <StarLabels>
                <StarLabel>{t('page.pathway.dissatisfied')}</StarLabel>
                <StarLabel>{t('page.pathway.highlySatisfied')}</StarLabel>
              </StarLabels>
            </Rating>
            <StyledCollapse in={review.messageFormAvailable || (isSsku && !!values.stars)}>
              <MessageForm>
                {!isSsku && (
                  <>
                    <MessageTitle>{t('page.pathway.pleaseLeaveAComment')}</MessageTitle>
                    <StyledField
                      id="message"
                      inputProps={{
                        'data-testid': 'reviewFormMessage',
                        'aria-invalid': undefined,
                        'aria-label': t('page.pathway.yourComment'),
                      }}
                      name="message"
                      variant="outlined"
                      label={t('page.pathway.yourComment')}
                      multiline
                      rows={3}
                      value={values.message}
                      error={!!errors.message}
                      helperText={errors.message}
                      onChange={handleChange}
                    />
                  </>
                )}
                <StyledCustomButton
                  data-testid="submitReview"
                  onClick={() => {
                    handleSubmit();
                    sendAnalyticEvent(PATHWAY_PAGE_EVENTS.reviewComment);
                  }}
                >
                  {t('page.pathway.leaveComment')}
                </StyledCustomButton>
              </MessageForm>
            </StyledCollapse>
          </Content>
          <BackgroundImageWrapper faded={Boolean(imageUrl)}>
            {imageUrl && (
              <StyledLazyLoadImage wrapperCss={lazyImageWrapperCss} path={imageUrl} sizes="100vw" />
            )}
          </BackgroundImageWrapper>
        </ContentWrapper>
      </Fade>
    </Collapse>
  );
};

export default ReviewForm;

const ContentWrapper = styled('div')`
  position: relative;
  border-radius: ${MIDDLE_RADIUS_PX};
  overflow: hidden;
  background: ${({ theme }) => theme.palette.common.blockBackground.light};
`;
const Content = styled('div')`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${MIDDLE_MARGIN_PX} ${SUBMIDDLE_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${MIDDLE_MARGIN_PX};
  }
`;
const Stars = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const StarLabel = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.common.text.label};
`;
const StarLabels = styled('div')`
  display: flex;
  justify-content: space-between;
`;
const Rating = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 392px;
  width: 100%;
  margin: 0 auto;
`;
const Title = styled(Typography)`
  font-size: 16px;
  text-align: center;
  color: ${({ theme }) => theme.palette.common.text.secondaryToPrimary};

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 18px;
  }
`;
const PathwayName = styled(H3)`
  margin-bottom: 18px;
`;
const MessageTitle = styled(H3)`
  margin-bottom: ${MIDDLE_MARGIN_PX};
  text-align: center;
`;
const StyledField = styled(TextField)`
  width: 100%;
  max-width: 780px;
  margin: 0 auto ${SMALL_MARGIN_PX};
  background: ${({ theme }) => alpha(theme.palette.common.answerItem, 0.3)};
  backdrop-filter: blur(4px);

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: ${MIDDLE_MARGIN_PX};
  }
`;
const StyledCustomButton = styled(CustomButton)`
  width: 100%;
  margin: 0 auto;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: initial;
  }
`;
const MessageForm = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: ${SMALL_MARGIN_PX};
`;
const BackgroundImageWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'faded',
})<{ faded: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;

  &:after {
    content: '';
    display: ${({ faded }) => !faded && 'none'};
    position: absolute;
    top: -1px;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => alpha(theme.palette.common.blueDark, 0.7)};
  }

  &:before {
    content: '';
    display: ${({ faded }) => !faded && 'none'};
    position: absolute;
    bottom: -2px;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      ${({ theme }) => theme.palette.common.blueDark} 0%,
      ${({ theme }) => alpha(theme.palette.common.blueDark, 0)} 100%
    );
  }
`;
const StyledLazyLoadImage = styled(LazyLoadImage)`
  position: relative;
  top: -1px;
  width: 100%;
  height: 100%;
  min-height: 550px;
  object-fit: cover;
`;
const lazyImageWrapperCss = css`
  height: 100%;
  width: 100%;
`;
const StyledCollapse = styled(Collapse)`
  width: 100%;
`;
const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 0;
  right: 0;
  width: 16px;
  height: 16px;
  padding: ${SUBMIDDLE_MARGIN_PX};
  box-sizing: content-box;
  cursor: pointer;
`;
