import { useContext } from 'react';

import { TenantCtx } from '~/context/TenantProvider';
import { TenantsRefs } from '~/utils/tenantsConfig';

const useIsSsku = () => {
  const { tenant, fallbackTenantName } = useContext(TenantCtx);
  const tenantName = tenant?.name || fallbackTenantName;
  return tenantName === TenantsRefs.Ssku;
};

export default useIsSsku;
