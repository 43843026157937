import React from 'react';

import { useQuery } from '@apollo/client';
import { Box, Card, Container, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import cx from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper/types/swiper-options';

import AnimatedSkeleton from '~/components/AnimatedSkeleton';
import AnimatedSkeletonMultiline from '~/components/AnimatedSkeletonMultiline';
import IKImage from '~/components/IKImage/IKImage';
import { H2 } from '~/components/UI/Texts';
import { GET_LIST_PARTNERS_BY_FILTER } from '~/graphql/partners';
import useIsSsku from '~/hooks/tenant/useIsSsku';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import styled, { css } from '~/styled';
import {
  BREAKPOINTS_VALUES,
  LARGE_MARGIN_PX,
  MIDDLE_MARGIN_PX,
  SKELETON_TEXT_HEIGHT_SMALL_PX,
  TINY_MARGIN_PX,
  useBreakpoint,
  useIsWidthUp,
} from '~/theme';
import { Query, QueryListPartnersArgs } from '~/types';
import { checkIsSSR } from '~/utils/checkIsSSR';

const itemsOnBreakpointMap: Omit<typeof BREAKPOINTS_VALUES, 'xs' | 'sm'> = {
  md: 4,
  lg: 5,
  xl: 6,
  xxl: 6,
  ml: 6,
};

const FALLBACK_ITEMS_AMOUNT = 6;

const getSwiperSettings = (isSlidable): SwiperOptions => ({
  slidesPerView: 2.5,
  spaceBetween: 16,
  observer: true,
  noSwiping: !isSlidable,
  allowTouchMove: isSlidable,
  centerInsufficientSlides: true,
  ...(isSlidable && {
    pagination: {
      clickable: true,
    },
    navigation: true,
  }),
  breakpoints: {
    [BREAKPOINTS_VALUES.xl]: {
      slidesPerView: 6,
    },
    [BREAKPOINTS_VALUES.lg]: {
      slidesPerView: 5,
    },
    [BREAKPOINTS_VALUES.md]: {
      slidesPerView: 4,
      ...(isSlidable && {
        mousewheel: {
          releaseOnEdges: true,
          forceToAxis: true,
          invert: true,
        },
      }),
    },
  },
});

const BlockByType = ({
  loading,
  partners,
  displayType,
  background,
  heading,
  description,
  whiteBg,
  isMobile,
}) => {
  const { t } = useTenantTranslation();

  const breakpoint = useBreakpoint();

  const itemsOnBreakpoint = itemsOnBreakpointMap[breakpoint] || FALLBACK_ITEMS_AMOUNT;
  const isSlidable = !isMobile && partners?.length > itemsOnBreakpoint;
  const swiperSettings = getSwiperSettings(isSlidable);

  switch (displayType) {
    case 'Block':
      return (
        <CardsWrapper className={'partners-type-block'}>
          <HeadingBlock variant="h2" component="h2" align="center">
            {heading}
          </HeadingBlock>

          {loading ? (
            <LoadingRow>
              <LoadingCard xs="125px" sm="95px" />
              <LoadingCard xs="125px" sm="95px" />
              <LoadingCard xs="125px" sm="95px" />
              <LoadingCard xs="125px" sm="95px" />
            </LoadingRow>
          ) : (
            partners?.map((item) => {
              return (
                <StyledCard key={item.id} className="card">
                  <IKImage
                    path={item.image_url}
                    alt={item.title}
                    draggable={false}
                    srcset={[{ width: 170 }, { width: 270 }, { width: 140 }, { width: 180 }]}
                    sizes={`(max-width:${BREAKPOINTS_VALUES['sm']}px) 170px, (max-width:${BREAKPOINTS_VALUES['md']}px) 270px, (max-width:${BREAKPOINTS_VALUES['lg']}px) 140px, 180px`}
                  />
                </StyledCard>
              );
            })
          )}
        </CardsWrapper>
      );
    case 'Slider':
      return isMobile ? (
        <CardsWrapper className="partners-type-block">
          <HeadingBlock variant="h2" component="h2" align="center">
            {heading}
          </HeadingBlock>

          {loading ? (
            <LoadingRow>
              <LoadingCard xs="95px" />
              <LoadingCard xs="95px" />
              <LoadingCard xs="95px" />
            </LoadingRow>
          ) : (
            partners?.map((item) => {
              return (
                <StyledCard key={item.id} className="card">
                  <IKImage
                    path={item.image_url}
                    alt={item.title}
                    draggable={false}
                    srcset={[{ width: 170 }, { width: 270 }, { width: 140 }, { width: 180 }]}
                    sizes={`(max-width:${BREAKPOINTS_VALUES['sm']}px) 170px, (max-width:${BREAKPOINTS_VALUES['md']}px) 270px, (max-width:${BREAKPOINTS_VALUES['lg']}px) 140px, 180px`}
                  />
                </StyledCard>
              );
            })
          )}
        </CardsWrapper>
      ) : loading ? (
        <LoadingRow>
          <LoadingSlide xs="105px" />
          <LoadingSlide xs="105px" />
          <LoadingSlide xs="105px" />
          <LoadingSlide xs="105px" />
        </LoadingRow>
      ) : (
        <SwiperWrapper
          className="partners-type-slider"
          optionalStyles={isSlidable && swiperWrap(background)}
        >
          {isSlidable !== undefined && (
            <Swiper {...swiperSettings}>
              {partners?.map((item) => {
                return (
                  <SwiperSlide key={item.id}>
                    <SlideCard
                      className={cx('card', 'cardSlider')}
                      optionalStyles={whiteBg && whiteBgCss}
                    >
                      <IKImage
                        path={item.image_url}
                        alt={item.title}
                        draggable={false}
                        srcset={[{ width: 200 }, { width: 270 }, { width: 320 }, { width: 220 }]}
                        sizes={`(max-width:${BREAKPOINTS_VALUES['sm']}px) 200px, (max-width:${BREAKPOINTS_VALUES['md']}px) 270px, (max-width:${BREAKPOINTS_VALUES['lg']}px) 320px, 220px`}
                      />
                    </SlideCard>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </SwiperWrapper>
      );
    case 'WithDescription':
      const loadingCard = (
        <CardDesc>
          <DescImgWrap optionalStyles={whiteBg && whiteBgCss}>
            <AnimatedSkeleton xs="80px" />
          </DescImgWrap>
          <LoadingText xs={SKELETON_TEXT_HEIGHT_SMALL_PX} lines={4} spacer={TINY_MARGIN_PX} />
        </CardDesc>
      );
      return (
        <PartnersDescWrapper className={'partners-type-description'}>
          {heading && (
            <HeadingDesc variant="h2" component="h2" align="center">
              {heading}
            </HeadingDesc>
          )}

          {description && <PartnerDescBlockDesc>{description}</PartnerDescBlockDesc>}

          <CardsDescWrapper>
            {loading ? (
              <>
                {loadingCard}
                {loadingCard}
                {loadingCard}
                {loadingCard}
              </>
            ) : (
              partners?.map((item) => {
                return (
                  <CardDesc key={item?.id}>
                    <DescImgWrap optionalStyles={whiteBg && whiteBgCss}>
                      <IKImage
                        path={item.image_url}
                        alt={item.title}
                        draggable={false}
                        srcset={[{ width: 500 }, { width: 600 }, { width: 850 }, { width: 900 }]}
                        sizes={`(max-width:${BREAKPOINTS_VALUES['sm']}px) 500px, (max-width:${BREAKPOINTS_VALUES['md']}px) 600px, (max-width:${BREAKPOINTS_VALUES['lg']}px) 850px, 900px`}
                      />
                    </DescImgWrap>
                    <PartnerDesc>{item?.description}</PartnerDesc>
                  </CardDesc>
                );
              })
            )}
          </CardsDescWrapper>
        </PartnersDescWrapper>
      );
    default:
      return <div>{t('block.partners.empty')}</div>;
  }
};

export const PartnersBlock = ({
  data: partnersData,
  loading: loadingPartnersData,
  preventLoader,
}) => {
  const background = partnersData?.background;
  const displayType = partnersData?.display;
  const whiteBg = partnersData?.white_icon;
  const isMobile = !useIsWidthUp('md');
  const isSSR = checkIsSSR();
  const isSsku = useIsSsku();

  const partnerTypeId = partnersData?.partnerType?.id;
  const partnersList = partnersData?.partnersList;

  const { data, loading: loadingData } = useQuery<
    Pick<Query, 'listPartners'>,
    QueryListPartnersArgs
  >(GET_LIST_PARTNERS_BY_FILTER, {
    variables: {
      filter: {
        type_id: partnerTypeId,
      },
    },
    skip: !partnerTypeId || partnersList || isSSR,
  });

  const loading = loadingData || isSSR || loadingPartnersData;

  const heading =
    typeof partnersData?.heading === 'string' ? partnersData?.heading : partnersData?.heading?.en;
  const description = partnersData?.description;
  const partners = partnersList || data?.listPartners;

  if (loading) {
    if (preventLoader) {
      return null;
    }

    return (
      <Wrapper
        className={cx(background === 'Dark' ? 'dark-block' : 'light-block', 'partners-block')}
        displayType={displayType}
        background={background}
      >
        <StyledContainer displayType={displayType} background={background}>
          {heading && (
            <Heading variant="h2" component="h2" align="center" displayType={displayType}>
              {heading}
            </Heading>
          )}
          <BlockByType
            partners={partners}
            displayType={displayType}
            background={background}
            heading={heading}
            description={description}
            whiteBg={whiteBg}
            isMobile={isMobile}
            loading={loading}
          />
        </StyledContainer>
      </Wrapper>
    );
  }

  return (
    <Wrapper
      className={cx(background === 'Dark' ? 'dark-block' : 'light-block', 'partners-block')}
      displayType={displayType}
      background={background}
    >
      <StyledContainer displayType={displayType} background={background}>
        {heading && (
          <Heading
            special={isSsku}
            variant="h2"
            component="h2"
            align="center"
            displayType={displayType}
          >
            {heading}
          </Heading>
        )}
        <BlockByType
          partners={partners}
          displayType={displayType}
          background={background}
          heading={heading}
          description={description}
          whiteBg={whiteBg}
          isMobile={isMobile}
          loading={loading}
        />
      </StyledContainer>
    </Wrapper>
  );
};

export default PartnersBlock;

const LoadingRow = styled('div')`
  display: flex;
  justify-content: space-around;
  flex-grow: 1;
  flex-wrap: wrap;

  ${({ theme }) => theme.breakpoints.up('md')} {
    justify-content: space-between;
  }
`;
const LoadingCard = styled(AnimatedSkeleton)`
  width: calc(100% / 2 - ${MIDDLE_MARGIN_PX});
  margin-bottom: ${MIDDLE_MARGIN_PX};
  margin-right: ${MIDDLE_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: calc(100% / 3 - ${MIDDLE_MARGIN_PX});
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: unset;
    flex-grow: 1;
    margin-bottom: 0;
  }
`;
const LoadingSlide = styled(AnimatedSkeleton)`
  width: calc(100% / 2 - ${MIDDLE_MARGIN_PX});
  margin-bottom: ${MIDDLE_MARGIN_PX};
  margin-right: ${MIDDLE_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: calc(100% / 3 - ${MIDDLE_MARGIN_PX});
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: unset;
    flex-grow: 1;
    margin-bottom: 0;
  }
`;
const LoadingText = styled(AnimatedSkeletonMultiline)`
  flex-grow: 1;
  padding: 0 22px 22px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-top: 35px;
    padding-bottom: 40px;
    padding-left: 25px;
  }
`;

const CardsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;

  & .card {
    width: calc(50% - ${MIDDLE_MARGIN_PX});
    max-width: unset;
    box-shadow: none;
    background: ${({ theme }) => theme.palette.common.transparent};
    margin-bottom: ${MIDDLE_MARGIN_PX};
    margin-right: ${MIDDLE_MARGIN_PX};
    height: unset;

    & img {
      max-height: 95px;
    }
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    justify-content: center;

    & .card {
      width: calc(100% / 3 - ${MIDDLE_MARGIN_PX});
    }
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-right: 0;

    &,
    & > h2 {
      margin-right: 20px;
    }

    & div:last-child {
      margin-right: 0;
    }

    & .card {
      margin-bottom: 0;
      margin-right: 20px;
      width: unset;
      max-width: 140px;
    }
  }
`;
const HeadingBlock = styled(Typography)`
  && {
    display: none;

    ${({ theme }) => theme.breakpoints.up('md')} {
      display: block;
      flex-shrink: 0;
    }
  }
`;
const StyledCard = styled(Card)`
  width: 135px;
  height: 74px;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 13px;
  margin-bottom: 13px;
  box-shadow: 0px 3px 6px ${({ theme }) => alpha(theme.palette.common.blackPure, 0.4)};
  background: ${({ theme }) => theme.palette.common.blueDark};
  box-sizing: border-box;

  & img {
    filter: invert(${({ theme }) => (theme.palette.mode === 'light' ? 1 : 0)});
    max-width: 100%;
    max-height: 28px;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-shrink: 1;
    width: auto;
    max-width: 140px;
    height: auto;
    padding: 0;
    box-shadow: none;
    background: ${({ theme }) => theme.palette.common.transparent};
    margin-bottom: 0;
    margin-right: 0;

    & img {
      height: auto;
      object-fit: contain;
      max-height: 98px;
      width: 100%;
    }
  }
`;
const swiperWrap =
  (background) =>
  ({ theme }) =>
    css`
      & .swiper {
        padding-left: 60px;
        padding-right: 60px;
      }

      & .swiper-button-next,
      & .swiper-button-prev {
        background: ${background === 'Dark'
          ? theme.palette.common.blockBackground.main
          : theme.palette.common.blockBackground.light};
        opacity: 1;
        top: 31%;
      }

      & .swiper-button-next::after,
      & .swiper-button-prev::after {
        opacity: 0.33;
      }

      & .swiper-button-next:hover,
      & .swiper-button-prev:hover {
        &::after {
          opacity: 1;
        }
      }

      ${theme.breakpoints.up('sm')} {
        & .cardSlider {
          max-width: 100%;
        }
      }
    `;
const SlideCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})`
  width: 135px;
  height: 74px;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 13px;
  margin-bottom: 13px;
  box-shadow: 0px 3px 6px ${({ theme }) => alpha(theme.palette.common.blackPure, 0.4)};
  background: ${({ theme }) => theme.palette.common.blueDark};
  box-sizing: border-box;

  & img {
    filter: invert(${({ theme }) => (theme.palette.mode === 'light' ? 1 : 0)});
    max-width: 100%;
    max-height: 28px;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-shrink: 1;
    width: auto;
    max-width: 140px;
    height: auto;
    padding: 0;
    box-shadow: none;
    background: ${({ theme }) => theme.palette.common.transparent};
    margin-bottom: 0;
    margin-right: 0;

    & img {
      height: auto;
      object-fit: contain;
      max-height: 98px;
      width: 100%;
    }
  }

  max-height: none;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 194px;
    max-width: none;
    height: 106px;
    justify-content: center;
    padding: 15px;
    max-height: none;
  }

  ${({ optionalStyles }) => optionalStyles}
`;
const whiteBgCss = ({ theme }) => css`
  background: ${theme.palette.common.white};
`;
const CardDesc = styled(Card)`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.common.blockBackground.light};
  box-shadow: 0px 3px 6px ${({ theme }) => alpha(theme.palette.common.blackPure, 0.4)};
  border-radius: 5px;
  margin-bottom: 18px;
  padding: 0;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
    margin-bottom: 23px;
    padding: 0 40px 0 0;
  }
`;
const DescImgWrap = styled('div', {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  padding: 22px;

  & img {
    height: 100px;
    max-width: 100%;
    object-fit: contain;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-shrink: 0;
    width: 28%;
    margin-bottom: 0;
    padding: 35px 35px 40px 35px;

    & img {
      width: 100%;
    }
  }

  ${({ optionalStyles }) => optionalStyles}
`;
const PartnersDescWrapper = styled(Box)`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
  }
`;
const HeadingDesc = styled(Typography)`
  && {
    ${({ theme }) => theme.breakpoints.up('sm')} {
      display: none;
    }
  }
`;
const PartnerDescBlockDesc = styled(Typography)`
  margin-top: 20px;
  font-size: 22px;
  line-height: 28px;
  color: ${({ theme }) => theme.palette.common.gray};
`;
const CardsDescWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 80%;
    margin: auto;
  }
`;
const PartnerDesc = styled(Typography)`
  font-size: 18px;
  line-height: 23px;
  color: ${({ theme }) => theme.palette.common.gray};
  text-align: center;
  padding: 0 22px 22px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    text-align: left;
    padding-top: 35px;
    padding-bottom: 40px;
    padding-left: 25px;
  }
`;
const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'displayType' && prop !== 'background',
})`
  padding-top: ${MIDDLE_MARGIN_PX};
  padding-bottom: ${({ displayType }) =>
    displayType !== 'WithDescription' ? '0px' : MIDDLE_MARGIN_PX};
  background: ${({ theme }) => theme.palette.common.blockBackground.main};

  &:first-child {
    margin-top: 76px;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-top: 72px;
    padding-bottom: ${({ displayType }) =>
      displayType !== 'WithDescription' ? MIDDLE_MARGIN_PX : LARGE_MARGIN_PX};
    background: ${({ background, theme }) =>
      background === 'Dark'
        ? theme.palette.common.blockBackground.main
        : theme.palette.common.blockBackground.light};

    &:first-child {
      margin-top: 120px;
    }
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-bottom: ${LARGE_MARGIN_PX};
  }
`;
const StyledContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'displayType' && prop !== 'background',
})`
  & .swiper::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -1px;
    width: 50px;
    height: 74px;
    background: ${({ theme }) => theme.palette.common.transparent}
      linear-gradient(
        -90deg,
        ${({ theme }) => theme.palette.common.blockBackground.main} 0%,
        ${({ theme }) => alpha(theme.palette.common.blockBackground.main, 0)} 100%
      )
      0% 0% no-repeat padding-box;
    z-index: 3;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    & .swiper::after {
      opacity: 0;
      background: ${({ theme }) => theme.palette.common.transparent}
        linear-gradient(
          -90deg,
          ${({ theme, background }) =>
              background === 'Dark'
                ? theme.palette.common.blockBackground.main
                : theme.palette.common.blockBackground.light}
            0%,
          ${({ theme, background }) =>
              background === 'Dark'
                ? alpha(theme.palette.common.blockBackground.main, 0)
                : alpha(theme.palette.common.blockBackground.light, 0)}
            100%
        )
        0% 0% no-repeat padding-box;
    }
  }
`;
const Heading = styled(H2, {
  shouldForwardProp: (prop) => prop !== 'displayType',
})`
  && {
    margin-bottom: ${MIDDLE_MARGIN_PX};
    display: ${({ displayType }) => (displayType === 'WithDescription' ? 'none' : 'block')};

    ${({ theme }) => theme.breakpoints.up('sm')} {
      margin-bottom: ${MIDDLE_MARGIN_PX};
      display: block;
    }

    ${({ theme }) => theme.breakpoints.up('md')} {
      display: ${({ displayType }) => (displayType === 'Block' ? 'none' : 'block')};
    }
  }
`;
const SwiperWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})`
  ${({ optionalStyles }) => optionalStyles}
`;
