import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import cx from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper/types/swiper-options';

import Pathway from '~/components/Pathway/Pathway';
import {
  CategoryType,
  secondsToPathwaysFormat,
  splitPathwayItemsByCategories,
} from '~/components/PathwaysListBlock/helpers';
import Select from '~/components/Select/Select';
import styled, { css } from '~/styled';
import {
  BREAKPOINTS_VALUES,
  LARGE_MARGIN_PX,
  MIDDLE_MARGIN_PX,
  SMALL_MARGIN_PX,
  useIsWidthUp,
} from '~/theme';
import { Background, Pathway as PathwayType, PathwayItem, PathwayListBlock } from '~/types';
import { splitIntoChunks } from '~/utils/arrayUtils/splitIntoChunks';

const CHUNK_LENGTH = 2;

const swiperSettings: SwiperOptions = {
  slidesPerView: 4.5,
  spaceBetween: 16,
  pagination: {
    clickable: true,
  },
  navigation: true,
  mousewheel: {
    releaseOnEdges: true,
    forceToAxis: true,
    invert: true,
  },
  slidesOffsetAfter: 16,
  breakpoints: {
    [BREAKPOINTS_VALUES.xl]: {
      slidesPerView: 4.5,
    },
    [BREAKPOINTS_VALUES.lg]: {
      slidesPerView: 4.5,
    },
    [BREAKPOINTS_VALUES.md]: {
      slidesPerView: 3.2,
    },
    [BREAKPOINTS_VALUES.sm]: {
      slidesPerView: 2.2,
    },
    [BREAKPOINTS_VALUES.xs]: {
      slidesPerView: 1,
      spaceBetween: 36,
    },
  },
  observer: true,
};

interface PathwaysListProps {
  data?: PathwayListBlock;
}

const PathwaysListBlock: FC<PathwaysListProps> = ({ data }) => {
  const background = data?.background;
  const heading = data?.heading;
  const withCategories = data?.with_categories;

  const [hasMaxWidth, setHasMaxWidth] = useState(true);
  const [categoriesArray, setCategoriesArray] = useState<CategoryType[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<CategoryType | null>(null);
  const [formattedData, setFormattedData] = useState<Map<string | undefined, PathwayItem[]> | null>(
    null,
  );

  const isMobile = !useIsWidthUp('md');
  const isSmallMobile = !useIsWidthUp('sm');
  const selectWrapRef = useRef<HTMLDivElement | null>(null);

  const pathways = useMemo(() => {
    return (data?.items?.filter((item) => item?.pathway) as PathwayItem[]) || [];
  }, [data]);
  const pathwaysArr = useMemo(() => {
    const arr = withCategories ? formattedData?.get(selectedCategory?.value) : pathways;
    return isSmallMobile ? splitIntoChunks(arr, CHUNK_LENGTH) : arr;
  }, [withCategories, formattedData, pathways, selectedCategory, isSmallMobile]);

  useEffect(() => {
    setTimeout(() => {
      setHasMaxWidth(false);
    }, 0);
  }, []);

  useEffect(() => {
    if (withCategories) {
      const { result, categoriesArray } = splitPathwayItemsByCategories(pathways);

      setCategoriesArray(categoriesArray);
      setFormattedData(result);
      if (categoriesArray.length > 0) {
        setSelectedCategory(categoriesArray[0]);
      }
    }
  }, [pathways, withCategories]);

  return (
    <StyledContainer
      className={cx(
        background === 'Dark' ? 'dark-block' : 'light-block',
        'pathways-container',
        'pathways-block',
      )}
      maxWidth={false}
      background={background}
    >
      <HeadingWrapper>
        <Heading variant="h2" component="h2" align="center">
          {heading}
        </Heading>

        {withCategories &&
          Boolean(categoriesArray?.length) &&
          (isMobile ? (
            <SelectorWrapper ref={selectWrapRef}>
              <Select
                value={selectedCategory}
                items={categoriesArray}
                onChange={(val) => setSelectedCategory(val)}
                anchorRef={selectWrapRef}
                selectBtnCss={selectBtnCss}
                selectHeaderCss={selectHeaderCss}
              />
            </SelectorWrapper>
          ) : (
            <CategoriesWrapper>
              {categoriesArray.map((category) => (
                <CategoryItem
                  data-testid="category"
                  key={category.value}
                  onClick={() => setSelectedCategory(category)}
                  active={category.value === selectedCategory?.value}
                >
                  {category.display}
                </CategoryItem>
              ))}
            </CategoriesWrapper>
          ))}
      </HeadingWrapper>
      {pathwaysArr && (
        <Swiper {...swiperSettings}>
          {!isSmallMobile
            ? pathwaysArr?.map((item, i) => {
                const { image_url, name, videos, duration, count_videos, slug } =
                  item?.pathway as PathwayType;
                const fullDuration = duration
                  ? duration
                  : videos?.map((v) => v?.duration || 0)?.reduce((acc, d) => acc + d, 0);
                const countVideos = count_videos ? count_videos : videos?.length;
                return (
                  <SwiperSlide key={i}>
                    <ItemWrapper hasMaxWidth={hasMaxWidth}>
                      <StyledPathway
                        videoWidth={0}
                        duration={secondsToPathwaysFormat(fullDuration)}
                        countVideos={countVideos}
                        imageUrl={image_url}
                        name={name}
                        withHover={false}
                        background={isSmallMobile ? Background.Dark : background}
                        slug={slug}
                      />
                    </ItemWrapper>
                  </SwiperSlide>
                );
              })
            : pathwaysArr?.map((chunk, i) => (
                <SwiperSlide key={i}>
                  <ChunkWrapper>
                    {chunk.map((item, i) => {
                      const { image_url, name, videos, duration, count_videos, slug } =
                        item?.pathway as PathwayType;
                      const fullDuration = duration
                        ? duration
                        : videos?.map((v) => v?.duration || 0)?.reduce((acc, d) => acc + d, 0);
                      const countVideos = count_videos ? count_videos : videos?.length;
                      return (
                        <ItemWrapper
                          key={i}
                          className="pathwaysListBlock-itemWrapper"
                          hasMaxWidth={hasMaxWidth}
                        >
                          <StyledPathway
                            videoWidth={0}
                            duration={secondsToPathwaysFormat(fullDuration)}
                            countVideos={countVideos}
                            imageUrl={image_url}
                            name={name}
                            withHover={false}
                            background={isSmallMobile ? Background.Dark : background}
                            slug={slug}
                          />
                        </ItemWrapper>
                      );
                    })}
                  </ChunkWrapper>
                </SwiperSlide>
              ))}
        </Swiper>
      )}
    </StyledContainer>
  );
};

export default PathwaysListBlock;

const StyledPathway = styled(Pathway)`
  margin-right: 24px;
`;
const StyledContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'background',
})`
  & {
    padding-right: 0;
  }
  padding-top: ${MIDDLE_MARGIN_PX};
  padding-bottom: ${MIDDLE_MARGIN_PX};
  background: ${({ theme }) => theme.palette.secondary.dark};
  &:first-child {
    margin-top: 76px;
  }
  & .swiper-button-next,
  & .swiper-button-prev {
    top: 33%;
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-top: ${LARGE_MARGIN_PX};
    padding-bottom: ${LARGE_MARGIN_PX};
    background: ${({ background, theme }) =>
      background === 'Dark' ? theme.palette.secondary.dark : theme.palette.secondary.light};

    & .swiper::after {
      background: transparent
        linear-gradient(
          -90deg,
          ${({ background, theme }) =>
              background === 'Dark' ? theme.palette.secondary.dark : theme.palette.secondary.light}
            0%,
          ${({ theme }) => alpha(theme.palette.common.blueDark, 0)} 100%
        )
        0% 0% no-repeat padding-box;
      height: 350px;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: -1px;
      width: 50px;
      z-index: 3;
    }
  }
`;
const HeadingWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const Heading = styled(Typography)`
  && {
    max-width: 250px;
    margin-bottom: 22px;
    ${({ theme }) => theme.breakpoints.up('sm')} {
      max-width: 506px;
      margin-bottom: ${MIDDLE_MARGIN_PX};
    }
  }
`;
const SelectorWrapper = styled(Select.Wrapper)`
  margin-bottom: ${MIDDLE_MARGIN_PX};
  width: 100%;

  > button {
    background: ${({ theme }) => theme.palette.common.violetAnalyticsCard};
  }
`;
const selectBtnCss = css`
  width: 100%;
`;
const selectHeaderCss = css`
  width: 100%;
  justify-content: space-between;
`;
const CategoriesWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${MIDDLE_MARGIN_PX};
  justify-content: center;
`;
const CategoryItem = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active',
})`
  font-size: 18px;
  line-height: 18px;
  color: ${({ active, theme }) =>
    active ? theme.palette.common.white : alpha(theme.palette.common.white, 0.56)};
  margin-right: ${SMALL_MARGIN_PX};
  margin-bottom: ${SMALL_MARGIN_PX};
  cursor: pointer;
  position: relative;
  &:hover {
    color: ${({ theme }) => theme.palette.common.white};
    &:after {
      width: 100%;
    }
  }
  &:after {
    content: '';
    height: 2px;
    background: ${({ theme }) => theme.palette.common.blue};
    width: ${({ active }) => (active ? '100%' : 0)};
    position: absolute;
    bottom: -9px;
    left: 0;
    transition: 0.5s ease all 0.3s;
  }
  &:last-child {
    margin-right: 0;
  }
`;
const ItemWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasMaxWidth',
})`
  overflow: hidden;
  max-width: ${({ hasMaxWidth }) => (hasMaxWidth ? '20%' : 'none')};
  margin-right: ${({ hasMaxWidth }) => (hasMaxWidth ? '2%' : '0px')};
  height: 240px;
  padding: 20px 0 0 0;
  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 316px;
  }
`;
const ChunkWrapper = styled('div')`
  & .pathwaysListBlock-itemWrapper {
    margin-right: ${SMALL_MARGIN_PX};
    &:first-child {
      margin-bottom: ${MIDDLE_MARGIN_PX};
    }
  }
`;
