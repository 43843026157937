import React, {
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { alpha } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';

import AnimatedSkeleton from '~/components/AnimatedSkeleton';
import { ThemeCtx } from '~/context/ThemeProvider/ThemeProvider';
import useIsSsku from '~/hooks/tenant/useIsSsku';
import useEventListener from '~/hooks/useEventListener';
import useVerse, { VerseEvents } from '~/hooks/useVerse';
import { VideoPlayerEvents } from '~/pages/SingleVideoPage/hooks/useVideoPlayer';
import styled, { css } from '~/styled';
import { useIsWidthUp } from '~/theme';
import { Video as VideoType, VideosShowingMode } from '~/types';
import UserCom from '~/utils/UserCom';

export const isIOSDevice = () => {
  //@ts-ignore
  return /iPad|iPhone|iPod/.test(window?.navigator?.userAgent) && !window?.MSStream;
};

interface VideoPlayerProps {
  mobOrientation?: VideoType['showing_mode'];
  id?: string;
  src?: string;
  className?: string;
  duration?: number;
  title?: string;
  expertName?: string;
  verseId?: string | null;
  videoUrl?: string | null;
  bumperVideoUrl?: string | null;
  onToggleFullscreen?: (isFullscreen: boolean) => void;
  isFullScreen?: boolean;
  additionalIframeUrlParams?: string;
  children?: ({ setShowBumper }: { setShowBumper: (isOpen: boolean) => void }) => ReactNode;
  waitForVideoLoading?: boolean;
}

const exitFullscreen = () => {
  if (document.fullscreenElement) {
    document.exitFullscreen();
  }
};

const VideoPlayer = React.forwardRef<HTMLFrameElement, VideoPlayerProps>(
  (
    {
      mobOrientation = VideosShowingMode.Landscape,
      verseId,
      className = '',
      duration = null,
      expertName,
      title,
      id,
      videoUrl,
      bumperVideoUrl,
      onToggleFullscreen,
      isFullScreen,
      additionalIframeUrlParams = '',
      children,
      waitForVideoLoading = false,
    },
    ref,
  ) => {
    const [isIOS, setIsIOS] = useState(false);
    const [isIosFullScreen, setIsIosFullScreen] = useState<boolean>(false);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const location = useLocation();
    const bumperRef = useRef<HTMLVideoElement>();
    const isSsku = useIsSsku();
    const isMobile = !useIsWidthUp('md');
    const [showBumper, setShowBumper] = useState(false);
    const { isDeviceInPortraitMode } = useContext(ThemeCtx);

    const iframeRef = useRef<HTMLFrameElement>(null);
    const { changeVideoDuration, sendCustomEvent } = useVerse(
      (ref as RefObject<HTMLFrameElement>) || iframeRef,
    );

    const url = verseId
      ? `${
          process.env.REACT_APP_VERSE_PLAYER_URL
        }/stories/${verseId}-xunlocked/?embed&controls=2&sharing=0&covertitle=0${additionalIframeUrlParams}${
          isSsku &&
          isMobile &&
          mobOrientation === VideosShowingMode.Portrait &&
          isDeviceInPortraitMode
            ? '&fitmode=1&orientation=portrait'
            : ''
        }`
      : videoUrl || '';

    useEventListener(
      'ended',
      () => {
        window.dispatchEvent(new CustomEvent(VideoPlayerEvents.bumperFinished));
      },
      bumperRef.current,
    );

    useEventListener('loadingTerm', () => {
      sendCustomEvent({
        type: 'loadingTerm',
      });
    });

    useEventListener('loadedTerm', (e) => {
      sendCustomEvent({
        type: 'loadedTerm',
        data: e?.detail || {},
      });
    });

    useEventListener('termError', () => {
      sendCustomEvent({
        type: 'termError',
      });
    });

    // to trigger after hydration
    useEffect(() => {
      setIsIOS(isIOSDevice());
    }, []);

    useEffect(() => {
      setShowBumper(false);
      if (bumperRef.current) {
        bumperRef.current.currentTime = 0;
      }
    }, [location, bumperRef]);

    //used to save fullscreen state on screen rotate that cause modal remount
    useEffect(() => {
      if (typeof isFullScreen === 'boolean') {
        setIsIosFullScreen(isFullScreen);
      }
    }, [isFullScreen]);

    const exitIosFullscreen = useCallback(() => {
      setIsIosFullScreen(false);
      onToggleFullscreen?.(false);
      UserCom.showWidget();
      //scroll to video while exiting from fullscreen mode
      if (isIosFullScreen) {
        scroller.scrollTo('playerWrap', {
          duration: 1000,
          smooth: 'easeInOutQuart',
          offset: -80,
        });
      }
    }, [isIosFullScreen, onToggleFullscreen]);

    const toggleIosFullScreen = useCallback(
      (directMode?: boolean) => {
        if (directMode === false || (directMode === undefined && isIosFullScreen)) {
          exitIosFullscreen();
        } else if ((directMode === undefined && !isIosFullScreen) || directMode === true) {
          setIsIosFullScreen(true);
          onToggleFullscreen?.(true);
          UserCom.hideWidget();
        }
      },
      [isIosFullScreen, exitIosFullscreen, onToggleFullscreen],
    );

    const handleVideoWatched = useCallback(() => {
      if (bumperVideoUrl) {
        setShowBumper(true);
      }

      if (isIosFullScreen && exitIosFullscreen) {
        exitIosFullscreen();
      } else {
        exitFullscreen();
      }
    }, [bumperVideoUrl, exitIosFullscreen, isIosFullScreen]);

    useEffect(() => {
      const mql = window.matchMedia('(orientation: portrait)');

      const handleChangeOrientationIosFullscreen = (e) => {
        if (isIOSDevice()) {
          const isRotated = !e.matches;
          toggleIosFullScreen(isRotated);
        }
      };

      if (typeof mql?.addEventListener === 'function') {
        mql?.addEventListener('change', handleChangeOrientationIosFullscreen);
      }

      return () => {
        if (typeof mql?.removeEventListener === 'function') {
          mql?.removeEventListener('change', handleChangeOrientationIosFullscreen);
        }
      };
    }, [toggleIosFullScreen]);

    useEventListener(VerseEvents.LastChapterFinished, handleVideoWatched);

    useEffect(() => {
      if (duration) {
        changeVideoDuration(duration, title, expertName);
      }
    }, [title, expertName, duration, changeVideoDuration]);

    return (
      <Wrapper optionalStyles={isIosFullScreen && generatedFullScreen}>
        {waitForVideoLoading && !isVideoLoaded && <LoadingOverlay />}
        {isIosFullScreen ? (
          <FullscreenExitIcon
            onClick={(e) => {
              e.stopPropagation();
              toggleIosFullScreen();
            }}
            isIOS={isIOS}
          />
        ) : (
          <FullScreenIcon
            onClick={(e) => {
              e.stopPropagation();
              toggleIosFullScreen();
            }}
            isIOS={isIOS}
          />
        )}
        {bumperVideoUrl && showBumper && (
          <Bumper data-testid="bumperWrapper">
            <Video ref={bumperRef} autoPlay playsInline>
              <source src={bumperVideoUrl} />
            </Video>
          </Bumper>
        )}
        {children?.({ setShowBumper })}
        <Iframe
          data-testid="videoPlayer"
          id={id}
          className={className}
          src={url}
          allow="autoplay"
          allowFullScreen
          // @ts-ignore
          ref={ref || iframeRef}
          onLoad={() => setIsVideoLoaded(true)}
        />
      </Wrapper>
    );
  },
);

export default VideoPlayer;

const LoadingOverlay = styled(AnimatedSkeleton)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})`
  position: relative;
  width: 100%;
  height: 100%;

  ${({ optionalStyles }) => optionalStyles}
`;
const generatedFullScreen = css`
  border-radius: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1200;
`;
const createFullScreenIcon = (tag) => styled(tag, {
  shouldForwardProp: (prop) => prop !== 'isIOS',
})`
  position: absolute;
  top: 10px;
  left: 10px;
  color: ${({ theme }) => alpha(theme.palette.common.white, 0.7)};
  z-index: 1300;
  display: ${({ isIOS }) => (!isIOS ? 'none' : 'unset')};

  &:hover {
    color: ${({ theme }) => theme.palette.common.white};
  }
`;
const FullscreenExitIcon = createFullScreenIcon(FullscreenExit);
const FullScreenIcon = createFullScreenIcon(Fullscreen);
const Bumper = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: ${({ theme }) => theme.palette.common.blueDark};
`;
const Video = styled('video')`
  width: 100%;
  height: 100%;
`;
const Iframe = styled('iframe')`
  border: none;
`;
