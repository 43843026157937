import React, { FC, ReactNode, RefObject, useRef } from 'react';

interface ModalContainerProps {
  labelledBy: string;
  children: (containerRef: RefObject<HTMLDivElement>) => ReactNode;
}

const ModalContainer: FC<ModalContainerProps> = ({ children, labelledBy }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    // section tag to fix accessibility issue - "This content element is not in a landmark element."
    <section aria-labelledby={labelledBy} ref={containerRef}>
      {children(containerRef)}
    </section>
  );
};

export default ModalContainer;
