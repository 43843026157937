import { Box, Container, Typography } from '@mui/material';

import { H1, H2 } from '~/components/UI/Texts';
import styled from '~/styled';
import { LARGE_MARGIN_PX, MIDDLE_MARGIN_PX, SMALL_MARGIN_PX } from '~/theme';

export const CenteredContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const SearchTitle = styled(H2)`
  && {
    color: ${({ theme }) => theme.palette.common.white};
    font-weight: bold;
    margin-bottom: 0;
    text-align: left;
  }
`;
export const SearchDescription = styled(Typography)`
  font-size: 18px;
  color: ${({ theme }) => theme.palette.common.text.secondaryToPrimary};
  line-height: 1.5;
  margin-top: ${SMALL_MARGIN_PX};
  white-space: break-spaces;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 20px;
    width: 850px;
    max-width: 100%;
    margin-top: ${MIDDLE_MARGIN_PX};
  }
`;
export const WrapperWithBorder = styled(Box)`
  padding-bottom: ${MIDDLE_MARGIN_PX};
  margin-bottom: ${MIDDLE_MARGIN_PX};
  position: relative;

  &:after {
    position: absolute;
    display: block;
    content: '';
    height: 3px;
    width: 100%;
    background-color: ${({ theme }) => theme.palette.common.divider.discover};
    opacity: 0.3;
    left: 0;
    bottom: 0;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: ${LARGE_MARGIN_PX};
  }
`;
export const HeroTitle = styled(H1, {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})`
  && {
    text-align: center;
    margin-bottom: ${MIDDLE_MARGIN_PX};
    ${({ optionalStyles }) => optionalStyles}
  }
`;
