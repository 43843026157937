import React, { FC, useEffect } from 'react';

import { alpha } from '@mui/material/styles';

import AddToList from '~/assets/icons/AddToList';
import RemoveFromList from '~/assets/icons/RemoveFromList';
import ToggleWatchlistPopper from '~/components/ToggleFromList/ToggleWatchlistPopper';
import { LightTooltip } from '~/components/UI/LightTooltip/LightTooltip';
import { useAlert } from '~/context/AlertProvider';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import useToggleFromWatchlist from '~/hooks/useToggleFromWatchlist';
import styled from '~/styled';

interface IToggleFromList {
  existInWatchlist?: boolean;
  noBackground?: boolean;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  id?: string | null;
}

const ToggleFromList: FC<IToggleFromList> = ({
  existInWatchlist,
  noBackground = false,
  placement = 'top',
  id,
}) => {
  const { t } = useTenantTranslation();
  const { showAlert, hideAlert } = useAlert();
  const { toggleWatchlist, loading } = useToggleFromWatchlist(
    Boolean(existInWatchlist),
    String(id),
  );

  const handleClick = async (event) => {
    const ref = event.currentTarget;
    await toggleWatchlist();
    showAlert({
      message: <ToggleWatchlistPopper existInWatchList={existInWatchlist} />,
      ref,
    });
  };

  useEffect(() => {
    return () => {
      hideAlert();
    };
  }, [hideAlert]);

  return (
    <LightTooltip
      placement={placement}
      title={existInWatchlist ? t('common.removeFromWatchlist') : t('common.addToWatchlist')}
    >
      <Wrapper
        data-testid={existInWatchlist ? 'removeFromWatchlistBtn' : 'addToWatchlistBtn'}
        onClick={handleClick}
        noBackground={noBackground}
        disabled={loading}
      >
        {existInWatchlist ? (
          <StyledRemoveIcon data-testid="removeFromWatchlistIcon" />
        ) : (
          <StyledAddIcon data-testid="addToWatchlistIcon" />
        )}
      </Wrapper>
    </LightTooltip>
  );
};

export default ToggleFromList;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'noBackground' && prop !== 'disabled',
})`
  width: ${({ noBackground }) => (noBackground ? 'initial' : '40px')};
  height: ${({ noBackground }) => (noBackground ? 'initial' : '40px')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${({ noBackground, theme }) =>
    noBackground ? 'initial' : theme.palette.common.card.background.light};
  box-shadow: 0 2px 6px 0 ${({ theme }) => alpha(theme.palette.common.secondaryShadow, 0.3)};
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
  transition: opacity 0.6s;
`;
const createIcon = (tag) => styled(tag)`
  position: relative;
  top: 2px;
  left: 2px;
`;
const StyledAddIcon = createIcon(AddToList);
const StyledRemoveIcon = createIcon(RemoveFromList);
