import React, { FC } from 'react';

import { alpha } from '@mui/material/styles';

import CloseIcon from '~/assets/icons/CloseIcon';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import NotificationFactory from '~/pages/DashboardPage/components/NotificationFactory/NotificationFactory';
import styled from '~/styled';
import {
  MIDDLE_MARGIN_PX,
  MIDDLE_RADIUS_PX,
  SMALL_MARGIN_PX,
  SMALL_RADIUS_PX,
  SUBMIDDLE_MARGIN_PX,
  TINY_MARGIN_PX,
} from '~/theme';
import { AnnouncementEntity } from '~/types';
import assertNever from '~/utils/errors/assertNever';

interface INotifications {
  items?: AnnouncementEntity[];
  fetchMore: () => void;
  disabledPagination: boolean;
  closeHandler: () => void;
}

const getNotificationEntityId = (notification: AnnouncementEntity) => {
  switch (notification.__typename) {
    case undefined:
      return null;
    case 'NotificationForCompletedPathway':
      return notification?.pathway?.id;
    case 'NotificationForCompletedCourse':
      return notification?.course?.id;
    case 'NotificationForAssignment':
    case 'NewVideoFromExpert':
    case 'Video':
    case 'Pathway':
      return notification.id;
    default:
      assertNever(notification);
  }
};

const Notifications: FC<INotifications> = ({
  items,
  fetchMore,
  disabledPagination,
  closeHandler,
}) => {
  const { t } = useTenantTranslation();

  return (
    <Wrapper>
      <Row>
        <Title>{t('page.dashboard.notifications')}</Title>
        <StyledCloseIcon onClick={closeHandler} />
      </Row>
      <List>
        {items?.map((item) => {
          return (
            <NotificationFactory
              key={getNotificationEntityId(item)}
              item={item}
              onClick={closeHandler}
            />
          );
        })}
        <ViewMore disabled={disabledPagination} onClick={fetchMore}>
          {t('button.viewMore')}
        </ViewMore>
      </List>
    </Wrapper>
  );
};

export default Notifications;

const Row = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 ${TINY_MARGIN_PX} ${SMALL_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }
`;
const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-height: calc(100vh - 60px);
  margin: 0 0 0 -${MIDDLE_MARGIN_PX};
  padding: ${SUBMIDDLE_MARGIN_PX} ${TINY_MARGIN_PX} ${MIDDLE_MARGIN_PX} ${TINY_MARGIN_PX};
  background: ${({ theme }) => theme.palette.common.blueDark};

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 600px;
    margin: 0 0 0 -${MIDDLE_MARGIN_PX};
    padding: ${MIDDLE_MARGIN_PX} ${SMALL_MARGIN_PX} ${MIDDLE_MARGIN_PX}
      calc(${MIDDLE_MARGIN_PX} - ${TINY_MARGIN_PX});
    border-radius: ${MIDDLE_RADIUS_PX};
    background: ${({ theme }) => theme.palette.common.card.overlay.main};
    box-shadow: 0 13px 26px ${({ theme }) => alpha(theme.palette.common.blackPure, 0.33)};
  }
`;
const List = styled('ul')`
  max-height: 100%;
  list-style-type: none;
  padding: 0 ${TINY_MARGIN_PX};
  margin: 0;
  overflow-y: auto;

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-height: 440px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 18px 18px ${({ theme }) => alpha(theme.palette.common.blue, 0.33)};
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    box-shadow: inset 0 0 18px 18px ${({ theme }) => theme.palette.common.blue};
  }
`;
const ViewMore = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  width: 100%;
  margin-top: ${SMALL_MARGIN_PX};
  background: ${({ theme }) => theme.palette.common.violetAnalyticsCard};
  color: ${({ theme }) => theme.palette.common.gray};
  font-weight: 700;
  border-radius: ${SMALL_RADIUS_PX};
  border: none;
  pointer-events: ${({ disabled }) => disabled && 'none'};
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  opacity: ${({ disabled }) => disabled && '0.3'};
`;
const Title = styled('span')`
  display: block;
  font-weight: 700;
`;
const StyledCloseIcon = styled(CloseIcon)`
  width: 14px;
  height: 14px;
`;
