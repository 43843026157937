import React, { FC, ReactNode, useMemo } from 'react';

import CheckIcon from '~/assets/images/CheckIcon.svg';
import CheckIconSsku from '~/assets/images/CheckIconSsku.svg';
import styled from '~/styled';
import { TINY_MARGIN_PX } from '~/theme';
import { generateRandomString } from '~/utils/stringUtils';

interface CheckboxProps {
  name: string;
  children: ReactNode;
  checked: boolean;
  onChange?: Function;
  className?: string;
  variant?: 'secondary' | 'primary';
}

const Checkbox: FC<CheckboxProps> = ({
  name,
  className,
  children,
  variant = 'secondary',
  ...rest
}) => {
  //used to avoid setting wrong checkboxes if couple components are rendered on page
  const id = useMemo(() => generateRandomString(5), []);

  return (
    <div className={className}>
      <CheckboxInput variant={variant} type="checkbox" id={`${name}_${id}`} name={name} {...rest} />
      <CheckboxLabel className="checkboxLabel" htmlFor={`${name}_${id}`}>
        {children}
      </CheckboxLabel>
    </div>
  );
};

const StyledCheckbox = styled(Checkbox)`
  display: flex;
  align-items: center;
`;

const CheckboxInput = styled('input')<{ variant: CheckboxProps['variant'] }>`
  position: absolute;
  z-index: -1;
  opacity: 0;

  &:checked + .checkboxLabel {
    &::before {
      border: 2px solid
        ${({ theme, variant }) =>
          variant === 'secondary' ? theme.palette.common.accent : theme.palette.common.primary};
    }

    &::after {
      display: block;
    }
  }
`;
const CheckboxLabel = styled('label')`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.common.text.secondaryToPrimary};

  &::before {
    content: '';
    width: 21px;
    min-width: 21px;
    height: 21px;
    border-radius: 5px;
    border: 2px solid ${({ theme }) => theme.palette.common.icon.gray};
    display: block;
    margin-right: ${TINY_MARGIN_PX};
    margin-top: 2px;
  }

  &::after {
    content: '';
    display: none;
    background-image: url('${({ theme }) => (theme.isSsku ? CheckIconSsku : CheckIcon)}');
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 2;
    position: absolute;
    left: 5px;
    top: 51%;
    transform: translateY(-50%);
    width: 12px;
    height: 9px;
  }
`;

export default StyledCheckbox;
