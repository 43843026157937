import React, { FC, useContext, useEffect, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import CustomPage from '~/blocks/CustomPage/CustomPage';
import NotFoundPage from '~/blocks/NotFoundPage/NotFoundPage';
import JSBlock from '~/components/JSBlock/JSBlock';
import RouteWithCode from '~/components/RouteWithCode/RouteWithCode';
import { DEFAULT_DISCOVER_URL } from '~/const';
import { TenantCtx } from '~/context/TenantProvider';
import { GET_PAGE_BY_URL } from '~/graphql/pages';
import { LearnerInfo, Query, QueryGetPageByUrlArgs } from '~/types';

const getSearchActionObj = (name: string, url: string) => ({
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name,
  url: `${url}/`,
  potentialAction: [
    {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: `${url}${DEFAULT_DISCOVER_URL}/search?s={search_term_string}`,
      },
      'query-input': 'required name=search_term_string',
    },
  ],
});

interface HomepageProps {
  userInfo?: LearnerInfo;
  userLoading: boolean;
}

const Homepage: FC<HomepageProps> = ({ userLoading, userInfo }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { tenant, config } = useContext(TenantCtx);
  const pathname = location?.pathname;
  const queryParams = new URLSearchParams(location?.search);
  const searchValue = queryParams.get('s');
  const cleanPathname =
    pathname.length > 1 && pathname[pathname.length - 1] === '/' ? pathname.slice(0, -1) : pathname;

  const { data: pageData, loading: pageLoading } = useQuery<
    Pick<Query, 'getPageByUrl'>,
    QueryGetPageByUrlArgs
  >(GET_PAGE_BY_URL, {
    variables: {
      input: {
        url: [cleanPathname],
      },
    },
    errorPolicy: 'all',
  });

  const blocks = pageData?.getPageByUrl?.blocks;
  const hasBlocks = blocks && blocks.length > 0;

  const scriptData = useMemo(
    () => ({
      customScriptType: 'application/ld+json',
      js_code: JSON.stringify(getSearchActionObj(tenant?.title || '', tenant?.portal_url || '')),
    }),
    [tenant],
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location?.search);
    const searchValue = queryParams.get('s');
    if (searchValue) {
      navigate(`${DEFAULT_DISCOVER_URL}/search/?s=${searchValue}`);
    }
    if (!pageLoading && !hasBlocks) {
      navigate('/404');
    }
  }, [navigate, pageLoading, hasBlocks, location]);

  if (!searchValue && userInfo && !userLoading) {
    return <Navigate to={config?.appMainPage} replace />;
  }

  if (pageLoading) {
    return null;
  }

  if (hasBlocks) {
    return (
      <>
        {process.env.REACT_APP_BUILD_TYPE === 'prod' && (
          <Helmet>
            <link rel="canonical" href={`${tenant?.portal_url}/`} />
          </Helmet>
        )}
        <JSBlock data={scriptData} />
        <CustomPage blocks={blocks} preventLoader blocksLoading={pageLoading} />
      </>
    );
  } else {
    return (
      <RouteWithCode code={404}>
        <NotFoundPage />
      </RouteWithCode>
    );
  }
};

export default Homepage;
