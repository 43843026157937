import React from 'react';

import { Autocomplete, Box, Typography } from '@mui/material';

import useTenantTranslation from '~/hooks/useTenantTranslation';
import useValidation from '~/hooks/useValidation';
import {
  CancelButton,
  ModalContent,
  ModalWrapper,
  StyledCloseIcon,
  StyledCustomButton,
  StyledFormField,
} from '~/modals/modalStyles';
import { ModalProps } from '~/modals/types';
import { learnerTeamFormSchema } from '~/pages/MyAccountPage/components/ChangeLearnerTeamModal/validation';
import { LearnerInfo, Team } from '~/types';

export interface IChangeLearnerTeamModal {
  learner?: LearnerInfo | null;
  teams: (Team | null)[];
  currentTeam?: Team | null;
  onSubmit: (value: { team: Team }) => void;
}

const ChangeLearnerTeamModal: React.FC<IChangeLearnerTeamModal & ModalProps> = ({
  teams,
  learner,
  currentTeam,
  closeModal,
  onSubmit,
  labelledBy,
}) => {
  const { t } = useTenantTranslation();
  const { values, errors, handleSubmit, handleAutocompleteChange } = useValidation(
    {
      team: currentTeam,
    },
    learnerTeamFormSchema,
    onSubmit,
  );

  return (
    <ModalWrapper data-testid="changeTeamModal">
      <StyledCloseIcon onClick={closeModal} />
      <Typography id={labelledBy} variant="h3">
        {t('page.account.changeTeamFor', { learner: learner?.name })}
      </Typography>
      <ModalContent>
        <Autocomplete
          data-testid="teamField"
          options={teams || []}
          getOptionLabel={(option) => option?.name || ''}
          value={values.team}
          onChange={handleAutocompleteChange('team')}
          renderInput={(params) => (
            <StyledFormField
              {...params}
              name="team"
              variant="outlined"
              label={t('page.assignedLearnings.team')}
              error={!!errors?.team}
              helperText={errors?.team}
            />
          )}
        />
      </ModalContent>
      <Box>
        <CancelButton onClick={closeModal} color="secondary">
          {t('button.cancel')}
        </CancelButton>
        <StyledCustomButton data-testid="changeTeamBtn" onClick={handleSubmit}>
          {t('button.change')}
        </StyledCustomButton>
      </Box>
    </ModalWrapper>
  );
};

export default ChangeLearnerTeamModal;
