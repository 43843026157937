import { createContext, useContext } from 'react';

import useCompanyLogo from '~/hooks/useCompanyLogo';
import { LearnerInfo } from '~/types';

export function useUserInfoContext() {
  const context = useContext(UserInfoCtx);

  if (!context) {
    throw new Error('useUserInfoContext must be used within a UserInfoCtx');
  }

  return context;
}
export const UserInfoCtx = createContext<{
  userLoading: boolean;
  suiteIDs: string[];
  hasAccessToContent: boolean;
  userInfo?: LearnerInfo;
  companyLogo?: ReturnType<typeof useCompanyLogo>;
}>({
  userInfo: undefined,
  suiteIDs: [],
  userLoading: false,
  hasAccessToContent: false,
  companyLogo: undefined,
});
