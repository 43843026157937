import React from 'react';

import { Box, Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import cn from 'classnames';
import parse from 'html-react-parser';

import LazyLoadImage from '~/components/LazyloadImage/LazyLoadImage';
import SafeLink from '~/components/SafeLink';
import CustomButton from '~/components/UI/CustomButton/CustomButton';
import styled, { css } from '~/styled';
import {
  BREAKPOINTS_VALUES,
  LARGE_MARGIN_PX,
  MIDDLE_MARGIN_PX,
  SMALL_MARGIN_PX,
  SUBMIDDLE_MARGIN_PX,
  useIsWidthUp,
} from '~/theme';
import { Background } from '~/types';

export const TEXT = 'text';
export const IMAGE = 'image';

//object with different styling for single, first and second image
const IMAGE_DATA = {
  SINGLE: {
    wrapperClass: 'images-text-block__image-wrap',
    srcset: [],
    sizes: '',
  },
  FIRST: {
    wrapperClass: 'images-text-block__image-wrap',
    srcset: [{ width: 584 }, { width: 350 }],
    sizes: `(max-width:${BREAKPOINTS_VALUES['lg']}px) 584px, 350px`,
  },
  SECOND: {
    wrapperClass: 'images-text-block__second-image-wrap',
    srcset: [{ width: 420 }, { width: 343 }],
    sizes: `(max-width:${BREAKPOINTS_VALUES['lg']}px) 420px, 343px`,
  },
};

const Text = ({
  shouldStartFromImage,
  heading,
  isImageAndTextBlock,
  text,
  buttonText,
  buttonUrl,
}) => {
  return (
    <TextContainer className={cn('images-text-block__text-container', 'textContainer')}>
      <TextWrapper
        className={'images-text-block__text-wrapper'}
        startFromImage={shouldStartFromImage}
      >
        <ItemHeading
          variant="h2"
          component="h2"
          className={cn('images-text-block__item-heading', 'itemHeading')}
          optionalStyles={isImageAndTextBlock && leftAlign}
        >
          {heading}
        </ItemHeading>
        <ItemText className={'images-text-block__item-text'}>{parse(text)}</ItemText>
        {Boolean(buttonUrl && buttonText) && (
          <SafeLink to={buttonUrl}>
            <StyledCustomButton>{buttonText}</StyledCustomButton>
          </SafeLink>
        )}
      </TextWrapper>
    </TextContainer>
  );
};

const getImageData = (isSingle: boolean, isFirst: boolean) => {
  if (isSingle) return IMAGE_DATA.SINGLE;
  else if (isFirst) return IMAGE_DATA.FIRST;
  else return IMAGE_DATA.SECOND;
};

const Image = ({
  background,
  shouldStartFromImage,
  image,
  mobileImage = '',
  alt,
  isSingle = false,
  isFirst = false,
  isBgImage = false,
}) => {
  const imageData = getImageData(isSingle, isFirst);

  const isMobile = !useIsWidthUp('md');

  return (
    <ImageWrapper
      className={cn(imageData.wrapperClass, isSingle && 'singleImageWrap')}
      optionalStyles={
        isSingle
          ? singleImageWrap(shouldStartFromImage)
          : isFirst
          ? firstImageWrap
          : secondImageWrap(shouldStartFromImage)
      }
    >
      {isBgImage ? (
        <div className="imagePreloaderWrap">
          <LazyLoadImage
            path={isMobile && mobileImage ? mobileImage : image}
            alt={alt}
            draggable={false}
            srcset={imageData.srcset}
            sizes={imageData.sizes}
            wrapperCss={imgGradientWrapper(background, shouldStartFromImage)}
          />
        </div>
      ) : (
        <LazyLoadImage
          imageWrapperClass="imagePreloaderWrap"
          path={isMobile && mobileImage ? mobileImage : image}
          alt={alt}
          draggable={false}
          srcset={imageData.srcset}
          sizes={imageData.sizes}
        />
      )}
    </ImageWrapper>
  );
};

const ImageTextArrayBlock = ({ data }) => {
  const background = data?.background;
  const shouldStartFromImage = data?.shouldStartFromImage;
  const itemsToFilter = data?.items;

  function* filter(array, condition, maxSize) {
    if (!maxSize || maxSize > array.length) {
      maxSize = array.length;
    }
    let count = 0;
    let i = 0;
    while (count < maxSize && i < array.length) {
      if (condition(array[i])) {
        yield array[i];
        count++;
      }
      i++;
    }
  }

  const texts = Array.from(filter(itemsToFilter, (i) => i.type === TEXT, 1));
  const images = Array.from(filter(itemsToFilter, (i) => i.type === IMAGE, 2));
  const imagesLength = images?.length;
  const items = imagesLength === 2 ? [images[0], ...texts, images[1]] : [...images, ...texts];
  const hasBackgroundImage = items.length === 2 && images?.[0]?.is_background;

  const heading = typeof data?.heading === 'string' ? data?.heading : data?.heading?.en;

  if (!items || items.length === 0) return null;
  const isImageAndTextBlock = !!(
    items.find((item) => item.type === IMAGE) && items.find((item) => item.type === TEXT)
  );

  return (
    <Wrapper
      className={cn(
        background === 'Dark' ? 'dark-block' : 'light-block',
        'images-text-block',
        hasBackgroundImage && 'images-text-block__with-bg-image',
      )}
      optionalStyles={hasBackgroundImage && wrapperWithBgImage}
      background={background}
    >
      <StyledContainer optionalStyles={imagesLength === 1 && wideContainer}>
        {!hasBackgroundImage && (
          <Heading
            variant="h2"
            component="h2"
            align="center"
            className="images-text-block__heading"
          >
            {heading}
          </Heading>
        )}
        <ContentWrapper>
          <Content className="content" startFromImage={shouldStartFromImage}>
            {items &&
              items.map((item, index) => {
                const type = item?.type;
                return (
                  <React.Fragment key={index}>
                    {type === IMAGE && (
                      <Image
                        background={background}
                        shouldStartFromImage={shouldStartFromImage}
                        image={item?.image}
                        mobileImage={item?.mobile_image}
                        alt={item?.alt}
                        isSingle={imagesLength === 1}
                        isFirst={imagesLength === 2 && index === 0}
                        isBgImage={hasBackgroundImage}
                      />
                    )}
                    {type === TEXT && (
                      <Text
                        shouldStartFromImage={shouldStartFromImage}
                        heading={heading}
                        text={item?.text}
                        isImageAndTextBlock={isImageAndTextBlock}
                        buttonText={item?.button_text}
                        buttonUrl={item?.button_url}
                      />
                    )}
                  </React.Fragment>
                );
              })}
          </Content>
        </ContentWrapper>
      </StyledContainer>
    </Wrapper>
  );
};

export default ImageTextArrayBlock;

const TextContainer = styled(Box)`
  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: 50%;
  }
`;
const TextWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'startFromImage',
})`
  margin-bottom: ${SUBMIDDLE_MARGIN_PX};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  &:last-child {
    margin-bottom: 0;
  }

  & p {
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 50%;
    min-width: 343px;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: ${MIDDLE_MARGIN_PX};
    padding-left: ${({ startFromImage }) => (startFromImage ? '120px' : '0px')};
    padding-right: ${({ startFromImage }) => (startFromImage ? '0px' : '120px')};
    margin-left: ${({ startFromImage }) => (startFromImage ? '0px' : 'auto')};
    margin-right: ${({ startFromImage }) => (startFromImage ? 'auto' : '0px')};
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    max-width: 586px;
  }
`;
const ItemHeading = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})`
  && {
    margin-bottom: ${SMALL_MARGIN_PX};
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    display: none;
    width: 100%;

    ${({ theme }) => theme.breakpoints.up('lg')} {
      display: block;
      font-size: 28px;
      line-height: 34px;
      text-align: left;
    }
  }

  ${({ optionalStyles }) => optionalStyles}
`;
const leftAlign = css`
  && {
    align-self: flex-start;
  }
`;
const ItemText = styled('div')`
  margin-bottom: ${MIDDLE_MARGIN_PX};

  &:last-child {
    margin-bottom: 0;
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6,
  & p,
  & ul,
  & ol {
    margin-bottom: ${SMALL_MARGIN_PX};
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6,
  & p {
    text-align: center;

    ${({ theme }) => theme.breakpoints.up('lg')} {
      text-align: left;
    }
  }

  &,
  & p,
  li {
    font-size: 16px;
    line-height: 1.5;
    color: ${({ theme }) => theme.palette.common.gray};

    ${({ theme }) => theme.breakpoints.up('lg')} {
      font-size: 18px;
    }
  }

  & ul,
  & ol {
    padding-left: 20px;

    & li {
      &::marker {
        color: ${({ theme }) => theme.palette.common.primary};
      }
    }
  }

  & a {
    color: ${({ theme }) => theme.palette.common.white};
    font-weight: bold;

    &:hover {
      color: ${({ theme }) => theme.palette.common.blue};
    }
  }
`;
const StyledCustomButton = styled(CustomButton)`
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: unset;
  }
`;
const singleImageWrap =
  (startFromImage) =>
  ({ theme }) =>
    css`
      width: 343px;
      margin-bottom: ${SMALL_MARGIN_PX};

      ${theme.breakpoints.up('lg')} {
        display: flex;
        width: 50%;
        margin-bottom: 0;
      }

      & img {
        width: 100%;
        object-fit: contain;
        max-height: 100%;
        border-radius: 5px;
      }

      & .imagePreloaderWrap {
        margin-left: ${startFromImage ? 'auto' : '0px'};
        margin-right: ${!startFromImage && 'auto'};

        ${theme.breakpoints.up('lg')} {
          max-height: 470px;
          max-width: 900px;

          & img {
            max-width: 900px;
            max-height: 470px;
          }
        }
      }
    `;
const firstImageWrap = ({ theme }) => css`
  height: 200px;
  margin-bottom: ${SUBMIDDLE_MARGIN_PX};

  & .imagePreloaderWrap {
    width: 100%;
    height: 100%;
  }

  & img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
    border-radius: 5px;
  }

  ${theme.breakpoints.up('lg')} {
    margin-bottom: 40px;
    width: 50%;
    height: 359px;

    & img {
      object-fit: cover;
    }
  }
`;
const secondImageWrap =
  (startFromImage) =>
  ({ theme }) =>
    css`
      height: 200px;
      margin-bottom: ${SUBMIDDLE_MARGIN_PX};

      & .imagePreloaderWrap {
        width: 100%;
        height: 100%;
      }

      & img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        object-fit: contain;
        border-radius: 5px;
      }

      ${theme.breakpoints.up('lg')} {
        position: absolute;
        bottom: 0;
        left: ${startFromImage && '20%'};
        right: ${!startFromImage && '0'};
        width: 36%;
        height: 253px;
        margin-bottom: 0;

        & img {
          object-fit: cover;
        }
      }
    `;
const imgGradientWrapper =
  (background, startFromImage) =>
  ({ theme }) =>
    css`
      display: inline-flex;
      position: relative;
      max-width: 100%;
      width: 100%;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          ${alpha(theme.palette.common.blackSecondary, 0)} 37.59%,
          ${theme.palette.common.blueDark} 95.39%
        );
      }

      ${theme.breakpoints.up('sm')} {
        &::before {
          background: linear-gradient(
            180deg,
            ${alpha(theme.palette.common.blackSecondary, 0)} 37.59%,
            ${background === Background.Dark
                ? theme.palette.common.blueDark
                : theme.palette.common.blackSecondary}
              95.39%
          );
        }
      }

      ${theme.breakpoints.up('lg')} {
        width: unset;

        &::before {
          background: linear-gradient(
            ${startFromImage ? '90deg' : '-90deg'},
            ${alpha(theme.palette.common.blackSecondary, 0)} 37.59%,
            ${background === Background.Dark
                ? theme.palette.common.blueDark
                : theme.palette.common.blackSecondary}
              95.39%
          );
        }
      }
    `;
const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'background' && prop !== 'optionalStyles',
})`
  padding-top: ${MIDDLE_MARGIN_PX};
  padding-bottom: ${MIDDLE_MARGIN_PX};
  background: ${({ theme }) => theme.palette.secondary.dark};
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-top: ${LARGE_MARGIN_PX};
    padding-bottom: ${LARGE_MARGIN_PX};
    background: ${({ background, theme }) =>
      background === 'Dark' ? theme.palette.secondary.dark : theme.palette.secondary.light};
  }

  &:first-child {
    margin-top: 76px;
  }

  ${({ optionalStyles }) => optionalStyles}
`;
const wrapperWithBgImage = ({ theme }) =>
  css`
    padding-top: 0;
    padding-bottom: 0;

    ${theme.breakpoints.up('sm')} {
      padding-top: 0;
      padding-bottom: 0;
    }

    & .content {
      ${theme.breakpoints.up('lg')} {
        justify-content: flex-end;
      }
    }
  `;
const StyledContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})`
  & {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${({ theme }) => theme.palette.common.white};
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;

    ${({ optionalStyles }) => optionalStyles}
  }
`;
const wideContainer = ({ theme }) => css`
  ${theme.breakpoints.up('xl')} {
    max-width: 1600px;
  }
`;
const Heading = styled(Typography)`
  && {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: ${SMALL_MARGIN_PX};

    ${({ theme }) => theme.breakpoints.up('lg')} {
      display: none;
      margin-bottom: ${MIDDLE_MARGIN_PX};
    }
  }
`;
const ContentWrapper = styled('div')`
  width: 100%;
`;
const Content = styled('div', {
  shouldForwardProp: (prop) => prop !== 'startFromImage',
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    align-items: center;
    position: relative;
    flex-direction: ${({ startFromImage }) => (!startFromImage ? 'row-reverse' : 'row')};
  }
`;
const ImageWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'optionalStyles',
})`
  ${({ optionalStyles }) => optionalStyles}
`;
