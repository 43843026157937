import { DEFAULT_DISCOVER_URL } from '~/const';
import { Maybe, Video } from '~/types';

export const JOIN_TEAM_LINK = '/my-account/join-team/';
export const TEAMS_LINK = '/my-account/teams';
export const JOIN_TEAM_ROUTE = `${JOIN_TEAM_LINK}:hash`;

export const INSIGHTS_SERP_ROUTE = `/insights`;

export const DiscoverRoutes = {
  Search: `/search`,
  Videos: `/videos`,
  Pathways: `/pathways`,
  Glossary: `/glossary`,
  Courses: `/courses`,
  Insights: INSIGHTS_SERP_ROUTE,
  Experts: `/experts`,
};

export const SERP_ROUTES = [
  DiscoverRoutes.Search,
  DiscoverRoutes.Videos,
  DiscoverRoutes.Pathways,
  DiscoverRoutes.Glossary,
  DiscoverRoutes.Courses,
];

export const DISCOVER_VIDEOS_ROUTE = `${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Videos}`;

export const PRICING_ROUTE = '/pricing';
export const MY_MEMBERSHIP_ROUTE = '/my-account/membership-area';
export const HOME_ROUTE = '/home';
export const ROOT_ROUTE = '/';
export const LEARNER_AL_ROUTE = '/assigned-learning';
export const MANAGER_AL_ROUTES = {
  List: '/assigned-learning/dashboard',
  FormTypeSelect: '/assigned-learning/dashboard/new',
};
export const SHARE_PATHWAY_ROUTE = '/share';
export const SHARE_COURSE_ROUTE = '/share/courses';

export const MAINTENANCE = '/maintenance';
export const hardcodedCourseUrls = [
  '/courses/Master-the-project-and-people-challenge',
  '/courses/An-introduction-to-coding',
  '/courses/Green-strategies-for-financial-savings',
];
export const oldExpertPageRoutes = ['/expert/:expertSlug', '/experts/:expertSlug'];
export const homepageRoutes = ['/home', '/recently-watched-my-watchlist/'];
export const LOGIN_ROUTE = '/login';
export const loginRoutes = [LOGIN_ROUTE, '/sso'];

export const getVideoLink = (video?: Maybe<Video>) => {
  if (!video) {
    return ROOT_ROUTE;
  }

  return video?.video_url
    ? `${DiscoverRoutes.Videos}/${video?.video_url}/`
    : `${DiscoverRoutes.Videos}/${video?.id}/`;
};

export const getExpertLink = (video?: Maybe<Video>) =>
  video ? `${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Experts}/${video.user?.slug}` : ROOT_ROUTE;

export const CASE_STUDY_PATH = `${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Pathways}/:pathwaySlug/case-study/:studyId`;
export const buildPathwayRoute = (slug: string) =>
  `${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Pathways}/${slug}`;
export const buildTestRoute = (slug: string, testId: string) =>
  `${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Pathways}/${slug}/test/${testId}`;
export const buildCaseStudyRoute = (pathwaySlug?: Maybe<string>, studyId?: Maybe<string>) =>
  pathwaySlug && studyId
    ? `${DEFAULT_DISCOVER_URL}${DiscoverRoutes.Pathways}/${pathwaySlug}/case-study/${studyId}`
    : ROOT_ROUTE;
export const buildCourseRoute = (slug: string) => `${DiscoverRoutes.Courses}/${slug}`;
export const buildCourseProgressRoute = (slug: string) => `${buildCourseRoute(slug)}/progress`;
