import React from 'react';

import { LightTooltip } from '~/components/UI/LightTooltip/LightTooltip';
import useTooltipOffset from '~/hooks/useTooltipOffset';
import styled, { CssUtilityType } from '~/styled';

interface ProgressLineProps {
  width?: number;
  lineCss?: CssUtilityType;
  tooltipText?: string;
}

const ProgressLine: React.FC<ProgressLineProps> = ({
  width = 0,
  lineCss = '',
  tooltipText = '',
  ...rest
}) => {
  const [offset, wrapperRef, handleMouseEnter] = useTooltipOffset();

  const line = (
    <Line data-testid="progress" style={{ width: `${width}%` }} additionalStyles={lineCss} />
  );

  return tooltipText ? (
    <StyledTooltip
      placement="top"
      title={tooltipText}
      PopperProps={{
        popperOptions: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [offset.x, 0],
              },
            },
          ],
        },
      }}
    >
      <Wrapper ref={wrapperRef} onMouseEnter={handleMouseEnter} {...rest}>
        {line}
      </Wrapper>
    </StyledTooltip>
  ) : (
    <Wrapper {...rest}>{line}</Wrapper>
  );
};

export default ProgressLine;

const StyledTooltip = styled(LightTooltip)`
  &:before {
    display: none;
  }
`;
const Wrapper = styled('div')`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 6px;
  background: ${({ theme }) => theme.palette.common.card.progressBackground};
  width: 100%;
  z-index: 5;
`;
const Line = styled('div', {
  shouldForwardProp: (prop) => prop !== 'additionalStyles',
})`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  transition: width 0.5s;
  background: ${({ theme }) => theme.palette.common.accent};
  z-index: 6;

  ${({ additionalStyles }) => additionalStyles}
`;
