import React, { FC, useState } from 'react';

import { Typography } from '@mui/material';

import CustomButton from '~/components/UI/CustomButton/CustomButton';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import { ModalWrapper, StyledCloseIcon } from '~/modals/modalStyles';
import { ModalProps } from '~/modals/types';
import styled from '~/styled';
import { MIDDLE_MARGIN_PX, SMALL_MARGIN_PX } from '~/theme';

export interface IConfirmModal {
  onSubmit: () => void | Promise<any>;
  title: string;
  description: string;
  cancelBtnCaption?: string;
  submitBtnCaption?: string;
}

const Confirm: FC<IConfirmModal & ModalProps> = ({
  closeModal,
  onSubmit,
  title,
  description,
  cancelBtnCaption,
  submitBtnCaption,
  labelledBy,
  ...rest
}) => {
  const { t } = useTenantTranslation();
  const [loading, setLoading] = useState(false);

  return (
    <ModalWrapper data-testid={rest['data-testid']}>
      <StyledCloseIcon onClick={closeModal} />
      <Typography id={labelledBy} variant="h3">
        {title}
      </Typography>
      {description && <Description>{description}</Description>}
      <Buttons>
        <CancelButton
          data-testid="cancelConfirm"
          className={'cancel-btn'}
          onClick={closeModal}
          color="secondary"
        >
          {cancelBtnCaption || t('button.cancel')}
        </CancelButton>
        <CustomButton
          loading={loading}
          data-testid="submitConfirm"
          btnClass="confirm-btn"
          onClick={async () => {
            const result = onSubmit?.();
            if (result instanceof Promise) {
              setLoading(true);
              await result;
            }

            setLoading(false);
            closeModal?.();
          }}
        >
          {submitBtnCaption || t('button.delete')}
        </CustomButton>
      </Buttons>
    </ModalWrapper>
  );
};

export default Confirm;

const Buttons = styled('div')`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
    justify-content: center;
  }
`;
const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.gray};
  font-size: 20px;
  margin-top: ${MIDDLE_MARGIN_PX};
  margin-bottom: ${MIDDLE_MARGIN_PX};
`;
const CancelButton = styled(CustomButton)`
  && {
    margin-bottom: ${SMALL_MARGIN_PX};

    ${({ theme }) => theme.breakpoints.up('sm')} {
      margin-right: ${SMALL_MARGIN_PX};
      margin-bottom: 0;
    }
  }
`;
