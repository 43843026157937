import React, { FC, useContext, useEffect, useMemo, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import Loader from '~/components/Loader/Loader';
import LoginWithPopupTriggerView from '~/components/LoginWithPopupTriggerView/LoginWithPopupTriggerView';
import SeoBlock from '~/components/SeoBlock/SeoBlock';
import { ONLINE_CONTENT_CONNECTOR_ATTRIBUTES } from '~/context/OnlineContentConnectorContext';
import { TenantCtx } from '~/context/TenantProvider';
import useIsSsku from '~/hooks/tenant/useIsSsku';
import useAuth from '~/hooks/useAuth';
import useGetLoginDomainByConnection from '~/hooks/useGetLoginDomainByConnection';
import useQueryParams from '~/hooks/useQueryParams';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import { LearnerInfo } from '~/types';
import { checkIsIframed } from '~/utils/checkIsIframed';

enum SOUCSE_TYPES {
  CSOD = 'csod',
  SABA = 'saba',
}

interface LoginPageProps {
  userInfo?: LearnerInfo;
  userLoading: boolean;
}

const checkString = (val: any): string => (typeof val === 'string' ? val : '');
type Entry = [string, any];
type Mapper = (entry: Entry) => Entry;
const mapEntries = (cb: Mapper) => (obj: {}) => Object.fromEntries(Object.entries(obj).map(cb));
const sanitizeKeys = mapEntries(([key, val]) => [key?.replace(/\?/, '') || key, val]);

export const getConnectionName = (sanitizedParams) => {
  let connectionName = '';
  const hasConnectionParam =
    sanitizedParams?.connection && typeof sanitizedParams.connection === 'string';
  const source = sanitizedParams?.source?.toLowerCase();
  const shouldGenerateConnectionName = Object.values(SOUCSE_TYPES).includes(source);

  if (hasConnectionParam) {
    if (shouldGenerateConnectionName) {
      const isCustom =
        sanitizedParams.connection === 'cornerstone' &&
        sanitizedParams.source === 'CSOD' &&
        sanitizedParams.subdomain === 'thinkcontent';

      if (isCustom) {
        connectionName = 'cornerstone';
      } else if (source === SOUCSE_TYPES.CSOD) {
        connectionName = `${sanitizedParams.connection}-${source}-${sanitizedParams.subdomain}`;
      } else if (source === SOUCSE_TYPES.SABA) {
        const fqdn = sanitizedParams?.fqdn?.replace(/\./g, '-') || '';
        connectionName = `${sanitizedParams.connection}-${source}-${fqdn}`;
      } else {
        connectionName = sanitizedParams.connection;
      }
    } else {
      connectionName = sanitizedParams.connection;
    }
  }

  return connectionName;
};

const LoginPage: FC<LoginPageProps> = ({ userInfo, userLoading }) => {
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    loginWithRedirect,
    isInitialized,
    isLoading,
    loginWithPopup,
    updateLoginDomain,
    loginDomain,
    updatingClient,
  } = useAuth();
  const { t } = useTenantTranslation();
  const isSsku = useIsSsku();
  const params = useQueryParams();
  const sanitizedParams = useMemo(() => sanitizeKeys(params), [params]);
  const connectionNameFromParams = getConnectionName(sanitizedParams);
  const { tenant, config } = useContext(TenantCtx);
  const connectionName =
    isSsku && process.env.REACT_APP_BUILD_TYPE === 'prod'
      ? connectionNameFromParams || config.auth.connection
      : connectionNameFromParams;
  const { domain, loadingDomain } = useGetLoginDomainByConnection(connectionName);

  useEffect(() => {
    if (!(updatingClient || loadingDomain)) {
      if (connectionName && domain && loginDomain !== domain) {
        updateLoginDomain(domain || '');
      } else if (!(!isInitialized || isLoading || userLoading)) {
        if (!userLoading && userInfo) {
          let stringParams = '';
          if (sanitizedParams?.courseId) {
            const newParams = new URLSearchParams();
            ONLINE_CONTENT_CONNECTOR_ATTRIBUTES.forEach((key) => {
              if (sanitizedParams?.[key]) {
                newParams.append(key, checkString(sanitizedParams?.[key]));
              }
            });

            stringParams = newParams.toString();
          }
          if (sanitizedParams?.redirectUrl) {
            navigate(`${sanitizedParams.redirectUrl}${stringParams ? '?' + stringParams : ''}`, {
              replace: true,
            });
          } else {
            navigate(config?.appMainPage + `${stringParams ? '?' + stringParams : ''}`, {
              replace: true,
            });
          }
        } else {
          const hasPrevUrl = location?.state?.prevPath && location.state.prevPath !== '/';
          const redirectUrl = hasPrevUrl
            ? `${location.state.prevPath}${location.state.prevSearch || ''}`
            : config?.appMainPage;
          const hasParamRedirect = Boolean(sanitizedParams?.redirectUrl);

          //check to prevent usual login via /sso
          if (location?.pathname && location.pathname.match(/^\/sso/) && !connectionName) {
            navigate('/');
          } else {
            if (checkIsIframed()) {
              setShowLoginPopup(true);
            } else {
              loginWithRedirect({
                redirectUri: hasParamRedirect
                  ? (sanitizedParams.redirectUrl as string)
                  : redirectUrl,
                isDirectUrl: !(hasPrevUrl || hasParamRedirect),
                ...(connectionName && {
                  connection: connectionName,
                }),
                ...(sanitizedParams?.courseId && {
                  appState: {
                    onlineContentConnectorData: {
                      userGuid: sanitizedParams?.userGuid,
                      sessionToken: sanitizedParams?.sessionToken,
                      callbackUrl: sanitizedParams?.callbackUrl,
                      fqdn: sanitizedParams?.fqdn,
                      subdomain: sanitizedParams?.subdomain,
                      registrationNumber: sanitizedParams?.registrationNumber,
                      source: sanitizedParams?.source,
                      ...(sanitizedParams?.withNavigation && {
                        withNavigation: sanitizedParams?.withNavigation,
                      }),
                      ...(sanitizedParams?.nocc && { nocc: sanitizedParams?.nocc }),
                    },
                  },
                }),
              });
            }
          }
        }
      }
    }
  }, [
    connectionName,
    domain,
    isInitialized,
    isLoading,
    loadingDomain,
    location.pathname,
    location.state,
    loginDomain,
    loginWithRedirect,
    navigate,
    sanitizedParams,
    updateLoginDomain,
    updatingClient,
    userInfo,
    userLoading,
    tenant,
    config,
    isSsku,
  ]);

  const seoLayout = (
    <SeoBlock
      data={{
        seo_title: t('page.login.login'),
      }}
    />
  );

  if (isLoading || userLoading) {
    return (
      <>
        {seoLayout}
        <Loader />;
      </>
    );
  }

  if (showLoginPopup) {
    return (
      <>
        {seoLayout}
        <LoginWithPopupTriggerView
          isSso={Boolean(connectionName)}
          handleLogin={() => {
            loginWithPopup(connectionName ? { connection: connectionName } : undefined);
          }}
        />
      </>
    );
  }

  return <Loader />;
};

export default LoginPage;
