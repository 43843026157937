import React from 'react';

import { Box, Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import cx from 'classnames';

import IKImage from '~/components/IKImage/IKImage';
import LazyLoadImage from '~/components/LazyloadImage/LazyLoadImage';
import Button from '~/components/UI/Button/Button';
import styled, { css } from '~/styled';
import { BREAKPOINTS_VALUES, MIDDLE_MARGIN_PX } from '~/theme';

const ImageHeadingTextBlock = ({ data }) => {
  const background = data?.background;
  const desktopImg = data?.desktop_image;
  const items = data?.items;
  const heading = typeof data?.heading === 'string' ? data?.heading : data?.heading?.en;
  const buttonUrl = data?.button_url;
  const buttonText = data?.button_text;

  if (!items || items.length === 0) return null;

  return (
    <Wrapper
      data-testid="imageHeadingBlock"
      className={cx(background === 'Dark' ? 'dark-block' : 'light-block', 'image-array-block')}
      background={background}
    >
      <StyledContainer>
        <Heading variant="h2" component="h2" align="center">
          {heading}
        </Heading>

        <ContentWrapper>
          <Content>
            <div>
              {items &&
                items.map((item, index) => {
                  const heading =
                    typeof item?.heading === 'string' ? item?.heading : item?.heading?.en;
                  const description =
                    typeof item?.description === 'string'
                      ? item?.description
                      : item?.description?.en;

                  return (
                    <Item key={index} className={'image-array-block__item'}>
                      <ImageWrap>
                        <IKImage
                          path={item.image}
                          draggable={false}
                          alt={heading}
                          srcset={[{ width: 65 }, { width: 50 }]}
                          sizes={`(max-width:${BREAKPOINTS_VALUES['md']}px) 65px, 50px`}
                        />
                      </ImageWrap>

                      <TextWrapper>
                        <ItemTitle variant="h3" component="h3">
                          {heading}
                        </ItemTitle>

                        <ItemText>{description}</ItemText>
                      </TextWrapper>
                    </Item>
                  );
                })}
            </div>

            <ImageMacWrap>
              <LazyLoadImage
                path={desktopImg}
                draggable={false}
                alt={heading}
                srcset={[{ width: 345 }, { width: 590 }]}
                sizes={`(max-width:${BREAKPOINTS_VALUES['lg']}px) 345px, 590px`}
                wrapperCss={imageWrapperCss}
              />
            </ImageMacWrap>
          </Content>
          <StyledButton text={buttonText} link={buttonUrl} />
        </ContentWrapper>
      </StyledContainer>
    </Wrapper>
  );
};

export default ImageHeadingTextBlock;

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'background' && prop !== 'additionalStyles' && prop !== 'additionalStyles1',
})`
  padding-top: 36px;
  padding-bottom: 36px;
  background: ${({ theme }) => theme.palette.secondary.dark};

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-top: 72px;
    padding-bottom: 72px;
    background: ${({ background, theme }) =>
      background === 'Dark' ? theme.palette.secondary.dark : theme.palette.secondary.light};
  }

  overflow-x: hidden;
`;
const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.palette.common.white};
`;
const Heading = styled(Typography)`
  && {
    margin-bottom: 22px;

    ${({ theme }) => theme.breakpoints.up('md')} {
      margin-bottom: ${MIDDLE_MARGIN_PX};
    }
  }
`;
const ContentWrapper = styled('div')`
  background: ${({ theme }) => theme.palette.secondary.light};
  box-shadow: 0px 3px 6px ${({ theme }) => alpha(theme.palette.common.blackPure, 0.4)};
  border-radius: 5px;
  overflow: hidden;
  padding: 41px 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    box-shadow: none;
    background: none;
    padding: 0;
  }
`;
const Content = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    align-items: center;
    margin-bottom: ${MIDDLE_MARGIN_PX};
  }
`;
const Item = styled('div', {
  shouldForwardProp: (prop) => prop !== 'additionalStyles',
})`
  display: flex;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 44px;
  }
`;
const ImageWrap = styled('div')`
  width: 48px;
  flex-shrink: 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 65px;
  }

  & img {
    width: 100%;
  }
`;
const TextWrapper = styled('div')`
  margin-left: 23px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-left: 32px;
    width: 300px;
  }
`;
const ItemTitle = styled(Typography)`
  && {
    ${({ theme }) => theme.breakpoints.up('md')} {
      margin-bottom: 12px;
    }
  }
`;
const ItemText = styled(Typography)`
  font-size: 16px;
  line-height: 20px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 18px;
    line-height: 1.5;
  }
`;
const ImageMacWrap = styled('div')`
  display: none;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: block;
    margin-left: 88px;
    width: 364px;
    height: 364px;

    & img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 5px;
    }
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: 564px;
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    width: 634px;
  }
`;
const imageWrapperCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
const StyledButton = styled(Button)`
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: auto;
  }
`;
