import React from 'react';

import { useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';

import LinkedInIcon from '~/assets/icons/LinkedInIcon';
import LazyLoadImage from '~/components/LazyloadImage/LazyLoadImage';
import { H3, MetaText } from '~/components/UI/Texts';
import useIsIE from '~/hooks/useIsIE';
import styled, { css } from '~/styled';
import { SMALL_MARGIN_PX, TINY_MARGIN_PX } from '~/theme';

const defaultSrcset = [{ width: 220, height: 290, focus: 'auto' }];
const defaultSizes = '100vw';
const defaultTransformation = [
  {
    width: 220,
    height: 290,
    focus: 'auto',
  },
];

const TeamMember = ({
  title,
  jobTitle,
  imageUrl,
  link,
  linkTitle,
  isDark = false,
  srcset = defaultSrcset,
  sizes = defaultSizes,
  transformation = defaultTransformation,
  className = '',
}) => {
  const theme = useTheme();
  //check on IE browser to replace srcset with custom image transformation
  const isIE = useIsIE();

  return (
    <Wrapper className={className} isDark={isDark}>
      {imageUrl && (
        <StyledLazyLoadImage
          path={imageUrl}
          alt={title}
          srcset={srcset}
          transformation={isIE ? transformation : null}
          sizes={sizes}
          data-object-fit="cover"
          wrapperCss={lazyLoadImageWrapperCss(isDark)}
        />
      )}
      <Bottom>
        <Title variant="h3">{title}</Title>
        <JobTitle>{jobTitle}</JobTitle>
        {link && (
          <LinkedInLink href={link} target="_blank" rel="noopener noreferrer">
            <StyledLinkedInIcon color={theme.palette.common.white} />
            {linkTitle}
          </LinkedInLink>
        )}
      </Bottom>
    </Wrapper>
  );
};

export default TeamMember;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDark',
})`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: ${({ isDark, theme }) =>
    isDark ? theme.palette.common.blueDark : theme.palette.common.blackSecondary};
  overflow: hidden;
`;
const lazyLoadImageWrapperCss =
  (isDark) =>
  ({ theme }) =>
    css`
      position: relative;
      height: 0;
      flex-grow: 1;
      margin-bottom: -${SMALL_MARGIN_PX};
      background: linear-gradient(
        24.01deg,
        ${alpha(theme.palette.common.blue, 0.33)} 27.74%,
        ${alpha(theme.palette.common.violetAnalyticsCard, 0.33)} 83.06%
      );
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background: linear-gradient(
          0deg,
          ${isDark ? theme.palette.common.blueDark : theme.palette.common.blackSecondary} 0%,
          ${alpha(
              isDark ? theme.palette.common.blueDark : theme.palette.common.blackSecondary,
              0.9,
            )}
            17.24%,
          ${alpha(isDark ? theme.palette.common.blueDark : theme.palette.common.blackSecondary, 0)}
            100%
        );
      }
    `;
const StyledLazyLoadImage = styled(LazyLoadImage)`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
const Bottom = styled('div')`
  position: relative;
  width: 100%;
  margin-top: auto;
  padding: 0 12px 12px;
  box-sizing: border-box;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0 ${SMALL_MARGIN_PX} ${SMALL_MARGIN_PX};
  }
`;
const Title = styled(H3)`
  && {
    margin-bottom: ${TINY_MARGIN_PX};
  }
`;
const JobTitle = styled(MetaText)`
  color: ${({ theme }) => theme.palette.common.gray};
  font-weight: bold;
`;
const LinkedInLink = styled('a')`
  min-height: 24px;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.common.gray};
  margin-top: ${TINY_MARGIN_PX};
  display: flex;
  align-items: center;
`;
const StyledLinkedInIcon = styled(LinkedInIcon)`
  margin-right: 6px;
  width: 16px;
`;
