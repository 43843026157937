import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Box, Container } from '@mui/material';
import { alpha } from '@mui/material/styles';
import cn from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper/types/swiper-options';

import AnimatedSkeleton from '~/components/AnimatedSkeleton';
import Pathway from '~/components/Pathway/Pathway';
import { BodyText, H2 } from '~/components/UI/Texts';
import Video from '~/components/Video/Video';
import { TenantCtx } from '~/context/TenantProvider';
import useIsSsku from '~/hooks/tenant/useIsSsku';
import useEventListener from '~/hooks/useEventListener';
import useRestrictedContainer from '~/hooks/useRestrictedContainer';
import useWatchList from '~/hooks/useWatchList';
import { getExpertLink, getVideoLink } from '~/routes';
import styled from '~/styled';
import { BREAKPOINTS_VALUES, MIDDLE_MARGIN_PX, SMALL_MARGIN_PX, useIsWidthUp } from '~/theme';
import {
  Background,
  Orientation,
  Tenant,
  Video as VideoType,
  Pathway as PathwayType,
} from '~/types';
import { splitIntoChunks } from '~/utils/arrayUtils/splitIntoChunks';
import { secondsToHHHmmm } from '~/utils/dateUtils/secondsToHHHmmm';
import { secondsToHms } from '~/utils/dateUtils/secondsToHms';
import { toBoolean } from '~/utils/toBoolean';

type SliderBreakpoint = {
  slidesPerView?: number | 'auto';
  slidesOffsetAfter?: number;
};

const getDefaultBreakpoints = (
  isPortraitOrientation: boolean,
  withChunksOnMobile: boolean,
): { [p: string]: SliderBreakpoint } => ({
  [BREAKPOINTS_VALUES.xl]: {
    slidesPerView: isPortraitOrientation ? 6 : 5.2,
  },
  [BREAKPOINTS_VALUES.lg]: {
    slidesPerView: isPortraitOrientation ? 5 : 4.2,
  },
  [BREAKPOINTS_VALUES.md]: {
    slidesPerView: isPortraitOrientation ? 4 : 3.2,
  },
  [BREAKPOINTS_VALUES.sm]: {
    slidesPerView: isPortraitOrientation ? 3 : 2.2,
    slidesOffsetAfter: isPortraitOrientation ? 0 : 16,
  },
  [BREAKPOINTS_VALUES.xs]: {
    slidesPerView: isPortraitOrientation ? 2.5 : withChunksOnMobile ? 1 : 1.2,
    slidesOffsetAfter: isPortraitOrientation || withChunksOnMobile ? 0 : 16,
  },
});

const getDefaultBreakpointsForIndexedVideos = (): { [p: string]: SliderBreakpoint } => ({
  [BREAKPOINTS_VALUES.xl]: {
    slidesPerView: 'auto',
  },
  [BREAKPOINTS_VALUES.lg]: {
    slidesPerView: 2.4,
  },
  [BREAKPOINTS_VALUES.md]: {
    slidesPerView: 2.1,
  },
  [BREAKPOINTS_VALUES.sm]: {
    slidesPerView: 1.8,
  },
  [BREAKPOINTS_VALUES.xs]: {
    slidesPerView: 1,
  },
});

enum VideoItem {
  Pathway = 'pathway',
  Video = 'video',
}

export const formatPathwayForVideoBlock = (item: PathwayType) => ({
  type: VideoItem.Pathway,
  pathway: item,
});
export const formatVideoForVideoBlock = (item: VideoType) => ({
  type: VideoItem.Video,
  video: {
    ...item,
    link: getVideoLink(item),
  },
});

export type VideoBlockItem =
  | ReturnType<typeof formatVideoForVideoBlock>
  | ReturnType<typeof formatPathwayForVideoBlock>;

interface VideosBlockProps {
  data: {
    background: Background;
    orientation: Orientation;
    heading?: string;
    description?: string;
    items: VideoBlockItem[];
  };
  tenant?: Tenant | null;
  getBreakpoints?: (isPortraitOrientation: boolean) => { [p: string]: SliderBreakpoint };
  handleClick?: (id: string) => void;
  withArrows?: boolean;
  className?: string;
  titleToLeft?: boolean;
  loading?: boolean;
  wrapperId?: string;
  withChunksOnMobile?: boolean;
  withIndexes?: boolean;
}

const loadingItems = new Array(6).fill(undefined);

const PathwayDataProvider = ({ data, videoWidth, background }) => (
  <Pathway
    videoWidth={videoWidth}
    id={data?.pathway?.id}
    name={data?.pathway?.name}
    description={data?.pathway?.description}
    imageUrl={data?.pathway?.image_url}
    duration={secondsToHHHmmm(data?.pathway?.duration)}
    countVideos={data?.pathway?.count_videos}
    slug={data?.pathway?.slug}
    background={background}
    progress={data?.pathway?.progress}
  />
);

const VideoDataProvider = ({
  data,
  defaultLink,
  orientation,
  videoWidth,
  background,
  isInWatchlist,
  ...rest
}) => {
  const { tenant } = useContext(TenantCtx);
  return (
    <Video
      id={data?.video?.id}
      title={data?.video?.title}
      duration={secondsToHms(data?.video?.duration)}
      userName={data?.video?.user?.name}
      categories={data?.video?.categories}
      difficulty={data?.video?.difficulty}
      previewUrl={data?.video?.preview_url}
      description={data?.video?.description}
      orientation={orientation}
      videoWidth={videoWidth}
      background={background}
      progress={data?.video?.progress}
      handleClick={data?.video?.handleClick}
      durationNumb={data?.video?.duration}
      link={data?.video?.link || defaultLink}
      expertLink={getExpertLink(data?.video)}
      verseId={data?.video?.verse_video_id}
      bumperUrl={data?.video?.show_bumper ? tenant?.video_bumper_url : null}
      isInWatchlist={isInWatchlist}
      shouldOpenInModal={!!data?.show_on_popups}
      {...rest}
    />
  );
};

const VideosBlock: React.FC<VideosBlockProps> = ({
  data,
  withIndexes,
  getBreakpoints = getDefaultBreakpoints,
  className,
  withArrows = true,
  titleToLeft = false,
  loading,
  wrapperId,
  withChunksOnMobile = false,
}) => {
  const { background, orientation, heading, description } = data;
  const RestrictedContainer = useRestrictedContainer();
  const isMobile = !useIsWidthUp('sm');
  const isSsku = useIsSsku();

  const videosIds = data?.items
    ?.filter(
      (item): item is ReturnType<typeof formatVideoForVideoBlock> =>
        item.type !== VideoItem.Pathway,
    )
    ?.map((item) => item.video.id)
    .filter(toBoolean<string>);

  const chunks = useMemo(() => {
    return isMobile && withChunksOnMobile ? splitIntoChunks(data?.items, 2) : data?.items;
  }, [isMobile, withChunksOnMobile, data]);

  const watchlistById = useWatchList(videosIds);

  const isPortraitOrientation = orientation === 'Portrait';
  const [videoWidth, setVideoWidth] = useState<number | null>(null);
  const [hasMaxWidth, setHasMaxWidth] = useState(true);
  const videoWrapRef = useRef(null);
  const widthTimeout = useRef<null | ReturnType<typeof setTimeout>>(null);

  const setVideoWidthHandler = useCallback(() => {
    if (widthTimeout.current) {
      clearTimeout(widthTimeout.current);
    }

    //add timeout, because gets wrong value after resize
    widthTimeout.current = setTimeout(() => {
      // @ts-ignore
      const videoWidthValue = videoWrapRef?.current?.clientWidth;
      !withIndexes && setVideoWidth(videoWidthValue);
    }, 500);
  }, [withIndexes, videoWrapRef, widthTimeout]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHasMaxWidth(false);
    }, 0);
    setVideoWidthHandler();

    return () => {
      clearTimeout(timeout);
    };
  }, [setVideoWidthHandler]);

  useEffect(() => {
    return () => {
      widthTimeout?.current && clearTimeout(widthTimeout.current);
    };
  }, []);

  useEventListener('resize', setVideoWidthHandler);

  const videosOnDesktopCount = withIndexes ? 'auto' : isPortraitOrientation ? 2.5 : 1.2;

  const dynamicSlidesOffsetAfter = videoWidth ? videoWidth * 0.2 + 50 : 100;
  const breakpoints = !withIndexes
    ? getBreakpoints(isPortraitOrientation, withChunksOnMobile)
    : getDefaultBreakpointsForIndexedVideos();

  const swiperSettings: SwiperOptions = {
    slidesPerView: videosOnDesktopCount,
    spaceBetween: 16,
    pagination: {
      clickable: true,
    },
    mousewheel: {
      releaseOnEdges: true,
      forceToAxis: true,
      invert: true,
    },
    navigation: withArrows,
    observer: true,
    slidesOffsetAfter: isPortraitOrientation ? 0 : dynamicSlidesOffsetAfter,
    breakpoints,
  };

  if (loading) {
    return (
      <Wrapper
        id={wrapperId}
        background={background}
        isPortraitOrientation={isPortraitOrientation}
        className={cn(background === 'Dark' ? 'dark-block' : 'light-block', className)}
        withChunksOnMobile={withChunksOnMobile}
      >
        <StyledContainer
          className="landscape-container"
          maxWidth={isPortraitOrientation ? undefined : 'ml'}
        >
          {heading && (
            <StyledRestrictedContainer as={RestrictedContainer}>
              <LoadingHeading sm="35px" />
            </StyledRestrictedContainer>
          )}
          <StyledSwiper withIndexes={withIndexes} {...swiperSettings}>
            {loadingItems.map((item, index) => (
              <StyledSwiperSlide key={index}>
                {isMobile && withChunksOnMobile ? (
                  <ChunkWrapper>
                    <LoadingCard />
                    <LoadingCard />
                  </ChunkWrapper>
                ) : (
                  <SlideWrapper>
                    <LoadingCard />
                  </SlideWrapper>
                )}
              </StyledSwiperSlide>
            ))}
          </StyledSwiper>
        </StyledContainer>
      </Wrapper>
    );
  }

  if (!data?.items || data?.items?.length === 0) {
    return null;
  }

  const Info = (
    <>
      <Heading special={isSsku} variant="h2" component="h2" align={titleToLeft ? 'left' : 'center'}>
        {heading}
      </Heading>
      {description && (
        <Description align={titleToLeft ? 'left' : 'center'}>{description}</Description>
      )}
    </>
  );

  const SwiperWrapper = !withIndexes ? Fragment : RightAlignedWrapper;

  return (
    <Wrapper
      id={wrapperId}
      background={background}
      isPortraitOrientation={isPortraitOrientation}
      className={cn(background === 'Dark' ? 'dark-block' : 'light-block', className)}
      withChunksOnMobile={withChunksOnMobile}
      data-testid="videosBlock"
    >
      <StyledContainer
        className="landscape-container"
        maxWidth={isPortraitOrientation ? undefined : 'ml'}
      >
        {(heading || description) &&
          (withIndexes ? (
            <Container>{Info}</Container>
          ) : (
            <StyledRestrictedContainer as={RestrictedContainer}>{Info}</StyledRestrictedContainer>
          ))}
        <SwiperWrapper>
          <StyledSwiper withIndexes={withIndexes} {...swiperSettings}>
            {chunks &&
              chunks.map((item, index) => {
                if (isMobile && withChunksOnMobile) {
                  return (
                    <StyledSwiperSlide
                      key={index}
                      isPortraitOrientation={isPortraitOrientation}
                      videoWidth={videoWidth}
                      hasMaxWidth={hasMaxWidth}
                    >
                      <ChunkWrapper>
                        {item &&
                          item.map((chunkItem, index) => {
                            const type = chunkItem?.type;
                            if (type === VideoItem.Pathway) {
                              return (
                                <PathwayDataProvider
                                  key={index}
                                  data={chunkItem}
                                  videoWidth={videoWidth}
                                  background={background}
                                />
                              );
                            }

                            return (
                              <VideoDataProvider
                                key={index}
                                data={chunkItem}
                                defaultLink={getVideoLink(item.video)}
                                isInWatchlist={watchlistById[chunkItem?.video?.id]}
                                videoWidth={videoWidth}
                                background={background}
                                orientation={orientation}
                              />
                            );
                          })}
                      </ChunkWrapper>
                    </StyledSwiperSlide>
                  );
                }

                const type = item?.type;
                if (type === VideoItem.Pathway) {
                  return (
                    <StyledSwiperSlide
                      key={index}
                      className="pathwaySlide"
                      isPortraitOrientation={isPortraitOrientation}
                      videoWidth={videoWidth}
                      hasMaxWidth={hasMaxWidth}
                    >
                      <SlideWrapper ref={videoWrapRef}>
                        <PathwayDataProvider
                          data={item}
                          videoWidth={videoWidth}
                          background={background}
                        />
                      </SlideWrapper>
                    </StyledSwiperSlide>
                  );
                }

                if (withIndexes) {
                  return (
                    <StyledSwiperSlide key={index} hasMaxWidth={hasMaxWidth}>
                      <SlideWrapperRow ref={videoWrapRef}>
                        {withIndexes && (
                          <IndexWrapper>
                            <VideoIndex>{index + 1}</VideoIndex>
                          </IndexWrapper>
                        )}
                        <IndexedVideoDataProvider
                          videoWidth={videoWidth}
                          background={background}
                          orientation={orientation}
                          data={item}
                          defaultLink={getVideoLink(item.video)}
                          isInWatchlist={watchlistById[item?.video?.id]}
                        />
                      </SlideWrapperRow>
                    </StyledSwiperSlide>
                  );
                }

                return (
                  <StyledSwiperSlide
                    key={index}
                    isPortraitOrientation={isPortraitOrientation}
                    videoWidth={videoWidth}
                    hasMaxWidth={hasMaxWidth}
                  >
                    <SlideWrapper ref={videoWrapRef}>
                      <VideoDataProvider
                        videoWidth={videoWidth}
                        background={background}
                        orientation={orientation}
                        data={item}
                        defaultLink={getVideoLink(item.video)}
                        isInWatchlist={watchlistById[item?.video?.id]}
                      />
                    </SlideWrapper>
                  </StyledSwiperSlide>
                );
              })}
          </StyledSwiper>
        </SwiperWrapper>
      </StyledContainer>
    </Wrapper>
  );
};

export default VideosBlock;

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isPortraitOrientation' && prop !== 'background' && prop !== 'withChunksOnMobile',
})`
  padding-top: 36px;
  padding-bottom: 36px;
  background: ${({ theme }) => theme.palette.common.blockBackground.main};

  &:first-child {
    margin-top: 76px;
  }

  & .swiper::after {
    content: '';
    display: ${({ withChunksOnMobile }) => (withChunksOnMobile ? 'none' : 'block')};
    position: absolute;
    ${({ isPortraitOrientation }) => (isPortraitOrientation ? 'top: 0' : 'bottom: 0')};
    right: -1px;
    width: 50px;
    height: ${({ isPortraitOrientation }) => (isPortraitOrientation ? '225px' : '100%')};
    background: ${({ background, theme }) =>
      background === 'Dark'
        ? ` transparent linear-gradient(-90deg, ${
            theme.palette.common.blockBackground.main
          } 0%, ${alpha(
            theme.palette.common.blockBackground.main,
            0,
          )} 100%) 0% 0% no-repeat padding-box`
        : ` transparent linear-gradient(-90deg, ${
            theme.palette.common.blockBackground.light
          } 0%, ${alpha(
            theme.palette.common.blockBackground.light,
            0,
          )} 100%) 0% 0% no-repeat padding-box`};
    z-index: 3;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-top: 72px;
    padding-bottom: 72px;
    background: ${({ background, theme }) =>
      background === 'Dark'
        ? theme.palette.common.blockBackground.main
        : theme.palette.common.blockBackground.light};

    &:first-child {
      margin-top: 120px;
    }

    & .swiper::after {
      display: block;
      height: 100%;
      opacity: ${({ isPortraitOrientation }) => (isPortraitOrientation ? 0 : 1)};
    }
  }
`;
const StyledContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'isPortraitOrientation',
})`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-right: ${({ isPortraitOrientation }) => (isPortraitOrientation ? 16 : 0)}px;
  }

  @media (min-width: 2800px) {
    max-width: 2800px;
  }
`;
const LoadingCard = styled(AnimatedSkeleton)`
  height: 260px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 100%;
  }
`;
const LoadingHeading = styled(AnimatedSkeleton)`
  max-width: 450px;
  margin-bottom: ${MIDDLE_MARGIN_PX};
  margin-right: auto;
`;

const ChunkWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 496px;

  & > *:first-child {
    margin-bottom: ${SMALL_MARGIN_PX};
  }
`;
const Description = styled(BodyText)`
  margin-bottom: ${MIDDLE_MARGIN_PX};

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: ${({ align }) => (align === 'center' ? '70%' : '100%')};
    margin: ${({ align }) => (align === 'center' ? '0 auto' : '0')};
    margin-bottom: ${MIDDLE_MARGIN_PX};
  }
`;

const StyledRestrictedContainer = styled('div')`
  & {
    padding: 0 16px 0 0;
  }
`;
const Heading = styled(H2, {
  shouldForwardProp: (prop) => prop !== 'additionalStyles',
})`
  && {
    margin: auto;
    margin-bottom: 22px;

    &:first-letter {
      text-transform: uppercase;
    }

    ${({ theme }) => theme.breakpoints.up('sm')} {
      margin-bottom: ${MIDDLE_MARGIN_PX};
    }

    ${({ theme }) => theme.breakpoints.up('ml')} {
      font-size: 40px;
    }
  }
`;
const SlideWrapper = styled('div')`
  height: 100%;
`;
const SlideWrapperRow = styled(SlideWrapper)`
  display: inline-flex;
`;
const StyledSwiper = styled(Swiper, {
  shouldForwardProp: (prop) => prop !== 'withIndexes',
})<{ withIndexes?: boolean }>`
  & .swiper-pagination {
    max-width: ${({ withIndexes }) => withIndexes && '1200px'};
  }
`;
const StyledSwiperSlide = styled(SwiperSlide, {
  shouldForwardProp: (prop) =>
    prop !== 'isPortraitOrientation' && prop !== 'videoWidth' && prop !== 'hasMaxWidth',
})`
  && {
    width: unset;
    transition: ${({ hasMaxWidth }) => (hasMaxWidth ? 'none' : 'height .3s, width .3s')};
    max-width: ${({ hasMaxWidth }) => (hasMaxWidth ? '20%' : 'none')};
    margin-right: ${({ hasMaxWidth }) => (hasMaxWidth ? '2%' : 'none')};

    &.pathwaySlide {
      padding: 20px 0 0 0;

      & > div {
        margin-right: 24px;
      }
    }

    ${({ theme }) => theme.breakpoints.up('md')} {
      height: 316px;

      &:hover {
        height: ${({ isPortraitOrientation }) => !isPortraitOrientation && '100%'};
        width: ${({ isPortraitOrientation, videoWidth }) =>
          !isPortraitOrientation && videoWidth && `${videoWidth + videoWidth * 0.2}px !important`};
      }
    }
  }
`;
const RightAlignedWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'as',
})`
  ${({ theme }) => theme.breakpoints.up('xl')} {
    margin-left: calc((100vw - 1200px) / 2);
  }

  ${({ theme }) => theme.breakpoints.up('ml')} {
    margin-left: calc((2200px - 1200px) / 2);
  }

  @media (min-width: 2800px) {
    margin-left: calc((2800px - 1200px) / 2);
  }
`;
const IndexWrapper = styled('div')`
  position: relative;
  z-index: 2;
  display: flex;
  height: 100%;
  margin-right: -40px;

  ${({ theme }) => theme.breakpoints.up('xl')} {
    z-index: 0;
    margin-right: -50px;
    height: 316px;
    margin-top: auto;
  }
`;
const VideoIndex = styled('span')`
  display: inline-block;
  height: 100%;
  font-size: ${({ theme }) => (theme.isSsku ? 170 : 230)}px;
  line-height: ${({ theme }) => (theme.isSsku ? 1 : 0.48)};
  font-weight: 700;
  color: ${({ theme }) => theme.palette.common.blockBackground.accentText};
  font-family: ${({ theme }) => theme.typography.specialFontFamily};

  ${({ theme }) => theme.breakpoints.up('xl')} {
    font-size: ${({ theme }) => (theme.isSsku ? 390 : 470)}px;
    line-height: ${({ theme }) => (theme.isSsku ? 0.8 : 0.48)};
  }
`;
const IndexedVideoDataProvider = styled(VideoDataProvider)`
  flex-grow: 1;
  margin-top: auto;

  ${({ theme }) => theme.breakpoints.up('xl')} {
    min-width: 407px;
    width: 0;
  }
`;
