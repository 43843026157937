import { compareAsc } from 'date-fns';

import { Maybe, NotificationSettings, Pathway, Scalars, Team, Video } from '~/types';

export enum VIEW_TYPES {
  TYPE_SELECT = 'type_select',
  FORM = 'form',
  REVIEW = 'review',
  LIST = 'list',
  DETAILS = 'details',
  USERS_DETAILS = 'users_details',
}

export type UserStatus = {
  userId?: Maybe<Scalars['String']>;
  userName?: Scalars['String'];
  status?: Scalars['String'];
  status_date?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['Int']>;
  test_percent_complete?: Maybe<Scalars['Float']>;
  quiz_percentage_complete?: Maybe<Scalars['Int']>;
  stars?: Maybe<Array<Maybe<Scalars['String']>>>;
  progress: number;
  isCpdLearning?: boolean;
};

export const checkIsNotificationOff = (notificationStatus) => {
  return Boolean(!notificationStatus || notificationStatus === NotificationSettings.Off);
};

export enum ASSIGNMENT_STATUSES {
  ASSIGNED = 'Assigned',
  ACKNOWLEDGED = 'Acknowledged',
  STARTED = 'Started',
  WATCHED = 'Watched',
  COMPLETED = 'Completed',
}

export const ASL_ROWS_PER_PAGE = 'ASL_ROWS_PER_PAGE';
export type SelectedEntity = {
  type: Scalars['String'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  videos_count?: Scalars['Int'];
  stars?: string[];
};
export type ItemType = Video | Pathway;

export interface FormFields {
  title: string;
  description: string;
  startDate?: null | Date;
  dueDate: null | Date;
  credits?: null | number;
  teams: Team[];
  itemsList?: (null | ItemType)[];
}

export const RELEASE_3_85_0_DATE = '2023-06-29 07:00:00';
export const beforeDate = (currDate, releaseDate) => compareAsc(currDate, releaseDate) < 0;

export const ASL_LEARNER_PAGE_EVENTS = {
  aslLearnerFilter: 'ASL_Learner_Filter',
  aslLearnerFilterAll: 'ASL_Learner_Filter_All',
  aslLearnerFilterNotStarted: 'ASL_Learner_Filter_notstarted',
  aslLearnerFilterInProgress: 'ASL_Learner_Filter_Inprogress',
  aslLearnerFilterComplete: 'ASL_Learner_Filter_Complete',
  certShare: 'ASL_Learner_Cert_Share',
  certShareLi: 'ASL_Learner_Cert_LI',
  certShareTeams: 'ASL_Learner_Cert_Teams',
  certShareFb: 'ASL_Learner_Cert_fbook',
  certShareX: 'ASL_Learner_Cert_X',
  aslLearnerCertDownload: 'ASL_Learner_Cert_dload',
};

export const ASL_MANAGER_PAGE_EVENTS = {
  aslNewAssignment: 'ASL_Manager_NewASL',
  newAslHoursType: 'ASL_Manager_NewASL_hrs',
  newAslVideosType: 'ASL_Manager_NewASL_vid',
  newAslPathwaysType: 'ASL_Manager_NewASL_pway',
  newAslCpdType: 'ASL_Manager_NewASL_cpd',
  aslNotificationsOn: 'ASL_Manager_Not_on',
  aslNotificationsOff: 'ASL_Manager_Not_off',
  aslNotificationsAuto: 'ASL_Manager_Not_auto',
  aslNotificationsMonth: 'ASL_Manager_Not_Month',
  aslNotificationsWeek: 'ASL_Manager_Not_Week',
  aslVideosTestOn: 'ASL_Manager_Test',
  aslAssign: 'ASL_Manager_SetASL',
  aslByTeamDownload: 'ASL_Manager_Data_team_dload',
  aslByTeamViewMore: 'ASL_Manager_Data_team_vmore',
  aslByAssignmentDownload: 'ASL_Manager_Data_asl_dload',
  aslByAssignmentViewMore: 'ASL_Manager_Data_asl_vmore',
};
