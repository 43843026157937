import React from 'react';

import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { alpha, Theme } from '@mui/material/styles';
import cx from 'classnames';
import parse from 'html-react-parser';

import FacebookIcon from '~/assets/icons/FacebookIcon';
import InstagramIcon from '~/assets/icons/InstagramIcon';
import LinkedInIcon from '~/assets/icons/LinkedInIcon';
import TwitterIcon from '~/assets/icons/TwitterIcon';
import IKImage from '~/components/IKImage/IKImage';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import styled from '~/styled';
import { BREAKPOINTS_VALUES, MIDDLE_MARGIN_PX, SMALL_MARGIN, SMALL_MARGIN_PX } from '~/theme';

enum SocialItemTypes {
  Telephone = 'telephone',
  Email = 'email',
  SocialMedia = 'socialMedia',
}

const ContactDetailsBlock = ({ data: blockData, tenant }) => {
  const background = blockData?.background;
  const items = blockData?.items;
  const theme = useTheme();
  const { t } = useTenantTranslation();

  const links = {
    instagram_link: { label: t('page.video.instagram'), icon: StyledInstagram },
    linkedin_link: { label: t('page.video.linkedIn'), icon: StyledLinkedin },
    twitter_link: { label: t('page.video.twitter'), icon: StyledTwitter },
    facebook_link: { label: t('page.video.facebook'), icon: StyledFacebook },
  };

  return (
    <Wrapper
      className={cx(background === 'Dark' ? 'dark-block' : 'light-block', 'contact-details-block')}
      background={background}
    >
      <StyledContainer>
        <DetailsBox>
          {items?.map((item) => {
            const heading = item?.heading;
            const icon = item?.icon_url;
            const content = item?.editable_html || '';
            const typeContent = item?.type_content;

            return (
              <DetailBox key={heading} className="details-block">
                <DetailBoxImage>
                  <IKImage
                    path={icon}
                    alt={heading}
                    draggable={false}
                    srcset={[{ width: 50 }, { width: 72 }]}
                    sizes={`(max-width:${BREAKPOINTS_VALUES['sm']}px) 50px, 72px`}
                  />
                </DetailBoxImage>
                <DetailBoxContent>
                  <DetailBoxTitle
                    variant="h4"
                    component="h4"
                    align="left"
                    className="details-title"
                  >
                    {heading}
                  </DetailBoxTitle>
                  {typeContent === SocialItemTypes.SocialMedia ? (
                    <Links>
                      {Object.entries(links).map(([key, value]) => {
                        const link = tenant?.[key];
                        const IconComponent = value.icon;
                        return (
                          Boolean(link) && (
                            <SocialLink
                              key={key}
                              href={link}
                              target="_blank"
                              rel="noopener noreferrer"
                              aria-label={value.label}
                            >
                              <IconComponent color={alpha(theme.palette.common.white, 0.33)} />
                            </SocialLink>
                          )
                        );
                      })}
                    </Links>
                  ) : (
                    <DetailBoxDescription>{parse(content)}</DetailBoxDescription>
                  )}
                </DetailBoxContent>
              </DetailBox>
            );
          })}
        </DetailsBox>
      </StyledContainer>
    </Wrapper>
  );
};

export default ContactDetailsBlock;

const styleIcon = (icon) =>
  styled(icon)(({ theme }: { theme: Theme }) => ({
    color: alpha(theme.palette.common.white, 0.33),
    width: 24,
  }));
const Links = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: SMALL_MARGIN,
});
const StyledInstagram = styleIcon(InstagramIcon);
const StyledFacebook = styleIcon(FacebookIcon);
const StyledTwitter = styleIcon(TwitterIcon);
const StyledLinkedin = styleIcon(LinkedInIcon);
const SocialLink = styled('a')({
  '&:not(:last-child)': {
    marginRight: SMALL_MARGIN,
  },
});

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'background',
})`
  padding-top: ${MIDDLE_MARGIN_PX};
  padding-bottom: ${MIDDLE_MARGIN_PX};
  background: ${({ theme }) => theme.palette.secondary.dark};
  overflow-x: hidden;

  &:first-child {
    margin-top: 76px;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-top: 72px;
    padding-bottom: 72px;
    background: ${({ background, theme }) =>
      background === 'Dark' ? theme.palette.secondary.dark : theme.palette.secondary.light};

    &:first-child {
      margin-top: 120px;
    }
  }
`;
const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const DetailsBox = styled(Box)`
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: calc(100% + ${SMALL_MARGIN}px);
    margin-left: -${SMALL_MARGIN / 2}px;
    margin-right: -${SMALL_MARGIN / 2}px;
    display: flex;
  }
`;
const DetailBox = styled(Box)`
  width: 100%;
  margin-bottom: ${MIDDLE_MARGIN_PX};
  padding: 0 ${SMALL_MARGIN_PX};
  box-sizing: border-box;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:not(:last-child) {
    ${({ theme }) => theme.breakpoints.up('md')} {
      border-right: 1px solid ${({ theme }) => alpha(theme.palette.common.white, 0.33)};
    }
  }
`;
const DetailBoxImage = styled(Box)`
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 0 auto 8px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 64px;
    height: 64px;
  }

  & img {
    max-width: 100%;
    max-height: 100%;
  }
`;
const DetailBoxContent = styled(Box)`
  flex: 1;
  margin-top: 0;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-top: ${SMALL_MARGIN_PX};
  }
`;
const DetailBoxTitle = styled(Typography)`
  margin-bottom: 4px;
  font-size: 24px;
  line-height: 22px;
  text-align: center;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-bottom: 4px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 11px;
    font-size: 28px;
    line-height: 31px;
  }
`;
const DetailBoxDescription = styled(Box)`
  font-size: 16px;
  line-height: 1.5;
  color: ${({ theme }) => theme.palette.common.gray};
  word-break: break-word;
  text-align: center;

  & p,
  & strong {
    margin: 0;
    font-size: inherit;
    line-height: inherit;
  }

  & a {
    color: ${({ theme }) => theme.palette.common.blue};
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 18px;
    line-height: 1.5;
    color: ${({ theme }) => theme.palette.common.gray};

    & p,
    & strong {
      margin: 0;
      font-size: inherit;
      line-height: inherit;
    }
  }
`;
