import React, { ReactNode, useContext } from 'react';

import { Helmet } from 'react-helmet';

import { TenantCtx } from '~/context/TenantProvider';
import { generateImageKitUrl } from '~/modules/imageKit';
import { TenantsRefs, TenantsUrlsByNames } from '~/utils/tenantsConfig';
import { isAbsoluteUrl } from '~/utils/urlUtils/isAbsoluteUrl';

interface SeoBlockProps {
  data?: any;
  canonicalUrl?: string;
  children?: ReactNode;
}

const SeoBlock: React.FC<SeoBlockProps> = ({ data, children, canonicalUrl }) => {
  const seoTitle = data?.seo_title;
  const seoDescription = data?.seo_description;
  const seoImage = data?.share_image;
  const seoAuthor = data?.seo_author;
  const publishDate = data?.publish_date;
  const url = data?.url;
  const robotsTag = data?.robots_tag;

  const { tenant } = useContext(TenantCtx);
  const imageKitUrl = generateImageKitUrl({
    path: seoImage,
    transformation: undefined,
  });

  const isAbsoluteCanonicalUrl = isAbsoluteUrl(canonicalUrl);

  return (
    <Helmet>
      <title>{seoTitle}</title>
      <meta name="description" content={seoDescription} />
      {!canonicalUrl && url && <link rel="canonical" href={url} />}
      {canonicalUrl && (
        <link
          rel="canonical"
          href={
            isAbsoluteCanonicalUrl
              ? canonicalUrl
              : `${TenantsUrlsByNames[tenant?.name || TenantsRefs.Fu]}${canonicalUrl}`
          }
        />
      )}
      {url && <meta property="og:url" content={url} />}
      <meta property="og:title" content={seoTitle} />
      <meta property="og:description" content={seoDescription} />
      {seoImage && <meta property="og:image" content={imageKitUrl} />}
      {seoImage && <meta property="og:image:secure_url" content={imageKitUrl} />}
      {seoImage && <meta name="twitter:image" content={imageKitUrl} />}
      <meta name="twitter:description" content={seoDescription} />
      <meta name="twitter:title" content={seoTitle} />
      {seoAuthor && <meta property="article:author" content={seoAuthor} />}
      {seoAuthor && <meta property="og:article:author" content={seoAuthor} />}
      {publishDate && <meta name="publish_date" property="og:publish_date" content={publishDate} />}
      {publishDate && <meta property="og:article:published_time" content={publishDate} />}
      {robotsTag && <meta name="robots" content={robotsTag} />}
      {children}
    </Helmet>
  );
};

export default SeoBlock;
