import React from 'react';

import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import CategoryLabel from '~/components/UI/CategoryLabel/CategoryLabel';
import ProgressLine from '~/components/UI/ProgressLine/ProgressLine';
import ZoomedBackground, { createCustomDimensionsImgWrapper } from '~/components/ZoomedBackground';
import { DEFAULT_DISCOVER_URL } from '~/const';
import useIsSsku from '~/hooks/tenant/useIsSsku';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import styled, { css } from '~/styled';
import { BREAKPOINTS_VALUES, getDefaultTransition } from '~/theme';

interface PathwayInterface {
  videoWidth: number | null;
  background?: string | null;
  handleClick?: (id: string) => void;
  id?: string | null;
  name?: string | null;
  description?: string | null;
  imageUrl?: string | null;
  duration?: string | null;
  countVideos?: number | null;
  slug?: string | null;
  progress?: number;
  className?: string;
  withHover?: boolean;
}

const Pathway: React.FC<PathwayInterface> = ({
  videoWidth,
  background,
  name,
  imageUrl,
  description,
  duration,
  countVideos,
  slug,
  progress,
  className,
  withHover = true,
}) => {
  const theme = useTheme();
  const { t } = useTenantTranslation();
  const isSsku = useIsSsku();
  const link = `${DEFAULT_DISCOVER_URL}/pathways/${slug}`;

  return (
    <PathwayWrap
      data-testid="pathwayCard"
      className={className}
      background={background}
      withHover={withHover}
    >
      <Wrap
        path={imageUrl}
        imgWrapperCss={createCustomDimensionsImgWrapper({ height: '77%' })}
        srcset={[{ width: 420 }, { width: 370 }, { width: 350 }, { width: 340 }]}
        sizes={`(max-width:${BREAKPOINTS_VALUES['sm']}px) 420px, (max-width: ${BREAKPOINTS_VALUES['md']}) 370px, (max-width:${BREAKPOINTS_VALUES['lg']}px) 350px, 340px`}
      >
        {slug ? (
          <Link data-testid="imageWrapperLink" to={link}>
            <ImgWrap
              className="imgWrap"
              withHover={withHover}
              withLink={slug}
              background={background}
            />
          </Link>
        ) : (
          <ImgWrap
            className="imgWrap"
            withHover={withHover}
            withLink={slug}
            background={background}
          />
        )}
        <CatDifWrap>
          <CategoryLabel
            name={t('button.pathway')}
            bgColor={theme.palette.common.category.pathway}
            textColor={theme.palette.common.category.color}
          />
        </CatDifWrap>
        <TextWrap>
          {slug ? (
            <StyledLink data-testid="titleLink" to={link}>
              <Title
                variant="h3"
                component="h3"
                className="title"
                videoWidth={videoWidth}
                withHover={withHover}
                withLink={slug}
              >
                {name}
              </Title>

              {description && <Description className="description">{description}</Description>}
            </StyledLink>
          ) : (
            <>
              <Title
                variant="h3"
                component="h3"
                className={'title'}
                videoWidth={videoWidth}
                withHover={withHover}
                withLink={slug}
              >
                {name}
              </Title>

              {description && <Description className={'description'}>{description}</Description>}
            </>
          )}
          <Duration data-testid="duration">
            {t(`common.videoCount${isSsku ? '_ssku' : ''}`, { count: countVideos }) +
              ' • ' +
              duration}
          </Duration>
          {(progress || progress === 0) && <ProgressLine width={progress} />}
        </TextWrap>
      </Wrap>
    </PathwayWrap>
  );
};

export default Pathway;

const PathwayWrap = styled('div', {
  shouldForwardProp: (prop) => prop !== 'background' && prop !== 'withHover',
})`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 240px;
  border-radius: 5px;
  background: ${({ background, theme }) =>
    background === 'Dark'
      ? theme.palette.common.blockBackground.light
      : theme.palette.common.blockBackground.main};
  border-top: 2px solid
    ${({ theme, background }) =>
      background === 'Dark'
        ? theme.palette.common.card.pathwayBorderMain
        : theme.palette.common.card.pathwayBorderLight};
  border-right: 2px solid
    ${({ theme, background }) =>
      background === 'Dark'
        ? theme.palette.common.card.pathwayBorderMain
        : theme.palette.common.card.pathwayBorderLight};

  &::before,
  &::after {
    width: 100%;
    border: 2px solid
      ${({ theme, background }) =>
        background === 'Dark'
          ? theme.palette.common.card.pathwayBorderMain
          : theme.palette.common.card.pathwayBorderLight};
    height: 100%;
    content: '';
    display: block;
    z-index: -1;
    position: absolute;
    background: ${({ theme }) => theme.palette.common.blockBackground.light};
    border-radius: 5px;
  }

  &::before {
    top: -20px;
    left: 20px;
  }

  &::after {
    top: -10px;
    left: 10px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 100%;

    &:hover {
      & .imgWrap {
        &::after {
          height: ${({ withHover }) => withHover && '180px'};
        }
      }

      & .title {
        width: ${({ withHover }) => withHover && 'undefined'};
      }

      & .description {
        width: ${({ withHover }) => withHover && 'undefined'};
        font-size: ${({ withHover }) => withHover && '16px'};
        line-height: ${({ withHover }) => withHover && '22px'};
        margin-bottom: ${({ withHover }) => withHover && '9px'};
        overflow: ${({ withHover }) => withHover && 'hidden'};
        white-space: ${({ withHover }) => withHover && 'inherit'};
        text-overflow: ${({ withHover }) => withHover && 'ellipsis'};
        display: ${({ withHover }) => withHover && '-webkit-box'};
        -webkit-line-clamp: ${({ withHover }) => withHover && '3'};
        -webkit-box-orient: ${({ withHover }) => withHover && 'vertical'};
      }
    }
  }

  ${({ additionalStyles }) => additionalStyles}
`;
const Wrap = styled(ZoomedBackground)`
  border-radius: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
  transition: ${getDefaultTransition('background-color')};

  &:hover {
    background: ${({ theme }) => theme.palette.common.card.hover};

    a,
    a > div {
      &:before {
        opacity: 1;
      }
    }
  }
`;
const backgroundShadowCss = css`
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 133px;
  -webkit-background-origin: padding-box;
  -o-background-origin: padding-box;
  -moz-background-origin: padding-box;
`;
const ImgWrap = styled('div', {
  shouldForwardProp: (prop) => prop !== 'withHover' && prop !== 'withLink' && prop !== 'background',
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 77%;
  transition: none;
  cursor: ${({ withHover, withLink }) => (withHover || withLink ? 'pointer' : 'auto')};
  overflow: hidden;
  &::after {
    ${backgroundShadowCss};
    background: ${({ background, theme }) =>
      background === 'Dark'
        ? `linear-gradient(0deg, ${theme.palette.common.blockBackground.light} 0%, ${alpha(
            theme.palette.common.blockBackground.light,
            0.9,
          )} 17%, ${alpha(theme.palette.common.blockBackground.light, 0)} 100%) 0% 0% no-repeat`
        : `linear-gradient(0deg, ${theme.palette.common.blockBackground.main} 0%, ${alpha(
            theme.palette.common.blockBackground.main,
            0.9,
          )} 17%, ${alpha(theme.palette.common.blockBackground.main, 0)} 100%) 0% 0% no-repeat`};
  }
  &::before {
    ${backgroundShadowCss};
    transition: ${getDefaultTransition('opacity')};
    background: ${({ theme }) => theme.palette.common.card.hoverGradient};
    z-index: 1;
    opacity: 0;
  }
`;
const CatDifWrap = styled('div')`
  display: flex;
  align-items: center;
  padding: 0 24px;
  margin-bottom: 12px;
  z-index: 1;

  & > div:not(:last-child) {
    margin-right: 9px;
  }

  & > a {
    margin-right: 9px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0 32px;
  }
`;
const TextWrap = styled('div')`
  padding: 0 24px 24px 24px;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0 32px 32px 32px;
  }
`;
const StyledLink = styled(Link)`
  color: inherit;
`;
const Title = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'videoWidth' && prop !== 'withHover' && prop !== 'withLink',
})`
  && {
    width: ${({ videoWidth }) => videoWidth - 27 * 2}px;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    overflow: hidden;
    white-space: inherit;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 12px;
    cursor: ${({ withHover, withLink }) => (withHover || withLink ? 'pointer' : 'auto')};

    ${({ theme }) => theme.breakpoints.up('md')} {
      font-size: 20px;
      line-height: 28px;
    }
  }
`;
const Description = styled(Typography)`
  display: none;
`;
const Duration = styled(Typography)`
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.palette.common.text.meta};
  overflow: hidden;
  white-space: inherit;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 14px;
    line-height: 18px;
  }
`;
