import { useContext, useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { formatMegaMenuItem, LOGGED_IN_ITEMS } from '~/blocks/Header/const';
import { UserInfoCtx } from '~/context/UserInfoProvider';
import { GET_HAS_NEWS } from '~/graphql/local';
import useIsSsku from '~/hooks/tenant/useIsSsku';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import { USER_ROLES } from '~/pages/const';
import { NavigationItem, Query, Video, VideoCategory } from '~/types';
import { replaceByIndex } from '~/utils/arrayUtils/replaceByIndex';
import { translate } from '~/utils/translate';
import { getCourseUrl } from '~/utils/urlUtils/getCourseUrl';

export type CourseDropdownItem = {
  id?: string;
  name?: string | null;
  navigationDescription?: string | null;
  slug?: string;
  isStarted: boolean;
};

const useMenuItems = ({
  navigationItems,
  dropdownCourses,
  assignedLearningsName,
  sortedCategories,
  twoLatestVideos,
}: {
  navigationItems: NavigationItem[];
  dropdownCourses: CourseDropdownItem[] | undefined;
  assignedLearningsName?: string;
  sortedCategories: (VideoCategory | null)[];
  twoLatestVideos?: (Video | null)[] | null;
}) => {
  const { userInfo } = useContext(UserInfoCtx);
  const { t } = useTenantTranslation();
  const { data } = useQuery<Pick<Query, 'hasNews'>>(GET_HAS_NEWS);
  const hasNews = data?.hasNews;
  const isSsku = useIsSsku();

  const menuItems = useMemo(() => {
    if (userInfo) {
      const arr = LOGGED_IN_ITEMS.map((item) => {
        if (item.type === 'MegaMenu') {
          return formatMegaMenuItem({
            isSsku,
            megaMenu: item,
            t,
            hasNews,
            baseCategoriesList: sortedCategories,
            twoLatestVideos,
          });
        }

        return {
          ...item,
          title: translate(`header.${item.title}`),
        };
      });

      if (isSsku) {
        return arr;
      }

      // add asl url if needed
      if (
        (userInfo?.teams && userInfo.teams.length > 0) ||
        userInfo?.role === USER_ROLES.CompanyOwner ||
        userInfo?.role === USER_ROLES.CompanyManager
      ) {
        const assignedLearningTitle = assignedLearningsName
          ? assignedLearningsName
          : t('header.assigned');
        const url = userInfo?.is_manager ? '/assigned-learning/dashboard' : '/assigned-learning';

        arr.push({
          url,
          title: `${assignedLearningTitle} ${t('header.learning')}`,
          subItems: [],
          type: '',
        });
      }

      return arr;
    } else {
      const links = navigationItems?.filter(
        (item) => item.type === 'Link' || item.type === 'MegaMenu',
      );
      let loggedOutLinksData: NavigationItem[] = [];

      const indexOfCourseLink = links.findIndex((item) => item?.url === '/courses');

      //replace url to single course if only one course
      if (indexOfCourseLink !== -1 && dropdownCourses?.length === 1 && dropdownCourses[0]) {
        loggedOutLinksData = replaceByIndex(links, indexOfCourseLink, {
          ...loggedOutLinksData[indexOfCourseLink],
          url: getCourseUrl(dropdownCourses[0]?.slug) || '',
        });
      } else {
        loggedOutLinksData = links;
      }

      return loggedOutLinksData;
    }
  }, [
    navigationItems,
    userInfo,
    dropdownCourses,
    hasNews,
    t,
    sortedCategories,
    assignedLearningsName,
    twoLatestVideos,
    isSsku,
  ]);

  return menuItems;
};

export default useMenuItems;
