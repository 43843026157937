import React from 'react';

import { Typography, useTheme } from '@mui/material';
import { differenceInCalendarDays, differenceInSeconds, endOfDay, format } from 'date-fns';

import useIsSsku from '~/hooks/tenant/useIsSsku';
import useTenantTranslation from '~/hooks/useTenantTranslation';
import styled from '~/styled';
import { AssignmentForLearnerType } from '~/types';
import parseWithCheck from '~/utils/dateUtils/parseWithCheck';

interface UserLearningDueDateProps {
  dueDate?: string;
  completedDate?: string | null;
  completed?: boolean;
  entityNumber?: number | null;
  credits?: number | null;
  startDate?: string | null;
  className?: string;
  type?: AssignmentForLearnerType | null;
  percentageCompleted?: number;
}

const UserLearningDueDate: React.FC<UserLearningDueDateProps> = ({
  dueDate,
  completedDate,
  completed,
  credits,
  entityNumber,
  startDate,
  className,
  type,
  percentageCompleted,
}) => {
  const theme = useTheme();
  const isSsku = useIsSsku();

  const { t } = useTenantTranslation();
  const parsedDueDate = dueDate && parseWithCheck(dueDate, 'yyyy-LL-dd HH:mm:ss', new Date());
  const parsedCompletedDate =
    completedDate && parseWithCheck(completedDate, 'yyyy-LL-dd HH:mm:ss', new Date());
  const parsedStartDate = startDate && parseWithCheck(startDate, 'yyyy-LL-dd HH:mm:ss', new Date());
  const difference = parsedDueDate
    ? differenceInCalendarDays(endOfDay(parsedDueDate), new Date())
    : 0;
  const isFinished = parsedDueDate
    ? differenceInSeconds(endOfDay(parsedDueDate), new Date()) <= 0
    : true;

  const isCpd = type === AssignmentForLearnerType.Cpd;
  const isHours = type === AssignmentForLearnerType.Hours;
  const isPathway = type === AssignmentForLearnerType.Pathway;
  const isVideo = type === AssignmentForLearnerType.Video;

  return (
    <DueDate
      data-testid="aslSubtitle"
      className={className}
      color={
        theme.palette.common[
          completed ? 'green' : difference >= 0 ? 'violetSecondary' : 'failedError'
        ]
      }
    >
      {(Boolean(percentageCompleted) || percentageCompleted === 0) && (
        <>
          {percentageCompleted}%{' '}
          <LowercaseSpan>{t('page.assignedLearnings.completed')}</LowercaseSpan>
          {' • '}
        </>
      )}
      {completed ? (
        <>
          {credits && (
            <>
              {isCpd && t('page.assignedLearnings.cpdCredit', { count: credits })}
              {isHours && t('page.assignedLearnings.hourCredit', { count: credits })}
              {' • '}
            </>
          )}
          {!!entityNumber && (
            <>
              {isPathway && t(`common.pathway${isSsku ? '_ssku' : ''}`, { count: entityNumber })}
              {isVideo && t(`common.videoCount${isSsku ? '_ssku' : ''}`, { count: entityNumber })}
              {' • '}
            </>
          )}
          {t('page.assignedLearnings.complete')}:{' '}
          {parsedCompletedDate && format(parsedCompletedDate, 'dd/MM/yyyy')}
        </>
      ) : (
        <>
          {credits && (
            <>
              {isCpd && t('page.assignedLearnings.cpdCredit', { count: credits })}
              {isHours && t('page.assignedLearnings.hourCredit', { count: credits })}
              {' • '}
            </>
          )}
          {!!entityNumber && (
            <>
              {isPathway && t(`common.pathway${isSsku ? '_ssku' : ''}`, { count: entityNumber })}
              {isVideo && t(`common.videoCount${isSsku ? '_ssku' : ''}`, { count: entityNumber })}
              {' • '}
            </>
          )}
          {!!parsedStartDate && (
            <>
              {t('page.assignedLearnings.start')}{' '}
              {parsedStartDate && format(parsedStartDate, 'dd/MM/yyyy')}
              {' • '}
            </>
          )}
          {t('page.assignedLearnings.due')} {parsedDueDate && format(parsedDueDate, 'dd/MM/yyyy')}
          {!isFinished && (
            <>
              {' • '}
              <Bold>
                {t('page.assignedLearnings.dayRemaining', { count: Math.abs(difference) })}
              </Bold>
            </>
          )}
        </>
      )}
    </DueDate>
  );
};

export default UserLearningDueDate;

const DueDate = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'color',
})`
  color: ${({ color }) => color};
`;
const LowercaseSpan = styled('span')`
  text-transform: lowercase;
`;
const Bold = styled('span')`
  font-weight: bold;
`;
