import React, { FC } from 'react';

import { FormLabel, Radio } from '@mui/material';
import { alpha } from '@mui/material/styles';

import HintIconTooltipped from '~/components/HintIconTooltipped/HintIconTooltipped';
import styled, { css } from '~/styled';
import { SUBMIDDLE_MARGIN_PX } from '~/theme';

interface NotificationRadioItemProps {
  name: string;
  label: string;
  tooltip: string;
  className?: string;
  onChange: () => void;
  checked: boolean;
}

const NotificationRadioItem: FC<NotificationRadioItemProps> = ({
  name,
  checked,
  label,
  tooltip,
  className = '',
  onChange,
  ...rest
}) => {
  return (
    <Wrapper className={className}>
      <StyledRadio onChange={onChange} checked={checked} id={name} />
      <StyledInputLabel data-testid={rest['data-testid']} htmlFor={name}>
        {label}
      </StyledInputLabel>
      <StyledHintIcon title={tooltip} placement="right" tooltipCss={tooltipCss} />
    </Wrapper>
  );
};

export default NotificationRadioItem;

const tooltipCss = css`
  max-width: 230px;
  text-align: left;
`;
const Wrapper = styled('div')`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-right: 0;
  padding-right: ${SUBMIDDLE_MARGIN_PX};
  margin-left: -11px;
`;
const StyledRadio = styled(Radio)`
  color: ${({ theme }) => alpha(theme.palette.common.white, 0.33)};

  & svg {
    width: 20px;
    height: 20px;
  }

  &.Mui-checked {
    color: ${({ theme }) => theme.palette.common.primary};
  }
`;
const StyledHintIcon = styled(HintIconTooltipped)`
  position: absolute;
  right: 0;
  top: 9px;
`;
const StyledInputLabel = styled(FormLabel)`
  color: ${({ theme }) => theme.palette.common.white};
`;
