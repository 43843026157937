import React, { FC } from 'react';

import { AccordionDetails, AccordionSummary, useTheme } from '@mui/material';
import Accordion from '@mui/material/Accordion';

import ChevronTopIcon from '~/assets/icons/ChevronTopIcon';
import { BodyText, H4 } from '~/components/UI/Texts';
import styled from '~/styled';
import { BIG_MARGIN_PX, MIDDLE_MARGIN_PX, SMALL_RADIUS_PX, SUBMIDDLE_MARGIN_PX } from '~/theme';

interface SingleAccordionProps {
  id: number;
  title?: string | null;
  description?: string | null;
  isOpened: boolean;
  handleOpen: (index: number) => void;
}

const SingleAccordion: FC<SingleAccordionProps> = ({
  id,
  title,
  description,
  isOpened,
  handleOpen,
}) => {
  const theme = useTheme();
  return (
    <StyledAccordion expanded={isOpened} onClick={() => handleOpen(id)}>
      <StyledAccordionSummary
        id={id + 'Header'}
        aria-controls={id + `Content`}
        expandIcon={<StyledChevronTopIcon color={theme.palette.common.icon.accordionIcon} />}
      >
        <Title variant="h4">{title}</Title>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <Description>{description}</Description>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default SingleAccordion;

const StyledAccordion = styled(Accordion)`
  background: ${({ theme }) => theme.palette.common.transparent};
  box-shadow: none;
  margin-bottom: ${SUBMIDDLE_MARGIN_PX};

  &::before {
    content: none;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.Mui-expanded {
    margin-bottom: ${MIDDLE_MARGIN_PX};

    ${({ theme }) => theme.breakpoints.up('md')} {
      margin-bottom: ${BIG_MARGIN_PX};
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0;
  background: ${({ theme }) => theme.palette.common.card.accordionBackground};
  border-radius: ${SMALL_RADIUS_PX};

  &.Mui-expanded {
    margin: 0;
  }

  & .MuiAccordionSummary-content {
    padding: ${SUBMIDDLE_MARGIN_PX};
    margin: 0;

    &.Mui-expanded {
      margin: 0;
    }
  }
`;
const StyledAccordionDetails = styled(AccordionDetails)`
  padding: ${SUBMIDDLE_MARGIN_PX};
  padding-bottom: 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${MIDDLE_MARGIN_PX} ${SUBMIDDLE_MARGIN_PX} 0 ${SUBMIDDLE_MARGIN_PX};
  }
`;
const Title = styled(H4)`
  && {
    color: ${({ theme }) => theme.palette.common.text.accordionTitle};
  }
`;
const Description = styled(BodyText)`
  color: ${({ theme }) => theme.palette.common.text.secondaryToPrimary};
`;
const StyledChevronTopIcon = styled(ChevronTopIcon)`
  width: 18px;
  height: 18px;
  margin-left: ${SUBMIDDLE_MARGIN_PX};
  margin-right: ${SUBMIDDLE_MARGIN_PX};
  transform: rotate(180deg);
`;
