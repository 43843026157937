import React, { lazy, Suspense, useContext, useEffect } from 'react';

import { Route, Routes } from 'react-router-dom';

import SSRRedirect from '~/SsrRedirect';
import { Header } from '~/blocks/Header/Header';
import Loader from '~/components/Loader/Loader';
import RequireRXDAuth from '~/components/RequireRXDAuth';
import { RXDContext } from '~/context/RXDContext';
import useScriptLoader, { SCRIPTS } from '~/context/ScriptLoader/useScriptLoader';
import useVerse from '~/hooks/useVerse';
import styled from '~/styled';
import { LearnerInfo, Maybe, Tenant } from '~/types';
import UserCom from '~/utils/UserCom';
import checkDomainsAvailability from '~/utils/checkDomainsAvailability';
import { getUserComUserId } from '~/utils/userComUtils';

const RXDLandingPage = lazy(() => import('../../pages/RXDLandingPage/RXDLandingPage'));
const RXDTestPage = lazy(() => import('../../pages/RXDTestPage/RXDTestPage'));
const SingleVideoPage = lazy(() => import('../../pages/SingleVideoPage/SingleVideoPage'));

interface RXDLayoutProps {
  tenant?: Maybe<Tenant>;
  userInfo?: LearnerInfo;
  userLoading: boolean;
}

const RXDLayout: React.FC<RXDLayoutProps> = ({ tenant, userInfo, userLoading }) => {
  const logoUrl = tenant?.logo_url;

  const { rxdUserInfo } = useContext(RXDContext);
  const { isLoaded: isUserComLoaded } = useScriptLoader(SCRIPTS.USER_COM);
  const { changeUserId } = useVerse();

  useEffect(() => {
    if (rxdUserInfo || userInfo) {
      const userId = rxdUserInfo ? rxdUserInfo?.id : userInfo?.id;
      changeUserId(userId, rxdUserInfo ? '' : userInfo?.email || '', getUserComUserId(userId));
    }
  }, [userInfo, rxdUserInfo, changeUserId]);

  useEffect(() => {
    if (isUserComLoaded) {
      UserCom.destroyWidget();
    }
  }, [isUserComLoaded]);

  useEffect(() => {
    if (rxdUserInfo) {
      checkDomainsAvailability([
        {
          name: 'partner_id',
          value: rxdUserInfo?.partnerId,
        },
        {
          name: 'user_id',
          value: rxdUserInfo?.id,
        },
      ]);
    }
  }, [rxdUserInfo]);

  return (
    <>
      <Header onlyLogo={true} logoUrl={logoUrl} />
      <Root>
        <Routes>
          <Route
            path="/videos/:slug"
            element={
              <RequireRXDAuth>
                <Suspense fallback={<Loader />}>
                  <SingleVideoPage isXApiPage={true} bumperUrl={tenant?.video_bumper_url} />
                </Suspense>
              </RequireRXDAuth>
            }
          />
          <Route
            path="/landing"
            element={
              <Suspense fallback={<Loader />}>
                <RXDLandingPage userInfo={userInfo} userLoading={userLoading} />
              </Suspense>
            }
          />
          <Route
            path="/test"
            element={
              <Suspense fallback={<Loader />}>
                <RXDTestPage />
              </Suspense>
            }
          />
          <Route path="/*" element={<SSRRedirect to="/" />} />
        </Routes>
      </Root>
    </>
  );
};

export default RXDLayout;

const Root = styled('main')`
  flex-grow: 1;
`;
