import React, { useContext } from 'react';

import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import cx from 'classnames';

import PlaySmallIcon from '~/assets/icons/PlaySmallIcon';
import CardDurationInfo from '~/components/Card/components/CardDurationInfo/CardDurationInfo';
import ConditionalLink from '~/components/ConditionalLink/ConditionalLink';
import LazyLoadImage from '~/components/LazyloadImage/LazyLoadImage';
import ToggleFromList from '~/components/ToggleFromList/ToggleFromList';
import CategoryLabel from '~/components/UI/CategoryLabel/CategoryLabel';
import DifficultyIcon from '~/components/UI/DifficultyIcon/DifficultyIcon';
import ProgressLine from '~/components/UI/ProgressLine/ProgressLine';
import { VideoInterface } from '~/components/Video/types';
import { UserInfoCtx } from '~/context/UserInfoProvider';
import Modal from '~/modals/Modal';
import { Modals } from '~/modals/types';
import styled, { css } from '~/styled';
import { BREAKPOINTS_VALUES } from '~/theme';
import { createCategoryLink } from '~/utils/urlUtils/createCategoryLink';

export const LandScapeVideo: React.FC<VideoInterface> = ({
  id,
  title,
  duration,
  userName,
  categories,
  difficulty,
  previewUrl,
  description,
  videoWidth,
  background,
  progress,
  handleClick,
  durationNumb,
  link,
  expertLink,
  verseId,
  bumperUrl,
  isInWatchlist,
  shouldOpenInModal = false,
  className,
}) => {
  const { userInfo } = useContext(UserInfoCtx);
  const categoryName = categories && Array.isArray(categories) ? categories[0]?.title : null;
  const categoryLink = createCategoryLink(categories?.[0]);
  const theme = useTheme();

  return (
    <Modal
      verseId={verseId}
      duration={durationNumb}
      title={title}
      expertName={userName}
      bumperUrl={bumperUrl}
    >
      {({ openModal }) => {
        const playVideo = () => {
          if (shouldOpenInModal) {
            openModal(Modals.VideoModal);
            return;
          }

          if (handleClick || link) {
            handleClick && handleClick(id);
          }
        };

        return (
          <VideoWrap className={className} background={background} videoWidth={videoWidth}>
            <ConditionalLink withLink={link && !shouldOpenInModal} to={link}>
              <ImgWrap
                className={cx('video-preview-wrapper', 'imgWrap')}
                onClick={playVideo}
                background={background}
              >
                <StyledLazyLoadImage
                  path={previewUrl}
                  alt={title}
                  draggable={false}
                  srcset={[{ width: 420 }, { width: 370 }, { width: 350 }, { width: 340 }]}
                  sizes={`(max-width:${BREAKPOINTS_VALUES['sm']}px) 420px, (max-width: ${BREAKPOINTS_VALUES['md']}) 370px, (max-width:${BREAKPOINTS_VALUES['lg']}px) 350px, 340px`}
                  objectFitPolyfill="cover"
                  wrapperCss={generatedLazyWrapper}
                />

                <BtnWrap>
                  <Btn color={theme.palette.secondary.contrastText} />
                </BtnWrap>
              </ImgWrap>
            </ConditionalLink>
            <CatDifWrap>
              {categoryName && <StyledCategoryLabel url={categoryLink} name={categoryName} />}
              <DifficultyIcon difficulty={difficulty} />
            </CatDifWrap>

            <TextWrap>
              <ConditionalLink withLink={link && !shouldOpenInModal} to={link}>
                <Title
                  variant="h3"
                  component="h3"
                  className="title"
                  onClick={playVideo}
                  videoWidth={videoWidth}
                >
                  {title}
                </Title>
                <Description className="description">{description}</Description>
              </ConditionalLink>
              <InfoRow>
                {(userName || duration) && (
                  <CardDurationInfo durationName={userName} duration={duration} link={expertLink} />
                )}
                {userInfo && <ToggleFromList id={id} existInWatchlist={isInWatchlist} />}
              </InfoRow>
              {(progress || progress === 0) && <ProgressLine width={progress} />}
            </TextWrap>
          </VideoWrap>
        );
      }}
    </Modal>
  );
};

export default LandScapeVideo;

const VideoWrap = styled('div', {
  shouldForwardProp: (prop) => prop !== 'background' && prop !== 'videoWidth',
})`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 240px;
  border-radius: 5px;
  overflow: hidden;
  background: ${({ background, theme }) =>
    background === 'Dark'
      ? theme.palette.common.blockBackground.light
      : theme.palette.common.blockBackground.main};
  box-shadow: ${({ background, theme }) =>
    background === 'Dark'
      ? `0px 3px 6px ${alpha(theme.palette.common.blackPure, 0.3)}`
      : `0px 3px 6px ${alpha(theme.palette.common.blackPure, 0.1)}`};

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 100%;

    &:hover {
      &::after {
        height: 242px;
        bottom: 132px;
      }

      & .imgWrap {
        &::after {
          height: 180px;
        }
      }

      & .title {
        width: ${({ videoWidth }) => videoWidth + videoWidth * 0.2 - 27 * 2}px;
      }

      & .description {
        width: ${({ videoWidth }) => videoWidth + videoWidth * 0.2 - 27 * 2}px;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 9px;
        overflow: hidden;
        white-space: inherit;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
`;
const ImgWrap = styled('div', {
  shouldForwardProp: (prop) => prop !== 'background',
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 198px;
  transition: none;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 100%;
    height: 133px;
    background: ${({ background, theme }) =>
      background === 'Dark'
        ? `linear-gradient(0deg, ${theme.palette.common.blockBackground.light} 0%, ${alpha(
            theme.palette.common.blockBackground.light,
            0.9,
          )} 28.65%, ${alpha(theme.palette.common.blockBackground.light, 0)} 100%)`
        : `linear-gradient(0deg, ${theme.palette.common.blockBackground.main} 0%, ${alpha(
            theme.palette.common.blockBackground.main,
            0.9,
          )} 28.65%, ${alpha(theme.palette.common.blockBackground.main, 0)} 100%)`};
    -webkit-background-origin: padding-box;
    -o-background-origin: padding-box;
    z-index: 0;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 246px;
  }
`;
const StyledLazyLoadImage = styled(LazyLoadImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const generatedLazyWrapper = css`
  width: 100%;
  height: 100%;
`;
const BtnWrap = styled('div')`
  cursor: pointer;
  position: absolute;
  transform: translate(-50%, -60%);
  background: ${({ theme }) => alpha(theme.palette.common.card.overlay.contrast, 0.6)};
  top: 50%;
  left: 50%;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 70px;
    height: 70px;
  }
`;
const Btn = styled(PlaySmallIcon)`
  width: 24px;
  height: 28px;
  margin-left: 2px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 35px;
    height: 40px;
    margin-left: 4px;
  }
`;
const CatDifWrap = styled('div')`
  display: flex;
  align-items: center;
  padding: 0 24px;
  margin-bottom: 12px;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0 32px;
  }
`;
const TextWrap = styled('div')`
  padding: 0 24px 24px 24px;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0 32px 32px 32px;
  }
`;
const Title = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'videoWidth',
})`
  && {
    color: ${({ theme }) => theme.palette.common.white};
    width: ${({ videoWidth }) => videoWidth - 27 * 2}px;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    overflow: hidden;
    white-space: inherit;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 12px;
    cursor: pointer;

    ${({ theme }) => theme.breakpoints.up('md')} {
      font-size: 20px;
      line-height: 28px;
    }
  }
`;
const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  display: none;
`;
const InfoRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledCategoryLabel = styled(CategoryLabel)`
  margin-right: 10px;
`;
