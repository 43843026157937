import React, { FC, MutableRefObject, ReactNode, useState } from 'react';

import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';

import styled, { CssUtilityType } from '~/styled';
import { MIDDLE_MARGIN, MIDDLE_MARGIN_PX, SUBMIDDLE_MARGIN, useIsWidthUp } from '~/theme';

interface IHeaderDropdown {
  children: ReactNode;
  opened: boolean;
  anchorElRef: MutableRefObject<null>;
  arrowPlacementBottom?: boolean;
  arrowCss?: CssUtilityType;
}

const HeaderDropdown: FC<IHeaderDropdown> = ({
  children,
  opened,
  anchorElRef,
  arrowPlacementBottom = false,
  arrowCss,
}) => {
  const [arrowElement, setArrowElement] = useState();
  const isMobile = !useIsWidthUp('md');
  const anchorEl = anchorElRef?.current;

  return (
    <StyledPopper
      arrow
      disablePortal
      anchorEl={anchorEl}
      open={opened}
      modifiers={[
        {
          name: 'arrow',
          options: { element: arrowElement },
        },
        !isMobile && { name: 'offset', options: { offset: [0, 20] } },
        !isMobile && {
          name: 'preventOverflow',
          options: { padding: MIDDLE_MARGIN },
        },
      ].filter(Boolean)}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <div>
            <Arrow top={!arrowPlacementBottom} ref={setArrowElement} optionalStyles={arrowCss} />
            {children}
          </div>
        </Fade>
      )}
    </StyledPopper>
  );
};

export default HeaderDropdown;

const StyledPopper = styled(Popper)`
  position: relative;
  z-index: 10000;
  margin: 0 ${MIDDLE_MARGIN_PX};
`;
const Arrow = styled('div', {
  shouldForwardProp: (prop) => prop !== 'top' && prop !== 'optionalStyles',
})<{ top: boolean; optionalStyles?: CssUtilityType }>`
  display: none;
  bottom: ${({ top }) => (top ? 100 : 0)}%;
  width: 32px;
  height: 32px;
  padding-top: ${({ top }) => (top ? SUBMIDDLE_MARGIN : 0)}px;
  margin-bottom: ${({ top }) => (top ? 0 : -10)}px;
  pointer-events: none;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: block;
  }

  &:after {
    content: '';
    display: block;
    width: 32px;
    height: 32px;
    transform-origin: 50% 50%;
    transform: rotate(45deg);
    border: none;
  }

  ${({ optionalStyles }) => optionalStyles}
`;
