import React, { createContext, FC, ReactNode, useMemo } from 'react';

import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';

import LinkedInModal from '~/components/LinkedInModal/LinkedInModal';
import { OnboardingModal } from '~/components/OnboardingModal/OnboardingModal';
import SetGoalsLaterModal from '~/components/OnboardingModal/SetGoalsLaterModal';
import ShareModal from '~/components/ShareModal/ShareModal';
import LeaveModal from '~/components/Test/LeaveModal';
import VideoModal from '~/components/VideoModal/VideoModal';
import Confirm from '~/modals/Confirm';
import ModalContainer from '~/modals/ModalContainer';
import { ModalProps, IChildrenParams } from '~/modals/types';
import useMultipleModals from '~/modals/useMultipleModals';
import ExtendDueDateModal from '~/pages/AssignedLearningPage/components/ExtendDueDateModal/ExtendDueDateModal';
import NotificationsModal from '~/pages/AssignedLearningPage/components/NotificationsModal/NotificationsModal';
import ChangeLearnerTeamModal from '~/pages/MyAccountPage/components/ChangeLearnerTeamModal/ChangeLearnerTeamModal';
import CreateTeamModal from '~/pages/MyAccountPage/components/CreateTeamModal/CreateTeamModal';
import EditTeamModal from '~/pages/MyAccountPage/components/EditTeamModal/EditTeamModal';
import { SMALL_MARGIN_PX } from '~/theme';

const modalViews = {
  LinkedInModal,
  ShareModal,
  LeaveModal,
  VideoModal,
  Confirm,
  ChangeLearnerTeamModal,
  EditTeamModal,
  CreateTeamModal,
  NotificationsModal,
  ExtendDueDateModal,
  OnboardingModal,
  SetGoalsLaterModal,
};

export const ModalCtx = createContext<Omit<ModalProps, 'labelledBy'>>({
  openModal: () => {},
  closeModal: () => {},
  closeAllModals: () => {},
});

function Modal<T extends unknown>({
  children,
  preventCloseOnOutsideClick = false,
  ...rest
}: T & {
  children: ReactNode | ((params: IChildrenParams) => ReactNode);
  preventCloseOnOutsideClick?: boolean;
}) {
  const { openModal, closeModal, stack, modalProps, closeAllModals } = useMultipleModals(
    preventCloseOnOutsideClick,
  );

  const memoizedValue = useMemo(
    () => ({
      openModal,
      closeModal,
      closeAllModals,
    }),
    [openModal, closeModal, closeAllModals],
  );

  return (
    <ModalCtx.Provider value={memoizedValue}>
      {typeof children === 'function'
        ? children({ openModal, closeModal, closeAllModals })
        : children}
      <>
        {stack.map((name, index) => {
          if (!name) {
            return null;
          }
          const CurrentModal = modalViews[name] as FC<T>;
          const modalTitleId = name + '/modalTitle';

          return (
            <ModalContainer key={index} labelledBy={modalTitleId}>
              {(containerRef) => (
                <StyledDialog
                  open={!!stack.length}
                  onClose={preventCloseOnOutsideClick ? undefined : closeModal}
                  aria-labelledby={modalTitleId}
                  // redefining container for modal to avoid putting aria-hidden="true" to root element which is cause accessibility issue "This focusable element is within another element with "aria-hidden" set to "true"."
                  container={() => containerRef.current}
                >
                  <CurrentModal
                    closeModal={closeModal}
                    openModal={openModal}
                    closeAllModals={closeAllModals}
                    // to make modal accessible set labelledBy as id to title component inside modal's layout
                    labelledBy={modalTitleId}
                    {...rest}
                    {...modalProps[name]}
                  />
                </StyledDialog>
              )}
            </ModalContainer>
          );
        })}
      </>
    </ModalCtx.Provider>
  );
}

export default Modal;

const StyledDialog = styled(Dialog)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${SMALL_MARGIN_PX};
`;
