import React from 'react';

import MUIAutocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import { alpha } from '@mui/material/styles';

import CloseIcon from '~/assets/icons/CloseIcon';
import styled from '~/styled';

const Autocomplete = ({ ...rest }: AutocompleteProps<any, any, any, any>) => {
  return (
    <StyledMUIAutocomplete
      ChipProps={{
        deleteIcon: <CloseIcon />,
      }}
      PopperComponent={StyledPopper}
      {...rest}
    />
  );
};

export default Autocomplete;

const StyledMUIAutocomplete = styled(MUIAutocomplete)`
  && .MuiChip-root {
    background-color: ${({ theme }) => alpha(theme.palette.common.gray, 0.1)};
  }
  && .MuiChip-label {
    font-size: 16px;
    line-height: 1;
    color: ${({ theme }) => theme.palette.common.white};
  }

  && .MuiChip-deleteIcon {
    width: 16px;
    height: 16px;
    padding: 4px;
    border-radius: 50%;
    color: ${({ theme }) => theme.palette.common.white};
    &:hover {
      background-color: ${({ theme }) => alpha(theme.palette.common.white, 0.08)};
    }
  }
`;

const StyledPopper = styled(Popper)`
  && {
    & .MuiAutocomplete-option {
      padding: 6px 16px;
    }
    & .MuiAutocomplete-option[aria-selected='true'],
    & .MuiAutocomplete-option[aria-selected='true'].Mui-focused {
      background-color: ${({ theme }) => theme.palette.common.blue};
      color: ${({ theme }) => theme.palette.common.white};
    }
  }
`;
